import { Attachment, LocalFile } from '@supy/common';
import { Nullable } from '@supy/core';
import { PackagingUnitRequest } from '@supy/packaging';

import { InventoryWastageEventItem } from './inventory-wastage-event.entity';

export enum InventoryEventStateEnum {
  Archived = 'archived',
  Submitted = 'submitted',
  Draft = 'draft',
}

export interface InventoryEventState {
  readonly name: InventoryEventStateEnum;
  readonly sort: number;
}

export interface InventoryEventFilters {
  readonly start?: Nullable<number>;
  readonly end?: Nullable<number>;
  readonly name: string | null;
  readonly locations: string[];
  readonly archived: boolean;
}

export interface InventoryEventDetailsRequest {
  readonly eventDate?: Date;
  readonly remarks?: string | null;
  readonly attachments?: Attachment[];
  readonly localFiles?: LocalFile[];
}

export interface InventoryEventItemRequest {
  readonly id?: string;
  readonly quantity: number;
  readonly packagingUnit?: PackagingUnitRequest;
}

export interface InventoryEventItemProps {
  readonly locationId: string;
  readonly term: string;
  readonly eventDate: Date;
}

export interface InventoryEventRequestProps {
  readonly id: string;
  readonly name: string;
  readonly 'retailer.id': string;
  readonly 'location.id': string;
  readonly 'state.name': string;
  readonly 'state.sort': string;
  readonly eventDate: Date | number;
  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export interface UploadInventoryEventAttachmentQueryParams {
  readonly retailerId: string;
}

export interface UploadInventoryEventAttachmentResponse {
  readonly path: string;
}

export interface BaseInventoryEventRepeatProps {
  readonly eventDate: Date;
}
export interface InventoryEventRepeatStandardProps extends BaseInventoryEventRepeatProps {
  readonly location: string;
  readonly name: string;
}

export interface InventoryEventRepeatTransferProps extends BaseInventoryEventRepeatProps {
  readonly fromLocation: string;
  readonly toLocation: string;
}
export type InventoryEventRepeatAllProps = InventoryEventRepeatStandardProps | InventoryEventRepeatTransferProps;

export interface ReplicationResponse {
  readonly id: string;
  readonly skippedItems: InventoryWastageEventItem[];
}
export interface DownloadTransferPdfResponse {
  readonly signedUrl: string;
}
