<div class="inventory-event-grid">
  <supy-grid
    primaryKey="id"
    class="inventory-event-grid__grid"
    [rowHeightFlexible]="true"
    [data]="data"
    [paginationMode]="paginationMode"
    [page]="requestMetadata.page"
    [perPage]="requestMetadata.limit"
    [prevPageDisabled]="requestMetadata.page === 0"
    [nextPageDisabled]="responseMetadata.count < requestMetadata.limit"
    (pageChange)="paginationChanged.emit($event)"
    (cellClick)="cellClicked.emit($event.cell.row.data?.id)"
  >
    <ng-container *ngIf="isTransfer; else notTransfer">
      <supy-grid-column field="code" header="Code" i18n-header="@@common.code" width="10%">
        <ng-template supyGridCell let-value let-rowData="rowData">
          <p class="inventory-event-grid__flex">
            <span class="inventory-event-grid__code">{{ value }}</span>
            <supy-icon *ngIf="rowData.attachments?.length" name="paperclip" size="small" />
          </p>
        </ng-template>
      </supy-grid-column>
      <supy-grid-column header="Transfer From" i18n-header="@@transferFrom" field="fromLocation" width="20%">
        <ng-template supyGridCell let-value>{{ value?.name ?? '' }} </ng-template>
      </supy-grid-column>
      <supy-grid-column header="Transfer To" i18n-header="@@transferTo" field="toLocation" width="20%">
        <ng-template supyGridCell let-value>{{ value?.name ?? '' }} </ng-template>
      </supy-grid-column>
      <supy-grid-column
        header="Transfer Date"
        i18n-header="@@grid.headers.transferDate.label"
        field="eventDate"
        width="20%"
      >
        <ng-template supyGridCell let-value>
          {{ value | timezone: ianaTimeZone | date: 'dd/MM/yy' }}
        </ng-template>
      </supy-grid-column>
      @if (!hidePrices()) {
        <supy-grid-column
          header="Total ({{ currency }})"
          i18n-header="@@common.totalInterpolation"
          field="cost"
          width="15%"
        >
          <ng-template supyGridCell let-value>
            <span>{{ value | supyPrecision: currencyPrecision }}</span>
          </ng-template>
        </supy-grid-column>
      }
      <supy-grid-column header="Status" i18n-header="@@status" field="state" width="15%">
        <ng-template supyGridCell let-rowData="rowData">
          <p>
            <supy-inventory-event-status
              [eventType]="eventType"
              [status]="getTransferStatus(rowData)"
              [circle]="true"
            />
          </p>
        </ng-template>
      </supy-grid-column>
    </ng-container>
    <ng-template #notTransfer>
      <supy-grid-column header="Event Name" i18n-header="@@eventName" width="30%">
        <ng-template supyGridCell let-rowData="rowData">
          <p class="inventory-event-grid__flex">
            <supy-icon
              *ngIf="rowData.state === inventoryEventStateEnum.Draft; else submitted"
              name="draft"
              color="warn"
            />
            <ng-template #submitted>
              <supy-icon name="clipboard-tick" color="success" />
            </ng-template>
            <span>
              {{ rowData.eventDate | timezone: ianaTimeZone | date: 'dd MMMM yyyy' }} -
              {{ rowData?.name ?? '' }}
            </span>
            <supy-icon *ngIf="rowData.attachments?.length" name="paperclip" size="small" />
          </p>
        </ng-template>
      </supy-grid-column>
      @if (!hidePrices()) {
        <supy-grid-column
          header="Total ({{ currency }})"
          i18n-header="@@common.totalInterpolation"
          field="cost"
          width="20%"
        >
          <ng-template supyGridCell let-value>{{ value | supyPrecision: currencyPrecision }}</ng-template>
        </supy-grid-column>
      }
      <supy-grid-column header="Location" i18n-header="@@location" field="location" width="30%">
        <ng-template supyGridCell let-value>{{ getBranchName(value?.id)?.name }}</ng-template>
      </supy-grid-column>
      <supy-grid-column header="Created By" i18n-header="@@common.createdBy" field="createdBy" width="20%">
        <ng-template supyGridCell let-value>{{ value?.firstName + ' ' + value?.lastName }}</ng-template>
      </supy-grid-column>
    </ng-template>
  </supy-grid>
</div>
