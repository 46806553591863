import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import { CellType } from '@infragistics/igniteui-angular';

import { BranchStorageLocations, IdType, LocalizedSkeletonObjectType, ViewBranch } from '@supy/common';
import { GridComponent } from '@supy/components';
import { Packaging, PackagingUnit } from '@supy/packaging';
import { getLocalizedName } from '@supy/settings';

import { BaseItemInventory, BaseItemInventoryLocation } from '../../core';

export interface BaseItemInventoryLocationGridItem {
  readonly id: string;
  readonly itemLocationId: string | null;
  readonly packagingUnit: PackagingUnit | null;
  readonly parLevel: number | null;
  readonly minLevel: number | null;
  readonly storages: LocalizedSkeletonObjectType[];
  readonly baseStorages?: LocalizedSkeletonObjectType[];
}

@Component({
  selector: 'supy-base-item-inventory-locations-grid',
  templateUrl: './base-item-inventory-locations-grid.component.html',
  styleUrls: ['./base-item-inventory-locations-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseItemInventoryLocationsGridComponent {
  readonly grid = viewChild<GridComponent>(GridComponent);

  readonly userLocations = input<ViewBranch[]>([]);
  readonly branchStorageLocations = input<BranchStorageLocations[]>([]);
  readonly isReadonly = input<boolean>();
  readonly inventoryItem = input<BaseItemInventory>();
  readonly packagingUnits = input<Packaging[]>([]);

  readonly #locationNameSearchText = signal('');
  readonly #data = signal<BaseItemInventoryLocationGridItem[]>([]);
  readonly data = computed(() =>
    this.#data().filter(({ id }) =>
      this.getLocationName(id)?.toLowerCase().includes(this.#locationNameSearchText().toLowerCase()),
    ),
  );

  readonly packagingUnitsList = signal<PackagingUnit[]>([]);

  protected readonly branchStorageLocationsMap = new Map<string, LocalizedSkeletonObjectType[]>();
  protected isInventoryItem = signal(false);

  constructor() {
    effect(
      () => {
        const locations = this.userLocations();

        if (locations?.length && !this.inventoryItem()) {
          this.#data.set(
            locations.map(location => ({
              id: location.id,
              itemLocationId: null,
              packagingUnit: null,
              parLevel: null,
              minLevel: null,
              storages: [],
              baseStorages: location.storageLocations ?? [],
            })),
          );
        }
      },
      { allowSignalWrites: true },
    );

    effect(
      () => {
        const inventoryItem = this.inventoryItem();

        if (untracked(() => this.isInventoryItem()) !== !!inventoryItem) {
          this.isInventoryItem.set(!!inventoryItem);
        }

        if (inventoryItem?.locations?.length) {
          const inventoryItemStoragesMap = new Map<string, boolean>(inventoryItem.storages.map(({ id }) => [id, true]));

          this.#data.set(
            inventoryItem.locations.map(location => {
              this.branchStorageLocationsMap.set(
                location.location.id,
                this.branchStorageLocations().find(({ id }) => id === location.location.id)?.children ?? [],
              );

              return {
                ...BaseItemInventoryLocation.deserialize({
                  ...location,
                  packagingUnit: location.packagingUnit ?? this.packagingUnitsList()[0],
                }),
                storages: structuredClone(
                  this.branchStorageLocationsMap
                    .get(location.location.id)
                    ?.filter(({ id }) => inventoryItemStoragesMap.has(id)) ?? [],
                ),
              };
            }),
          );
        }
      },
      {
        allowSignalWrites: true,
      },
    );

    effect(
      () => {
        const packagesList = this.packagingUnits();
        const baseUnit = this.inventoryItem()?.retailerItem?.baseUnit ?? packagesList[0]?.baseUnit;

        if (!baseUnit) {
          return;
        }

        this.packagingUnitsList.set(
          PackagingUnit.deserializeList([
            {
              name: baseUnit.name,
              uomId: baseUnit.id,
            },
            ...packagesList.map(packaging => ({
              name: packaging.packageName,
              uomId: packaging.id,
              packagingId: packaging.id,
            })),
          ]),
        );
      },
      {
        allowSignalWrites: true,
      },
    );
  }

  getLocationName(locationId: string): string | undefined {
    return this.userLocations()?.find(({ id }) => locationId === id)?.name;
  }

  onCellEditDone(): void {
    this.#data.set(this.#data());
  }

  onLocationNameSearch(value: string): void {
    this.#locationNameSearchText.set(value);
  }

  getStorageLocationsFromMap(locationId: string, storageIds: string[]): LocalizedSkeletonObjectType[] | undefined {
    return this.branchStorageLocationsMap.get(locationId)?.filter(({ id }) => storageIds.includes(id));
  }

  getStorageLocations(locationId: string, storageIds: string[]): LocalizedSkeletonObjectType[] | undefined {
    return this.branchStorageLocations()
      .find(({ id }) => id === locationId)
      ?.children.filter(({ id }) => storageIds.includes(id));
  }

  getIds<T extends IdType>(items: T[]): string[] {
    return items.map(({ id }) => id);
  }

  onNumberValueChange(cell: CellType, $event: number | string): void {
    cell.editValue = $event === '' ? 0 : $event;
  }

  onStorageValueChange(cell: CellType, rowData: BaseItemInventoryLocationGridItem, $event: string[]): void {
    cell.editValue = this.isInventoryItem()
      ? this.getStorageLocationsFromMap(rowData.id, $event)
      : this.getStorageLocations(rowData.id, $event);
  }

  protected readonly getLocalizedName = getLocalizedName;
}
