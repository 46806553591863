import { FilterActionsOptions } from '@supy/common';
import { CreatePackagingRequest, UpdatePackagingRequest } from '@supy/packaging';

import { costAdjustmentDetailPayload, costAdjustmentPayload, UpdateBaseItemInventoryRequest } from '../../../../core';
import {
  AutocompleteFinishedRecipesQueryProps,
  BackdateRecipeRequest,
  BulkUpdateRecipesCategoryRequest,
  BulkUpdateRecipesRequest,
  CloneRecipeRequest,
  FinishedRecipedUpdateRequest,
  InventoryRecipeBaseInventoryPayload,
  InventoryRecipeCreateRequest,
  InventoryRecipeType,
  RecipeSection,
  RecipeUpdateCostCenterRequest,
  RecipeUpdateLocationsRequest,
  SemiFinishedRecipedUpdateRequest,
  UpdateRecipeCookbookRequest,
  UpdateRecipeInventoryRequest,
} from '../../core';
import { InventoryRecipeFilters, InventoryRecipeRequestMetadata } from '../state';

export class InventoryRecipeGet {
  static readonly type = '[InventoryRecipe] Get';
  constructor(readonly payload: { id: string; locationId?: string; fromCache?: boolean }) {}
}

export class InventoryRecipeGetMany {
  static readonly type = '[InventoryRecipe] GetMany';
}

export class InventoryRecipeGetManyPost {
  static readonly type = '[InventoryRecipe] GetManyPost';
}

export class InventoryRecipeGetAll {
  static readonly type = '[InventoryRecipe] GetAll';
}

export class InventoryRecipeListExport {
  static readonly type = '[InventoryRecipe] ListExport';
}

export class InventoryRecipeInitFilters {
  static readonly type = '[InventoryRecipe] InitFilters';
}

export class InventoryRecipePatchFilter {
  static readonly type = '[InventoryRecipe] PatchFilter';
  constructor(
    readonly payload: Partial<InventoryRecipeFilters>,
    readonly options?: FilterActionsOptions,
  ) {}
}

export class InventoryRecipeResetFilter {
  static readonly type = '[InventoryRecipe] ResetFilter';
  constructor(readonly options?: { preserveKeys?: string[] }) {}
}

export class InventoryRecipeResetPagination {
  static readonly type = '[InventoryRecipe] ResetPagination';
}

export class InventoryRecipePatchRequestMeta {
  static readonly type = '[InventoryRecipe] PatchRequestMeta';
  constructor(readonly payload: Partial<InventoryRecipeRequestMetadata>) {}
}

export class InventoryRecipeResetRequestMeta {
  static readonly type = '[InventoryRecipe] ResetRequestMeta';
}

export class InventoryRecipeCreate {
  static type = '[InventoryRecipe] Create';

  constructor(readonly payload: InventoryRecipeCreateRequest) {}
}

export class InventoryRecipeUpdate {
  static type = '[InventoryRecipe] Update';

  constructor(
    readonly id: string,
    readonly payload: Partial<FinishedRecipedUpdateRequest | SemiFinishedRecipedUpdateRequest>,
  ) {}
}

export class InventoryRecipeCreateInventory {
  static type = '[InventoryRecipe] CreateInventory';

  constructor(
    readonly id: string,
    readonly payload: InventoryRecipeBaseInventoryPayload,
  ) {}
}

export class InventoryRecipeUpdateInventory {
  static type = '[InventoryRecipe] UpdateInventory';

  constructor(
    readonly id: string,
    readonly payload: Partial<UpdateRecipeInventoryRequest>,
  ) {}
}

export class InventoryRecipeUpdateCookbook {
  static type = '[InventoryRecipe] UpdateCookbook';

  constructor(
    readonly id: string,
    readonly payload: UpdateRecipeCookbookRequest,
  ) {}
}

export class InventoryRecipeSetCreationType {
  static type = '[InventoryRecipe] SetCreationType';

  constructor(readonly payload: InventoryRecipeType) {}
}

export class InventoryRecipeCreatePackaging {
  static readonly type = '[InventoryRecipe] CreatePackaging';
  constructor(readonly payload: { groupIndex: number; packaging: CreatePackagingRequest }) {}
}

export class InventoryRecipeUpdatePackaging {
  static readonly type = '[InventoryRecipe] UpdatePackaging';
  constructor(readonly payload: { id: string; groupIndex: number; body: UpdatePackagingRequest }) {}
}

export class InventoryRecipeDeletePackaging {
  static readonly type = '[InventoryRecipe] DeletePackaging';
  constructor(readonly payload: { id: string }) {}
}

export class InventoryRecipeMarkStock {
  static readonly type = '[InventoryRecipe] MarkStock';
  constructor(readonly payload: { id: string }) {}
}

export class InventoryRecipeUpdateBaseItemBranches {
  static readonly type = '[InventoryRecipe] UpdateBaseItemBranches';

  constructor(
    readonly id: string,
    readonly payload: Partial<UpdateBaseItemInventoryRequest>,
  ) {}
}

export class InventoryRecipeIngredientItemsGet {
  static readonly type = '[InventoryRecipe] IngretientItemsGet';

  constructor(
    readonly term: string,
    readonly locationId?: string,
  ) {}
}

export class InventoryRecipeGetStatistics {
  static readonly type = '[InventoryRecipe] GetStatistics';
}

export class InventoryRecipeBulkArchive {
  static readonly type = '[InventoryRecipe] BulkArchive';

  constructor(readonly body: BulkUpdateRecipesRequest) {}
}

export class InventoryRecipeBulkActivate {
  static readonly type = '[InventoryRecipe] BulkActivate';

  constructor(readonly body: BulkUpdateRecipesRequest) {}
}

export class InventoryRecipeBulkUpdateCategory {
  static readonly type = '[InventoryRecipe] BulkUpdateCategory';

  constructor(readonly body: BulkUpdateRecipesCategoryRequest) {}
}

export class InventoryRecipeResetSequence {
  static readonly type = '[InventoryRecipe] ResetSequence';
}

export class InventoryRecipeWarnPackagingLinkedToSupplier {
  static readonly type = '[InventoryRecipe] WarnPackagingLinkedToSupplier';
}

export class InventoryRecipeUpdateLocations {
  static readonly type = '[InventoryRecipe] UpdateLocations';

  constructor(
    readonly id: string,
    readonly body: RecipeUpdateLocationsRequest,
  ) {}
}

export class InventoryRecipeUpdateCostCenters {
  static readonly type = '[InventoryRecipe] UpdateCostCenters';

  constructor(
    readonly id: string,
    readonly body: RecipeUpdateCostCenterRequest,
  ) {}
}

export class InventoryRecipeDelete {
  static readonly type = '[InventoryRecipe] Delete';

  constructor(readonly id: string) {}
}

export class InventoryRecipePublish {
  static readonly type = '[InventoryRecipe] Publish';

  constructor(
    readonly id: string,
    readonly effectiveDate?: Date,
  ) {}
}

export class InventoryRecipeGetStatements {
  static readonly type = '[InventoryRecipe] GetStatements';

  constructor(
    readonly id: string,
    readonly locationIds: string[],
  ) {}
}

export class InventoryRecipeClone {
  static readonly type = '[InventoryRecipe] Clone';

  constructor(
    readonly payload: { readonly id: string; readonly body?: CloneRecipeRequest; readonly section: RecipeSection },
  ) {}
}

export class InventoryRecipeAutocompleteFinishedRecipes {
  static readonly type = '[InventoryRecipe] AutocompleteFinishedRecipes';

  constructor(readonly params: AutocompleteFinishedRecipesQueryProps) {}
}

export class InventoryRecipeGetMinimumEffectiveDate {
  static readonly type = '[InventoryRecipe] GetMinimumEffectiveDate';
  constructor(readonly id: string) {}
}

export class InventoryRecipeBackdate {
  static type = '[InventoryRecipe] Backdate';

  constructor(
    readonly id: string,
    readonly payload: BackdateRecipeRequest,
  ) {}
}

export class InventoryRecipeSetIdToCloneIngredients {
  static type = '[InventoryRecipe] CloneIngredients';

  constructor(readonly id: string) {}
}

export class InventoryRecipeGetPDF {
  static type = '[InventoryRecipe] GetPDF';

  constructor(readonly id: string) {}
}

export class InventoryRecipeCostAdjustmentsGetMany {
  static readonly type = '[InventoryRecipe] CostAdjustmentsGetMany';

  constructor(
    readonly recipeId: string,
    readonly payload: costAdjustmentPayload,
  ) {}
}

export class InventoryRecipeCostAdjustmentsReset {
  static readonly type = '[InventoryRecipe] CostAdjustmentsReset';
}

export class InventoryRecipeCostAdjustmentsDetails {
  static readonly type = '[InventoryRecipe] Get Cost Adjustments Details';

  constructor(
    readonly recipeId: string,
    readonly payload: costAdjustmentDetailPayload,
    readonly rowId: string,
  ) {}
}
