import { Language } from '@supy.api/dictionaries';

import { EventTime, IdType, LocalizedData, LocalizedSkeletonObjectType, Uom } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';
import { PackagingUnit } from '@supy/packaging';

export enum InventoryStockCountEventState {
  Archived = 'archived',
  Submitted = 'submitted',
  Draft = 'draft',
}

export enum InventoryStockCountItemScopeEnum {
  Item = 'item',
  Recipe = 'recipe',
}

export class InventoryStockCountPackagingResponse {
  readonly id: string;
  readonly name: string;
  readonly level: number;
  readonly unit: PackagingUnit;
  readonly partialCount: number;
  readonly totalCount: number;
  readonly isDefault: boolean;
  readonly packageName: string;
}

export class InventoryStockCountScope {
  readonly type: InventoryStockCountItemScopeEnum;
  readonly referenceId: string;

  constructor(args: NonFunctionProperties<InventoryStockCountScope>) {
    this.type = args.type;
    this.referenceId = args.referenceId;
  }

  static default(): InventoryStockCountScope {
    return new InventoryStockCountScope({
      referenceId: crypto.randomUUID(),
      type: InventoryStockCountItemScopeEnum.Item,
    });
  }
}

export class StockCountPackagingPayload {
  readonly packagingUnit: PackagingUnit;
  readonly quantity: number;
}

export class StockCountItemPayload {
  readonly id: string;
  readonly baseUnit: Uom;
  readonly cost: number;
  readonly onHand: number;
  readonly category: { id: string; name: LocalizedData };
  readonly packaging: StockCountPackagingPayload[];
}

export interface InventoryStockCountItemScope {
  readonly type: InventoryStockCountItemScopeEnum;
  readonly referenceId: string;
}

export interface InventoryStockCountItemResponse {
  readonly id: string;
  readonly code?: string;
  readonly scope?: InventoryStockCountItemScope;
  readonly name: LocalizedData;
  readonly baseUnit: Uom;
  readonly onHandQuantity: number;
  readonly retailerItemId: string;
  readonly cost: number;
  readonly onHandValue: number;
  readonly countedQuantity: number;
  readonly countedValue: number;
  readonly hasHiddenPackaging: boolean;
  readonly category: LocalizedSkeletonObjectType;
  readonly packagings: InventoryStockCountPackagingResponse[];
}

export class StockCountItemsPayload {
  readonly subStockCount?: IdType;
  readonly stockCount?: IdType;
  readonly items: StockCountItemPayload[];
  readonly state?: InventoryStockCountEventState;
}

export interface InventoryEventDateInput {
  readonly creationDate: Date;
  readonly time: EventTime;
}

export interface CreateInventoryStockCountInput {
  readonly name: string;
  readonly retailer: IdType;
  readonly location: IdType;
  readonly eventDate: InventoryEventDateInput;
  readonly isOpening: boolean;
  readonly templateId?: string;
}

export interface CreateInventorySubStockCountInput {
  readonly name: string;
  readonly templateId?: string;
}

export interface InventoryStockCountItemPackagingInput {
  readonly unit: PackagingUnit;
  readonly level: number;
  readonly count: number;
}

export interface InventoryStockCountItemInput {
  readonly id?: string;
  readonly scope: InventoryStockCountScopeRequest;
  readonly packagings: InventoryStockCountPackagingRequest[];
}

export interface UpdateInventorySubStockCountInput {
  readonly items: InventoryStockCountItemInput[];
}

export class InventoryStockCountPackagingRequest {
  readonly id?: string;
  readonly unit: PackagingUnit;
  readonly name: string;
  readonly partialCount: number;
}

export class InventoryStockCountScopeRequest {
  readonly type: InventoryStockCountItemScopeEnum;
  readonly referenceId: string;
}

export class InventoryStockCountItemRequest {
  readonly id: string;
  readonly scope: InventoryStockCountScopeRequest;
  readonly packagings: InventoryStockCountPackagingRequest[];
}

export interface InventoryStockCountRequestProps
  extends Record<`item.name.${Language}` | `name.${Language}` | `retailerItem.name.${Language}`, string> {
  readonly 'retailer.id': string;
  readonly 'location.id': string;
  readonly 'eventDate.creationDate': string;
  readonly 'subStockCounts.name': string;
  readonly 'item.scope.type': string;
  readonly 'category.id': string;
  readonly 'category.name': string;
  readonly 'suppliers.id': string;
  readonly 'accountingCategory.id': string;
  readonly 'storages.id': string;
  readonly 'locations.id': string;
  readonly 'retailerItem.category.id': string;
  readonly 'retailerItem.category.name': string;
  readonly 'retailerItem.suppliers.id': string;
  readonly 'retailerItem.accountingCategory.id': string;
  readonly 'retailerItem.code': string;
  readonly code: string;
  readonly isOpening: boolean;
}
