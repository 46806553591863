import { capital } from 'case';
import { Currency, IANATimezone } from '@supy.api/dictionaries';

import {
  createWorksheet,
  DataColumn,
  DEFAULT_IANA_TIMEZONE,
  getUTCOverriddenDateTime,
  saveWorkbook,
} from '@supy/common';

import { InventoryTransferEvent, InventoryTransferStateEnum } from '../core';

export async function downloadTransfersList(
  items: InventoryTransferEvent[],
  { currency, hidePrices }: { currency: Currency; hidePrices?: boolean },
  ianaTimeZone: IANATimezone = DEFAULT_IANA_TIMEZONE,
): Promise<void> {
  const columns: DataColumn[] = [
    { header: 'Code', key: 'code' },
    { header: 'Transfer From', key: 'fromLocation' },
    { header: 'Transfer To', key: 'toLocation' },
    { header: 'Created On', key: 'eventDate' },
    { header: 'Delivered On', key: 'deliveredOn' },
  ];

  if (!hidePrices) {
    columns.push({ header: `Total (${currency})`, key: 'cost' });
  }

  columns.push({ header: 'Status', key: 'state' });

  const currencyCols = new Set(['cost']);
  const dateCols = new Set(['eventDate', 'deliveredOn']);

  const { Workbook } = (await import('exceljs')).default;
  const workbook = new Workbook();

  const data = items.map(item => {
    return {
      ...item,
      eventDate: getUTCOverriddenDateTime(new Date(item.eventDate), ianaTimeZone),
      deliveredOn:
        item.state === InventoryTransferStateEnum.Received
          ? getUTCOverriddenDateTime(new Date(item.updatedAt), ianaTimeZone)
          : null,
      fromLocation: item.fromLocation.name,
      toLocation: item.toLocation.name,
      state: capital(item.state),
    };
  });

  await createWorksheet(workbook, 'Transfers', { data, columns, currencyCols, dateCols });

  const fileName = `transfers-${new Date().toISOString()}.xlsx`;

  saveWorkbook(workbook, fileName);
}
