import { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { RecipeActionEnum, ResourceEnum } from '@supy.api/permissions';

import { IsAllowedRequest, PermissionHelper, PermissionStrategy, Principal, Resource } from '@supy/authz';

@Injectable({ providedIn: 'root' })
class Create extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Recipe,
      action: RecipeActionEnum.Create,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class View extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Recipe,
      action: RecipeActionEnum.View,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class ViewRecipeHistory extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Recipe,
      action: RecipeActionEnum.ViewHistory,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class UpdateRecipeDetails extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Recipe,
      action: RecipeActionEnum.UpdateDetails,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class UpdateRecipeCost extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Recipe,
      action: RecipeActionEnum.UpdateCost,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class UpdateRecipeLocations extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Recipe,
      action: RecipeActionEnum.UpdateLocations,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class UpdateRecipeState extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Recipe,
      action: RecipeActionEnum.UpdateState,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class ViewCookBook extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Recipe,
      action: RecipeActionEnum.ViewCookbook,
      resource,
      principal,
    });
  }
}

export class RecipePermissionStrategy {
  static Create = Create;
  static View = View;
  static ViewRecipeHistory = ViewRecipeHistory;
  static UpdateRecipeDetails = UpdateRecipeDetails;
  static UpdateRecipeCost = UpdateRecipeCost;
  static UpdateRecipeLocations = UpdateRecipeLocations;
  static UpdateRecipeState = UpdateRecipeState;
  static ViewCookBook = ViewCookBook;
}
