import { ChangeDetectionStrategy, Component, inject, Input, input, output } from '@angular/core';
import { GridPagingMode, IColumnPipeArgs } from '@infragistics/igniteui-angular';
import { Store } from '@ngxs/store';
import { Currency, IANATimezone } from '@supy.api/dictionaries';

import { BaseRequestMetadata, BaseResponseMetadata, InventoryEventType, ViewBranch } from '@supy/common';
import { CurrentUserState } from '@supy/users';

import {
  InventoryEventStateEnum,
  InventoryTransferEvent,
  InventoryTransferMode,
  InventoryTransferStateEnum,
} from '../../core';
import { InventoryEvent } from '../../core/inventory-event.entity';

@Component({
  selector: 'supy-inventory-event-grid',
  templateUrl: './inventory-event-grid.component.html',
  styleUrls: ['./inventory-event-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryEventGridComponent<
  T extends InventoryEvent,
  U extends BaseRequestMetadata,
  V extends BaseResponseMetadata,
> {
  private readonly store = inject(Store);
  @Input() readonly eventType: InventoryEventType;
  @Input() readonly data: T[] = [];
  @Input() readonly requestMetadata: U;
  @Input() readonly responseMetadata: V;
  @Input() readonly currency: Currency;
  @Input() readonly currencyPrecision: number;
  @Input() readonly ianaTimeZone: IANATimezone;
  @Input() readonly idToUserLocation: Map<string, ViewBranch>;

  readonly hidePrices = input<boolean>();

  readonly cellClicked = output<string>();
  readonly paginationChanged = output<number>();

  protected readonly inventoryEventStateEnum = InventoryEventStateEnum;
  protected readonly inventoryEventType = InventoryEventType;
  protected readonly dateOptions: IColumnPipeArgs = { format: 'dd/MM/yy' };
  protected readonly paginationMode = GridPagingMode.Remote;

  get isTransfer(): boolean {
    return this.eventType === InventoryEventType.Transfer;
  }

  protected getBranchName(locationId: string) {
    return this.store.selectSnapshot(CurrentUserState.branchLocation(locationId));
  }

  protected getTransferStatus(transfer: InventoryTransferEvent): string {
    switch (transfer.state) {
      case InventoryTransferStateEnum.Requested:
        return this.idToUserLocation.has(transfer.fromLocation.id)
          ? InventoryTransferMode.Incoming
          : InventoryTransferMode.Outgoing;

      default:
        return transfer.state;
    }
  }
}
