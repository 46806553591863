<supy-grid-poc
  #gridPoc
  class="supy-create-recipe__ingredients-grid"
  [data]="ingredients"
  [pinnedBottomRowData]="pinnedBottomRowData"
  [fullWidthCellRendererParams]="fullWidthCellRendererParams"
  [getRowHeight]="getRowHeight"
  [isFullWidthRow]="isFullWidthRow"
  [defaultColDefs]="defaultColDefs"
  [fullWidthCellRenderer]="fullWidthCellRenderer"
  (gridReady)="setColDefs()"
  (cellEditingStopped)="onCellEditDone()"
  style="margin-block-end: 2rem"
/>

<ng-template #customOptionsTemplate let-option="option">
  <supy-icon [name]="getIngredientIcon(option?.type)" />
  <span>{{ getLocalizedName(option.name) }}</span>
</ng-template>

<ng-template #noMatchOption>
  <div class="supy-create-recipe__ingredients-grid-item-cell-selector-footer" i18n="@@inventory.recipe.grid.noMatch">
    Can't find your item?
    <a routerLink="/items/create/details" target="_blank">Create new item</a>
  </div>
</ng-template>
