import { first, takeUntil, timer } from 'rxjs';
import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Destroyable } from '@supy/common';
import { GridComponent } from '@supy/components';

import { InventoryRecipeCookbook, InventoryRecipeCookBookStep } from './../../core';

@Component({
  selector: 'supy-recipe-cookbook',
  templateUrl: './recipe-cookbook.component.html',
  styleUrls: ['./recipe-cookbook.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecipeCookbookComponent),
      multi: true,
    },
  ],
})
export class RecipeCookbookComponent<T = InventoryRecipeCookbook>
  extends Destroyable
  implements ControlValueAccessor, OnInit
{
  @Input() readonly recipeId: string;
  @Input() readonly cookbook: InventoryRecipeCookbook;
  @Input() set isReadonly(value: boolean) {
    this.#isReadonly = value;

    value ? this.form.disable({ emitEvent: false }) : this.form.enable({ emitEvent: false });
  }

  get isReadonly(): boolean {
    return this.#isReadonly;
  }

  #isReadonly: boolean;

  @ViewChild(GridComponent, { static: false }) private readonly grid: GridComponent;

  protected readonly form = new FormGroup({
    cookTimeInMinutes: new FormControl<number | null>(null),
    servingPersons: new FormControl<number | null>(null),
    difficulty: new FormControl<number | null>(null),
    instructions: new FormControl<string | null>(null),
    hidden: new FormControl<boolean>(false),
    steps: new FormControl<string[]>([]),
  });

  cookBookSteps: InventoryRecipeCookBookStep[] = [];

  onTouched: () => void;
  onChange: (value: T) => void;

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.onChange?.(value as T);
    });
  }

  onAddStep(): void {
    if (this.cookBookSteps.every(({ description }) => Boolean(description))) {
      this.cookBookSteps.push({ id: crypto.randomUUID(), description: null });

      this.grid.markForCheck();

      timer(0)
        .pipe(first())
        .subscribe(() => {
          this.grid.getCellByColumn(this.cookBookSteps.length - 1, 'description').editMode = true;
        });
    }
  }

  onRemoveStep(idToRemove: string): void {
    this.cookBookSteps = this.cookBookSteps.filter(({ id }) => id !== idToRemove);
  }

  writeValue(value: T): void {
    if (value) {
      this.form.patchValue(value, { emitEvent: false });
      this.cookBookSteps = this.form.value.steps?.map(step => ({ id: crypto.randomUUID(), description: step })) ?? [];
    }
  }

  registerOnChange(onChange: (value: T) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  syncForm(): void {
    this.form.patchValue(
      {
        steps: this.cookBookSteps.reduce<string[]>((acc, cur) => {
          if (cur.description) {
            acc.push(cur.description);
          }

          return acc;
        }, []),
      },
      { emitEvent: false },
    );
  }
}
