import { IdType, LocalizedData, Retailer } from '@supy/common';
import { getLocalizedName } from '@supy/settings';

export enum RetailerItemCategoryState {
  Active = 'active',
  Inactive = 'inactive',
}

class RetailerItemCategoryTotal {
  readonly ungroupedItems: number;
  readonly groups: number;
  readonly allItems: number;
}

export class RetailerItemCategory {
  readonly id: string;
  readonly retailerId: string;
  readonly 'name.en'?: string;
  readonly 'name.ar'?: string;
  readonly name: LocalizedData;
  readonly parent: IdType | null;
  readonly state: RetailerItemCategoryState;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly children?: RetailerItemCategory[];
  readonly retailer: Retailer;
  readonly total: RetailerItemCategoryTotal;
  readonly isDefault: boolean;

  constructor(retailerItemCategory: RetailerItemCategory) {
    Object.assign(this, retailerItemCategory);
    this.sortChildren();
  }

  private sortChildren() {
    if (this.children && this.children?.length > 0) {
      this.children.sort((a, b) => (getLocalizedName(a.name) || '').localeCompare(getLocalizedName(b.name) || ''));

      this.children.forEach(child => child.sortChildren());
    }
  }

  static default(): RetailerItemCategory {
    return {
      id: '',
      name: { en: '' },
      parent: null,
    } as RetailerItemCategory;
  }
}
