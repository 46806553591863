<div class="effective-date-confirmation-dialog">
  <supy-dialog
    position="center"
    initialState="open"
    (dialogClosed)="onDialogClosed($event)"
    (dialogClosing)="onDialogClosing($event)"
    (dialogOpened)="onDialogOpened($event)"
    (dialogOpening)="onDialogOpening($event)"
  >
    <supy-dialog-header>
      <h3>Save Changes</h3>
    </supy-dialog-header>
    <supy-dialog-content>
      <p>
        {{ message }}
      </p>
      <div class="effective-date-confirmation-dialog__control">
        <h6>Effective Date*</h6>
        <supy-date-input
          [formControl]="effectiveDateControl"
          [maxValue]="maximumDate()"
          [minValue]="minimumDate"
          [ianaTimeZone]="ianaTimeZone"
          displayFormat="dd MMMM yyyy"
        />
      </div>
    </supy-dialog-content>
    <supy-dialog-footer>
      <supy-button name="cancel" color="default" (buttonClick)="onCancelClick()">Cancel</supy-button>
      <supy-button
        name="confirm"
        color="primary"
        [disabled]="effectiveDateControl.invalid"
        (buttonClick)="onConfirmClick()"
      >
        Proceed
      </supy-button>
    </supy-dialog-footer>
  </supy-dialog>
</div>
