import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { IQueryResponse, Query } from '@supy/common';

import { INVENTORY_STOCK_COUNT_TEMPLATES_BFF_URI } from '../../../config';
import { InventoryStockCount } from '../core';
import {
  InventoryStockCountTemplate,
  InventoryStockCountTemplateCreate,
  InventoryStockCountTemplateDetailsResponse,
  InventoryStockCountTemplateUpdate,
  StockCountTemplateClone,
} from '../store';

@Injectable({ providedIn: 'root' })
export class InventoryStockCountTemplateService {
  private readonly httpClient = inject(HttpClient);
  private readonly uri = inject<string>(INVENTORY_STOCK_COUNT_TEMPLATES_BFF_URI);

  getTemplateById(id: string): Observable<InventoryStockCountTemplateDetailsResponse> {
    return this.httpClient.get<InventoryStockCountTemplateDetailsResponse>(`${this.uri}/${id}`);
  }

  getMany(query: Query<InventoryStockCountTemplate>): Observable<IQueryResponse<InventoryStockCountTemplate>> {
    return this.httpClient.get<IQueryResponse<InventoryStockCountTemplate>>(this.uri, {
      params: new HttpParams({
        fromObject: query.toQueryParams(),
      }),
    });
  }

  create(body: InventoryStockCountTemplateCreate): Observable<InventoryStockCountTemplate> {
    return this.httpClient.post<InventoryStockCountTemplate>(this.uri, body);
  }

  update(id: string, body: InventoryStockCountTemplateUpdate): Observable<InventoryStockCountTemplate> {
    return this.httpClient.patch<InventoryStockCountTemplate>(`${this.uri}/${id}`, body);
  }

  clone(id: string, body: StockCountTemplateClone): Observable<InventoryStockCount> {
    return this.httpClient.post<InventoryStockCount>(`${this.uri}/${id}/clone`, body);
  }

  deleteMany(id: string[]): Observable<void> {
    return this.httpClient.patch<void>(`${this.uri}/archive`, { ids: id });
  }
}
