import { IdType } from '@supy/common';
import { Nullable } from '@supy/core';

import { InventoryItemType } from '../../../core';
import { InventoryEventDetailsRequest, InventoryEventItemRequest } from './inventory-event.model';

export enum InventoryTransferStateEnum {
  Draft = 'draft',
  Submitted = 'submitted',
  Received = 'received',
  Archived = 'archived',
  Requested = 'requested',
  Rejected = 'rejected',
}

export interface InventoryTransferLocation extends IdType {
  readonly name?: string;
}

export interface InventoryTransferEventItemRequest extends InventoryEventItemRequest {
  readonly referenceId: string;
  readonly isStockable: boolean;
  readonly type: InventoryItemType;
}

export interface InventoryTransferDetailsRequest extends InventoryEventDetailsRequest {
  readonly fromLocation?: InventoryTransferLocation;
  readonly toLocation?: InventoryTransferLocation;
  readonly code?: string;
}

export interface InventoryTransferRequest extends InventoryTransferDetailsRequest {
  readonly items?: InventoryTransferEventItemRequest[];
  readonly state?: InventoryTransferStateEnum;
}

export type CreateInventoryTransferRequest = InventoryTransferRequest;
export type UpdateInventoryTransferRequest = Partial<InventoryTransferRequest> & IdType;

export enum InventoryTransferMode {
  Outgoing = 'outgoing',
  Incoming = 'incoming',
  Requested = 'requested',
}

export interface InventoryTransferFilters {
  readonly start?: Nullable<number>;
  readonly end?: Nullable<number>;
  readonly code: string | null;
  readonly locationIds: string[];
  readonly status: string | null;
}

export interface TransfersMappedFilters {
  readonly search: string;
  readonly status: string;
  readonly locationIds: string[];
  readonly dateRange: { readonly start: Date; readonly end: Date };
}

export interface InventoryTransfersRequestProps {
  readonly id: string;
  readonly code: string;
  readonly 'retailer.id': string;
  readonly 'toLocation.id': string;
  readonly 'fromLocation.id': string;
  readonly eventDate: Date | number;
  readonly 'createdBy.id': string;
  readonly 'updatedBy.id': string;
  readonly 'items.id': string;
  readonly createdAt: Date;
  readonly state: string;
}

export interface InventoryTransferCountPerState {
  readonly state: InventoryTransferMode;
  readonly count: number;
}
