import produce from 'immer';
import { tap } from 'rxjs';
import { SortDirection } from '@ag-grid-community/core';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';

import {
  BaseRequestMetadata,
  BaseResponseMetadata,
  EntityListState,
  EntityListStateModel,
  EventTime,
  LocalizedData,
  QueryBuilder,
  QueryPaging,
  SimpleUser,
} from '@supy/common';
import { SnackbarService } from '@supy/components';

import { InventoryStockCountTemplateService } from '../../services/stock-count-template.service';
import {
  InventoryStockCountsInitFilters,
  InventoryStockCountTemplatesClone,
  InventoryStockCountTemplatesCreate,
  InventoryStockCountTemplatesDelete,
  InventoryStockCountTemplatesGetMany,
  InventoryStockCountTemplatesGetOne,
  InventoryStockCountTemplatesPatchFilter,
  InventoryStockCountTemplatesPatchRequestMetadata,
  InventoryStockCountTemplatesResetFilter,
  InventoryStockCountTemplatesUpdate,
  ResetStockCountTemplateDetailsState,
} from '../actions';

export interface InventoryStockCountTemplate {
  readonly id: string;
  readonly name: string;
  readonly retailerId: string;
  readonly locationId: string;
  readonly state: 'active' | 'draft';
  readonly numberOfItems: number;

  readonly createdBy: SimpleUser;
  readonly createdAt: Date;
  readonly updatedBy?: SimpleUser;
  readonly updatedAt?: Date;

  readonly 'eventDate.creationDate': string;
}

export interface StockCountTemplateClone {
  readonly name: string;
  readonly locationIds: string[];
}

export interface InventoryStockCountTemplateDetailsResponse {
  readonly id: string;
  readonly name: string;
  readonly retailerId: string;
  readonly locationId: string;
  readonly recipes: item[];
  readonly items: item[];

  readonly createdBy: SimpleUser;
  readonly createdAt: Date;
  readonly updatedBy?: SimpleUser;
  readonly updatedAt?: Date;
}

interface item {
  readonly id: string;
  readonly code: string;
  readonly name: LocalizedData;
}

export interface InventoryStockCountTemplateCreate {
  readonly retailerId: string;
  readonly name: string;
  readonly locations: string[];
  readonly items: string[];
  readonly recipes: string[];
}

export type InventoryStockCountTemplateUpdate = Omit<InventoryStockCountTemplateCreate, 'retailerId' | 'locations'>;

export interface InventoryStockCountTemplatesStateModel extends EntityListStateModel<InventoryStockCountTemplate> {
  readonly filters: InventoryStockCountTemplatesFilters;
  readonly requestMetadata: InventoryStockCountTemplatesRequestMetadata;
  readonly responseMetadata: BaseResponseMetadata;
  readonly stockCountSelectedTemplate: InventoryStockCountTemplateDetailsResponse;
}

export interface InventoryStockCountTemplateDetails {
  readonly eventDate?: Date | null;
  readonly eventTime?: EventTime | null;
  readonly name?: string | null;
  readonly location: string | null;
}

export interface InventoryStockCountTemplatesFilters {
  readonly name: string | null;
  readonly locations: string[];
}

export interface InventoryStockCountTemplatesRequestMetadata extends BaseRequestMetadata {
  readonly retailerId: string | null;
  readonly sorting: { readonly name: SortDirection; readonly category: SortDirection };
}

const INVENTORY_STOCK_COUNT_ITEMS_STATE_TOKEN = new StateToken<InventoryStockCountTemplatesStateModel[]>(
  'inventoryStockCountTemplate',
);

const FILTERS_DEFAULT: InventoryStockCountTemplatesFilters = {
  name: null,
  locations: [],
};

const REQUEST_META_DEFAULT: InventoryStockCountTemplatesRequestMetadata = {
  page: 0,
  limit: 50,
  retailerId: null,
  sorting: {
    name: 'asc',
    category: 'asc',
  },
};
const RESPONSE_META_DEFAULT: BaseResponseMetadata = { total: 0, count: 0 };

@State<InventoryStockCountTemplatesStateModel>({
  name: INVENTORY_STOCK_COUNT_ITEMS_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    filters: FILTERS_DEFAULT,
    requestMetadata: REQUEST_META_DEFAULT,
    responseMetadata: RESPONSE_META_DEFAULT,
    stockCountSelectedTemplate: null,
  },
})
@Injectable()
export class InventoryStockCountTemplatesState extends EntityListState {
  protected readonly router = inject(Router);
  readonly #stockCountTemplatesService = inject(InventoryStockCountTemplateService);
  readonly #snackbar = inject(SnackbarService);

  @Selector()
  static templates(state: InventoryStockCountTemplatesStateModel) {
    return state?.entities;
  }

  @Selector()
  static selectedTemplate(state: InventoryStockCountTemplatesStateModel) {
    return state?.stockCountSelectedTemplate;
  }

  @Selector()
  static requestMetadata(state: InventoryStockCountTemplatesStateModel) {
    return state?.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: InventoryStockCountTemplatesStateModel) {
    return state?.responseMetadata;
  }

  @Selector()
  static filters(state: InventoryStockCountTemplatesStateModel) {
    return EntityListState.currentFilters<InventoryStockCountTemplatesFilters>(state);
  }

  @Action(InventoryStockCountTemplatesGetOne)
  getOneTemplate(
    ctx: StateContext<InventoryStockCountTemplatesStateModel>,
    action: InventoryStockCountTemplatesGetOne,
  ) {
    return this.#stockCountTemplatesService.getTemplateById(action.id).pipe(
      tap(template => {
        return ctx.patchState({
          stockCountSelectedTemplate: template,
        });
      }),
    );
  }

  @Action(ResetStockCountTemplateDetailsState)
  resetSelectedTemplate(ctx: StateContext<InventoryStockCountTemplatesStateModel>) {
    ctx.patchState({
      stockCountSelectedTemplate: null,
    });
  }

  @Action(InventoryStockCountsInitFilters)
  inventoryStockCountsInitFilter(ctx: StateContext<InventoryStockCountTemplatesStateModel>) {
    super.initFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(InventoryStockCountTemplatesPatchFilter)
  patchInventoryStockCountsFilter(
    ctx: StateContext<InventoryStockCountTemplatesStateModel>,
    action: InventoryStockCountTemplatesPatchFilter,
  ) {
    super.patchFilter(ctx, action.payload, FILTERS_DEFAULT);

    return ctx.dispatch([
      new InventoryStockCountTemplatesPatchRequestMetadata({ page: 0 }),
      new InventoryStockCountTemplatesGetMany(),
    ]);
  }

  @Action(InventoryStockCountTemplatesResetFilter)
  resetInventoryStockCountsFilter(ctx: StateContext<InventoryStockCountTemplatesStateModel>) {
    super.resetFilter(ctx, FILTERS_DEFAULT);

    return ctx.dispatch([
      new InventoryStockCountTemplatesPatchRequestMetadata({ page: 0 }),
      new InventoryStockCountTemplatesGetMany(),
    ]);
  }

  @Action(InventoryStockCountTemplatesPatchRequestMetadata)
  patchRequestMetadata(
    ctx: StateContext<InventoryStockCountTemplatesStateModel>,
    { payload }: InventoryStockCountTemplatesPatchRequestMetadata,
  ) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(InventoryStockCountTemplatesGetMany)
  getInventoryStockCountTemplates(ctx: StateContext<InventoryStockCountTemplatesStateModel>) {
    return this.#stockCountTemplatesService.getMany(this.getQuery(ctx.getState())).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(ctx, data);
      }),
    );
  }

  @Action(InventoryStockCountTemplatesClone)
  clone(ctx: StateContext<InventoryStockCountTemplatesStateModel>, action: InventoryStockCountTemplatesClone) {
    return this.#stockCountTemplatesService.clone(action.payload.id, action.payload.values).pipe(
      tap(() => {
        ctx.dispatch(new InventoryStockCountTemplatesGetMany());
      }),
    );
  }

  @Action(InventoryStockCountTemplatesDelete)
  deleteMany(ctx: StateContext<InventoryStockCountTemplatesStateModel>, action: InventoryStockCountTemplatesDelete) {
    return this.#stockCountTemplatesService.deleteMany(action.payload.ids).pipe(
      tap(() => {
        ctx.dispatch(new InventoryStockCountTemplatesGetMany());
      }),
    );
  }

  @Action(InventoryStockCountTemplatesCreate)
  create(ctx: StateContext<InventoryStockCountTemplatesStateModel>, action: InventoryStockCountTemplatesCreate) {
    return this.#stockCountTemplatesService.create(action.payload).pipe(
      tap(async () => {
        this.#snackbar.success(
          $localize`:@@inventory.stockCountTemplate.createSuccessful: Stock count template created successfully`,
        );
        await this.router.navigate(['/inventory/stock-count-templates']);
      }),
    );
  }

  @Action(InventoryStockCountTemplatesUpdate)
  update(ctx: StateContext<InventoryStockCountTemplatesStateModel>, { payload }: InventoryStockCountTemplatesUpdate) {
    return this.#stockCountTemplatesService.update(payload.id, payload.values).pipe(
      tap(async () => {
        this.#snackbar.success(
          $localize`:@@inventory.stockCountTemplate.updateSuccessful: Stock count template updated successfully`,
        );
        await this.router.navigate(['/inventory/stock-count-templates']);
      }),
    );
  }

  getQuery(state: InventoryStockCountTemplatesStateModel, options?: { noLimit: boolean }) {
    const { locations, name } = state.filters;
    // start, end WAITING FOR BACKEND
    const qb = new QueryBuilder<InventoryStockCountTemplate>({
      filtering: [],

      paging: options?.noLimit
        ? QueryPaging.NoLimit
        : { offset: state.requestMetadata.page * state.requestMetadata.limit, limit: state.requestMetadata.limit },
      ordering: [
        {
          by: 'createdAt',
          dir: 'desc',
        },
        { by: 'id', dir: 'desc' },
      ],
    });

    if (name) {
      qb.filtering.setFilter({
        by: 'name',
        match: name,
        op: 'like',
      });
    }

    if (state.requestMetadata.retailerId) {
      qb.filtering.setFilter({
        by: 'retailerId',
        match: state.requestMetadata.retailerId,
        op: 'eq',
      });
    }

    if (locations?.length) {
      qb.filtering.setFilter({
        by: 'locationId',
        match: locations,
        op: 'in',
      });
    }

    return qb.build();
  }
}
