import { capital } from 'case';
import { Currency, IANATimezone } from '@supy.api/dictionaries';

import {
  createWorksheet,
  DataColumn,
  DEFAULT_IANA_TIMEZONE,
  getUTCOverriddenDateTime,
  InventoryEventType,
  saveWorkbook,
} from '@supy/common';

import { InventoryProductionEvent, InventoryWastageEvent } from '../core';

export async function downloadInventoryEventList(
  items: (InventoryWastageEvent | InventoryProductionEvent)[],
  {
    currency,
    eventType,
    hidePrices,
  }: {
    currency: Currency;
    eventType: InventoryEventType;
    hidePrices?: boolean;
  },
  getBranchName: (id: string) => string,
  ianaTimeZone: IANATimezone = DEFAULT_IANA_TIMEZONE,
): Promise<void> {
  const columns: DataColumn[] = [
    { header: 'Event Date', key: 'eventDate' },
    { header: 'Event Name', key: 'name' },
    { header: 'Items', key: 'itemCount' },
  ];

  if (!hidePrices) {
    columns.push({ header: `Total (${currency})`, key: 'cost' });
  }

  columns.push(
    { header: 'Location', key: 'location' },
    { header: 'Created By', key: 'createdBy' },
    { header: 'Status', key: 'state' },
  );

  const currencyCols = new Set(['cost']);
  const dateCols = new Set(['eventDate']);

  const { Workbook } = (await import('exceljs')).default;
  const workbook = new Workbook();

  const data = items.map(item => {
    return {
      ...item,
      eventDate: getUTCOverriddenDateTime(new Date(item.eventDate), ianaTimeZone),
      location: getBranchName(item.location.id),
      createdBy: `${item.createdBy?.firstName} ${item.createdBy?.lastName}`,
      state: capital(item.state),
      itemCount: item.items?.length ?? 0,
    };
  });

  await createWorksheet(workbook, `${capital(eventType)} Events`, { data, columns, currencyCols, dateCols });

  const fileName = `${eventType}-events-${new Date().toISOString()}.xlsx`;

  saveWorkbook(workbook, fileName);
}
