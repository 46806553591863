import { inject, InjectionToken } from '@angular/core';

import { EndpointConfig } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

export interface InventoryConfig extends EndpointConfig {}

export const INVENTORY_ITEM_BFF_URI = new InjectionToken<string>('Inventory Item BFF URI', {
  factory: () => {
    const config = inject<EndpointConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/inventory-items`;
  },
});

export const INVENTORY_RECIPE_BFF_URI = new InjectionToken<string>('Inventory Recipe BFF URI', {
  factory: () => {
    const config = inject<EndpointConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/inventory-recipes`;
  },
});

export const PACKAGING_BFF_URI = new InjectionToken<string>('Packaging BFF URI', {
  factory: () => {
    const config = inject<EndpointConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/item-packages`;
  },
});

export const INVENTORY_WASTAGES_BFF_URI = new InjectionToken<string>('Inventory Wastages BFF URI', {
  factory: () => {
    const config = inject<EndpointConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/inventory-wastages`;
  },
});

export const INVENTORY_PRODUCTIONS_BFF_URI = new InjectionToken<string>('Inventory Productions BFF URI', {
  factory: () => {
    const config = inject<EndpointConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/inventory-productions`;
  },
});

export const INVENTORY_TRANSFERS_BFF_URI = new InjectionToken<string>('Inventory Transfers BFF URI', {
  factory: () => {
    const config = inject<EndpointConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/inventory-transfers`;
  },
});

export const INVENTORY_STOCK_COUNT_BFF_URI = new InjectionToken<string>('Inventory Stock Count BFF URI', {
  factory: () => {
    const config = inject<EndpointConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/inventory-stock-counts`;
  },
});

export const INVENTORY_STOCK_COUNT_TEMPLATES_BFF_URI = new InjectionToken<string>(
  'Inventory Stock Count Templates BFF URI',
  {
    factory: () => {
      const config = inject<EndpointConfig>(APP_CONFIG);

      return `${config.apiUrlBff}${config.apiUrlPrefix}/stock-count-templates`;
    },
  },
);
