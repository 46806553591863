import { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { ProductionActionEnum, ResourceEnum } from '@supy.api/permissions';

import { IsAllowedRequest, PermissionHelper, PermissionStrategy, Principal, Resource } from '@supy/authz';

@Injectable({ providedIn: 'root' })
class Create extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Production,
      action: ProductionActionEnum.Create,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class View extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Production,
      action: ProductionActionEnum.View,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Delete extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Production,
      action: ProductionActionEnum.Delete,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
class Submit extends PermissionStrategy {
  private readonly permissionHelper = inject(PermissionHelper);

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Production,
      action: ProductionActionEnum.Submit,
      resource,
      principal,
    });
  }
}

export class ProductionPermissionStrategy {
  static Create = Create;
  static View = View;
  static Delete = Delete;
  static Submit = Submit;
}
