import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IANATimezone } from '@supy.api/dictionaries';

import { getRetailerTimeZoneHelperMessage, InventoryEventType } from '@supy/common';
import {
  BaseDialogComponent,
  ButtonModule,
  DateInputModule,
  DialogComponent,
  DialogModule,
  DialogService,
  DropdownTreeModule,
  DropdownTreeNode,
  IconModule,
  InputModule,
  SnackbarService,
  TooltipModule,
} from '@supy/components';

import { InventoryEventRepeatAllProps } from '../../core';

@Component({
  selector: 'supy-inventory-event-repeat',
  standalone: true,
  imports: [
    CommonModule,
    InputModule,
    DateInputModule,
    TooltipModule,
    DropdownTreeModule,
    ReactiveFormsModule,
    IconModule,
    DialogModule,
    ButtonModule,
  ],
  providers: [DialogService],
  templateUrl: './inventory-event-repeat.component.html',
  styleUrl: './inventory-event-repeat.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryEventRepeatComponent extends BaseDialogComponent implements OnInit, OnDestroy {
  readonly #snackbarService = inject(SnackbarService);

  protected readonly getRetailerTimeZoneHelperMessage = getRetailerTimeZoneHelperMessage;
  protected readonly inventoryEventType = InventoryEventType;

  @Input() readonly eventType: InventoryEventType;
  @Input() readonly locations: DropdownTreeNode<string>[];
  @Input() readonly toLocations: DropdownTreeNode<string>[];
  @Input() readonly ianaTimeZone: IANATimezone;
  @Input() readonly utcOffset: number;
  @Input() readonly minDate: Date;
  @Input() readonly maxDate: Date;

  @Input() readonly chosenLocationId?: string;

  @Input() readonly chosenFromLocationId?: string;
  @Input() readonly chosenToLocationId?: string;

  protected readonly form = new UntypedFormGroup({
    eventDate: new UntypedFormControl(new Date()),
  });

  @ViewChild(DialogComponent) protected readonly dialog: DialogComponent;

  @Output() readonly confirm = new EventEmitter<InventoryEventRepeatAllProps>();
  @Output() readonly cancel = new EventEmitter<void>();

  ngOnInit() {
    if (this.eventType === InventoryEventType.Transfer) {
      this.form.addControl('fromLocation', new UntypedFormControl(this.chosenFromLocationId));
      this.form.addControl('toLocation', new UntypedFormControl(this.chosenToLocationId));
    } else {
      this.form.addControl('location', new UntypedFormControl(this.chosenLocationId));
      this.form.addControl('name', new UntypedFormControl(''));
    }
  }

  onConfirmClick(): void {
    this.closeDialog();

    const formValue = this.form.value as InventoryEventRepeatAllProps;

    this.confirm.emit(formValue);
  }

  protected onSelectFromLocation(locationId: string | null): void {
    const toLocationId = this.form.get('toLocation')?.value as string;

    if (locationId && toLocationId) {
      if (locationId === toLocationId) {
        this.showSameLocationTransferErrorMessage();
        this.form.get(['fromLocation'])?.setValue(null);
      }
    }
  }

  protected onSelectToLocation(locationId: string | null): void {
    const fromLocationId = this.form.get('fromLocation')?.value as string;

    if (locationId && fromLocationId) {
      if (locationId === fromLocationId) {
        this.showSameLocationTransferErrorMessage();
        this.form.get(['toLocation'])?.setValue(null);
      }
    }
  }

  private showSameLocationTransferErrorMessage(): void {
    this.#snackbarService.open(
      $localize`:@@inventory.event.details.showSameLocationTransferErrorMessage:You can't transfer to the same location`,
      { variant: 'error' },
    );
  }

  destroyed$ = new Subject<void>();

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
