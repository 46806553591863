import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { IDialogCancellableEventArgs, IDialogEventArgs } from '@infragistics/igniteui-angular';
import { IANATimezone } from '@supy.api/dictionaries';

import { Destroyable, getDateInTimeZone, getRetailerTimeZoneShiftedDate } from '@supy/common';
import { DialogComponent, IDialogComponent } from '@supy/components';

@Component({
  selector: 'supy-effective-date-confirmation-dialog',
  templateUrl: './effective-date-confirmation-dialog.component.html',
  styleUrls: ['./effective-date-confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EffectiveDateConfirmationDialogComponent extends Destroyable implements IDialogComponent, OnInit {
  @ViewChild(DialogComponent, { static: true }) readonly dialog: DialogComponent;

  @Input() readonly minimumDate: Date;
  @Input() readonly message: string;
  @Input() readonly ianaTimeZone: IANATimezone;
  @Input() readonly utcOffset: number;
  @Input() readonly isTodayDefault: boolean = false;

  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();
  @Output() readonly confirm = new EventEmitter<{ effectiveDate: Date }>();
  @Output() readonly cancel = new EventEmitter<void>();

  protected readonly effectiveDateControl = new FormControl<Date | null>(null, [Validators.required]);
  protected readonly maximumDate = signal<Date | null>(null);

  ngOnInit(): void {
    this.initData();
  }

  protected onCancelClick(): void {
    this.closeDialog();
    this.cancel.emit();
  }

  protected onConfirmClick(): void {
    const effectiveDate = this.effectiveDateControl.getRawValue();

    if (effectiveDate) {
      this.closeDialog();
      this.confirm.emit({
        effectiveDate: getRetailerTimeZoneShiftedDate(effectiveDate, this.ianaTimeZone, this.utcOffset),
      });
    }
  }

  private initData(): void {
    const maximumDate = getDateInTimeZone(new Date(), this.ianaTimeZone);

    if (this.isTodayDefault) {
      this.effectiveDateControl.setValue(maximumDate);
    } else {
      this.effectiveDateControl.setValue(null);
      maximumDate.setDate(maximumDate.getDate() - 1);
    }
    this.maximumDate.set(maximumDate);
  }

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }
}
