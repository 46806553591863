import { takeUntil } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output, Signal, signal, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { IDialogCancellableEventArgs, IDialogEventArgs } from '@infragistics/igniteui-angular';

import { FormComponent, SkeletonObjectType, Uom } from '@supy/common';
import { DialogComponent, DialogService, IDialogComponent } from '@supy/components';
import { getLocalizedName } from '@supy/settings';

import {
  CreatePackagingRequest,
  Packaging,
  PackagingGroup,
  PackagingLevel,
  PackagingQuantityRequest,
  PackagingScopeEnum,
  UpdatePackagingRequest,
} from '../../core';

interface PackageFormData {
  packageName: string | null;
  baseUnit: string;
  amount: number | null;
  itemName: string | null;
  usedAsPiece: boolean;
  isPreferred: boolean;
}

interface PackageItemName {
  readonly baseUnit: string;
  readonly amount?: number;
  readonly packageName: string;
}

@Component({
  selector: 'supy-create-package-item-dialog',
  styleUrls: ['create-package-item-dialog.component.scss'],
  templateUrl: 'create-package-item-dialog.component.html',
  providers: [DialogService],
})
export class CreatePackageItemDialogComponent
  extends FormComponent<PackageFormData>
  implements OnInit, IDialogComponent
{
  @Input() readonly item: SkeletonObjectType;
  @Input() readonly packages: PackagingGroup[];
  @Input() readonly isDeletable: boolean;
  @Input() readonly packageItem?: Packaging;
  @Input() readonly basePackageItem?: Packaging;
  @Input() set units(value: Uom[]) {
    this.#units = value;
  }

  get units(): Uom[] {
    return this.#units.map(unit => ({
      ...unit,
      name: this.getPacakgeUnitName(unit),
    }));
  }

  @Input() readonly baseUom: Uom | null;
  @Input() readonly scope: PackagingScopeEnum;
  @Input() readonly isItemUsedAsPiece: boolean;
  @Input() readonly isLoading: Signal<boolean> = signal(true);

  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();
  @Output() readonly createClicked = new EventEmitter<CreatePackagingRequest>();
  @Output() readonly saveClicked = new EventEmitter<UpdatePackagingRequest>();
  @Output() readonly deleteClicked = new EventEmitter<void>();

  @ViewChild(DialogComponent, { static: true }) readonly dialog: DialogComponent;

  #units: Uom[] = [];

  protected baseUnitName = signal<string>('');

  get selectedBaseUom(): Uom | null {
    return this.units.find(u => u.id === this.getValue('baseUnit')) ?? null;
  }

  get itemName(): string {
    return getLocalizedName(this.packageItem?.itemName) || this.item.name;
  }

  constructor() {
    super({
      packageName: [null],
      baseUnit: [null],
      amount: [null, [Validators.required, Validators.min(Number.MIN_VALUE)]],
      itemName: [{ value: null, disabled: true }],
      usedAsPiece: [false],
      isPreferred: [false],
    });
  }

  ngOnInit() {
    this.initData();
    this.setupFormListeners();
    this.setItemNameControlValue();
    this.checkQuantity();
  }

  initData(): void {
    const packageItemBaseUnit = this.packageItem?.baseUnit;
    const basePackageItem = this.basePackageItem?.baseUnit;

    this.baseUnitName.set(packageItemBaseUnit?.name ?? basePackageItem?.name ?? this.baseUom?.name ?? '');

    this.reconfigure('packageName', { value: this.packageItem?.unitName ?? null });
    this.reconfigure('baseUnit', { value: packageItemBaseUnit?.id ?? basePackageItem?.id ?? this.baseUom?.id ?? null });
    this.reconfigure('amount', { value: this.getPackagingAmount(this.packageItem) });
    this.reconfigure('isPreferred', { value: this.packageItem?.isPreferred ?? false });

    if (this.packageItem && !this.baseUom?.isPiece) {
      this.#units = this.#units.filter(({ isPiece }) => !isPiece);
    }
  }

  setupFormListeners(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.setItemNameControlValue();
    });

    this.form
      .get('baseUnit')
      ?.valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.form.get('amount')?.setValue(null);
        this.checkQuantity();
      });
  }

  checkQuantity(): void {
    if (this.selectedBaseUom?.isPiece && (!this.packages.length || !this.basePackageItem)) {
      this.form.get('amount')?.setValue(1);
      this.form.get('amount')?.disable();
    } else {
      this.form.get('amount')?.enable();
    }
  }

  getPacakgeUnitName(unit: Uom): string {
    if (this.packageItem?.level === 0) {
      return unit.name;
    }

    const parentId = this.packageItem?.parent?.id;
    const parentPackage = parentId
      ? this.packages.flatMap(group => group.items).find(item => item.id === parentId)
      : null;

    const packageName = parentPackage?.packageName ?? this.basePackageItem?.packageName ?? unit.name;

    return packageName.replace(/^\(|\)$/g, '');
  }

  getPackagingAmount(packaging: Packaging | undefined): number | null {
    if (!packaging) {
      return null;
    }

    return packaging.level === 0 ? packaging.quantity.baseUnit : (packaging.quantity?.parent ?? null);
  }

  setItemNameControlValue() {
    const baseUnitId = this.getValue('baseUnit') as string;
    const baseUnitName = this.units.find(({ id }) => id === baseUnitId)?.name;

    this.getControl('itemName')?.setValue(
      this.getPackageItemName({
        baseUnit: baseUnitName ?? '',
        amount: (this.getValue('amount') as number) ?? 0,
        packageName: (this.getValue('packageName') as string) ?? '',
      }),
      { emitEvent: false },
    );
  }

  getPackageItemName(value: PackageItemName): string {
    return `${this.itemName} ${this.getPackageName(value)}`;
  }

  getPackageName(value: PackageItemName): string {
    const basePackageName = this.basePackageItem?.packageName;
    const currentPackageNamePart =
      basePackageName && this.basePackageItem?.level !== 0
        ? basePackageName.slice(basePackageName.indexOf('(') + 1, basePackageName.indexOf(')'))
        : (basePackageName ?? '');

    const baseUnitName = this.units.find(({ id }) => id === value.baseUnit)?.name;

    return !this.basePackageItem || this.packageItem?.level === 0
      ? `${value.amount ?? ''}${baseUnitName ?? value.baseUnit}${value.packageName ? ` ${value.packageName}` : ''}`
      : `${value.packageName ? `${value.packageName} ` : ''}(${value.amount ? `${value.amount}x` : ''}${currentPackageNamePart})`;
  }

  protected onBaseUnitChange(value: string[]): void {
    this.reconfigure('baseUnit', { value: value.at(0) });
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  onCreateClick(): void {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    const formValue = this.form.getRawValue() as PackageFormData;
    const packageItemName: PackageItemName = {
      amount: formValue.amount ?? 0,
      packageName: formValue.packageName ?? '',
      baseUnit: formValue.baseUnit ?? '',
    };

    let quantity = {} as PackagingQuantityRequest;

    if (this.basePackageItem) {
      quantity = { parent: formValue.amount || 0 };
    } else {
      quantity = { baseUnit: formValue.amount || 0 };
    }

    let payload: CreatePackagingRequest = {
      unitName: formValue.packageName ?? '',
      packageName: this.getPackageName(packageItemName),
      quantity,
      source: { id: this.item.id, scope: this.scope },
      usedAsPiece: !this.basePackageItem && this.selectedBaseUom?.isPiece ? true : formValue.usedAsPiece,
      isPreferred: formValue.isPreferred,
      level: (this.basePackageItem ? this.basePackageItem.level + 1 : 0) as PackagingLevel,
    };

    if (this.basePackageItem) {
      payload = { ...payload, parent: { id: this.basePackageItem.id } };
    } else {
      payload = {
        ...payload,
        baseUnit: {
          id: formValue.baseUnit,
        },
      };
    }

    this.createClicked.emit(payload);
  }

  onSaveClick(): void {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      const formValue = this.form.getRawValue() as PackageFormData;

      const packageItemName: PackageItemName = {
        amount: formValue.amount ?? 0,
        packageName: formValue.packageName ?? '',
        baseUnit: formValue.baseUnit ?? '',
      };

      let quantity = {} as PackagingQuantityRequest;

      if (this.basePackageItem) {
        quantity = { parent: formValue.amount || 0 };
      } else {
        quantity = { baseUnit: formValue.amount || 0 };
      }

      let payload: UpdatePackagingRequest = {
        unitName: formValue.packageName ?? '',
        packageName: this.getPackageName(packageItemName),
        quantity,
        applyQuantityUpdates: true,
        isPreferred: formValue.isPreferred,
      };

      if (!this.basePackageItem) {
        payload = {
          ...payload,
          baseUnit: {
            id: formValue.baseUnit,
          },
        };
      }

      this.saveClicked.emit(payload);
    }
  }

  protected onBaseUnitClear(): void {
    this.reconfigure('baseUnit', { value: null });
  }
}
