<div class="supy-create-recipe__costing-chart">
  <supy-donut-chart
    *ngIf="averageFoodCost"
    [data]="chartData"
    [withLegend]="true"
    [allowSliceSelection]="true"
    (sliceClicked)="onSliceClicked($event)"
  >
    <div sliceSelectionTemplate>
      <div class="supy-create-recipe__costing-chart-text">
        <strong>{{ chartData[activeSlice]?.value | supyPrecision: currencyPrecision }} {{ currency }}</strong>
        <div>
          <strong>{{ chartData[activeSlice]?.category }}</strong>
        </div>
      </div>
    </div>
  </supy-donut-chart>
  <supy-donut-chart *ngIf="!averageFoodCost" [data]="defaultSliceSelection" />

  <supy-button-group
    class="supy-create-recipe__costing-chart-selector"
    [buttons]="chartTypes"
    [(value)]="selectedChartType"
  />
</div>
