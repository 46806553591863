import produce from 'immer';
import { forkJoin, mergeMap, switchMap, tap } from 'rxjs';
import { SortDirection } from '@ag-grid-community/core';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Language } from '@supy.api/dictionaries';

import {
  BaseRequestMetadata,
  BaseResponseMetadata,
  EntityListState,
  EntityListStateModel,
  EventTime,
  IQueryFiltering,
  Query,
  QueryBuilder,
  QueryPaging,
} from '@supy/common';
import { CurrentUserState } from '@supy/users';

import { BaseItemsService } from '../../../items';
import {
  GetStockCountItemsResponse,
  GetSubStockCountItemsResponse,
  InventoryStockCountEventState,
  InventoryStockCountItem,
  InventoryStockCountRequestProps,
} from '../../core';
import { InventoryStockCountService } from '../../services';
import {
  InventoryStockCountItemsDiscard,
  InventoryStockCountItemsGetExportable,
  InventoryStockCountItemsGetMany,
  InventoryStockCountItemsHidePackage,
  InventoryStockCountItemsInitFilters,
  InventoryStockCountItemsPatchFilters,
  InventoryStockCountItemsPatchRequestMeta,
  InventoryStockCountItemsReopen,
  InventoryStockCountItemsResetFilters,
  InventoryStockCountItemsResetStockCount,
  InventoryStockCountItemsSave,
  InventoryStockCountItemsSetFilters,
  InventoryStockCountItemsSetPackageAsDefault,
  InventoryStockCountItemsSetType,
  InventoryStockCountItemsToggleZeroingUncounted,
  InventoryStockCountItemsUnHideAllPackages,
} from '../actions';

export interface InventoryStockCountItemsStateModel extends EntityListStateModel<InventoryStockCountItem> {
  readonly entitiesInitial?: InventoryStockCountItem[] | null;
  readonly filters: InventoryStockCountItemsFilters;
  readonly requestMetadata: InventoryStockCountItemsRequestMetadata;
  readonly responseMetadata: BaseResponseMetadata;
  readonly stockCount: InventoryStockCountDetails;
  readonly isSubStockCount: boolean;
  readonly exportableItems: InventoryStockCountItem[];
}

export interface InventoryStockCountDetails {
  readonly eventDate?: Date | null;
  readonly eventTime?: EventTime | null;
  readonly name?: string | null;
  readonly location: string | null;
  readonly stockCountId: string | null;
  readonly state: InventoryStockCountEventState | null;
  readonly isOpening?: boolean | null;
}

export interface InventoryStockCountItemsFilters {
  readonly search: string | null;
  readonly categoryIds: string[];
  readonly accountingCategories: string[];
  readonly suppliers: string[];
  readonly storageId: string | null;
  readonly tab: InventoryStockCountTab | null;
}

export interface InventoryStockCountItemsRequestMetadata extends BaseRequestMetadata {
  readonly retailerId: string | null;
  readonly stockCountId: string;
  readonly subStockCountId: string;
  readonly sorting: { readonly name: SortDirection; readonly category: SortDirection };
}

export enum InventoryStockCountTab {
  Stockable = 'stockable',
  NonStockable = 'non-stockable',
}

const INVENTORY_STOCK_COUNT_ITEMS_STATE_TOKEN = new StateToken<InventoryStockCountItemsStateModel[]>(
  'inventoryStockCount',
);

const FILTERS_DEFAULT: InventoryStockCountItemsFilters = {
  search: null,
  categoryIds: [],
  accountingCategories: [],
  suppliers: [],
  storageId: null,
  tab: null,
};

const REQUEST_META_DEFAULT: InventoryStockCountItemsRequestMetadata = {
  stockCountId: '',
  subStockCountId: '',
  page: 0,
  limit: 50,
  retailerId: null,
  sorting: {
    name: null,
    category: null,
  },
};
const RESPONSE_META_DEFAULT: BaseResponseMetadata = { total: 0, count: 0 };

const DETAILS_DEFAULT: InventoryStockCountDetails = {
  eventDate: null,
  eventTime: null,
  name: null,
  location: null,
  stockCountId: null,
  state: null,
  isOpening: null,
};

interface GetQueryOptions {
  readonly noLimit?: boolean;
  readonly subCountItems?: boolean;
  readonly subCountRecipes?: boolean;
}

@State<InventoryStockCountItemsStateModel>({
  name: INVENTORY_STOCK_COUNT_ITEMS_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    filters: FILTERS_DEFAULT,
    stockCount: DETAILS_DEFAULT,
    isSubStockCount: false,
    requestMetadata: REQUEST_META_DEFAULT,
    responseMetadata: RESPONSE_META_DEFAULT,
    entitiesInitial: null,
    exportableItems: [],
  },
})
@Injectable()
export class InventoryStockCountItemsState extends EntityListState {
  protected readonly router = inject(Router);
  readonly #stockCountsService = inject(InventoryStockCountService);
  readonly #baseItemsService = inject(BaseItemsService);
  readonly #store = inject(Store);

  @Selector()
  static items(state: InventoryStockCountItemsStateModel) {
    return EntityListState.all(state);
  }

  @Selector()
  static currentItem(state: InventoryStockCountItemsStateModel) {
    return EntityListState.current(state);
  }

  static item(id: string, next?: boolean) {
    return createSelector([InventoryStockCountItemsState], (state: InventoryStockCountItemsStateModel) => {
      return EntityListState.one(id, next)(state);
    });
  }

  @Selector()
  static itemsInitial(state: InventoryStockCountItemsStateModel) {
    return state.entitiesInitial;
  }

  @Selector()
  static appliedFiltersCount(state: InventoryStockCountItemsStateModel) {
    return EntityListState.appliedFiltersCount(state, FILTERS_DEFAULT, ['status']);
  }

  @Selector()
  static filters(state: InventoryStockCountItemsStateModel) {
    return EntityListState.currentFilters<InventoryStockCountItemsFilters>(state);
  }

  @Selector()
  static tab(state: InventoryStockCountItemsStateModel) {
    return EntityListState.currentFilters<InventoryStockCountItemsFilters>(state).tab;
  }

  @Selector()
  static requestMetadata(state: InventoryStockCountItemsStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: InventoryStockCountItemsStateModel) {
    return state.responseMetadata;
  }

  @Selector()
  static stockCount({ stockCount }: InventoryStockCountItemsStateModel) {
    return stockCount;
  }

  @Selector()
  static eventState({ stockCount }: InventoryStockCountItemsStateModel) {
    return stockCount.state;
  }

  @Selector()
  static stockCountLocation({ stockCount }: InventoryStockCountItemsStateModel) {
    return stockCount.location;
  }

  @Selector()
  static exportableItems(state: InventoryStockCountItemsStateModel) {
    return state.exportableItems;
  }

  @Action(InventoryStockCountItemsInitFilters)
  inventoryStockCountInitFilter(ctx: StateContext<InventoryStockCountItemsStateModel>) {
    super.initFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(InventoryStockCountItemsSetFilters)
  setInventoryStockCountFilter(
    ctx: StateContext<InventoryStockCountItemsStateModel>,
    action: InventoryStockCountItemsSetFilters,
  ) {
    super.setFilter(ctx, action.payload, FILTERS_DEFAULT);
    ctx.dispatch([new InventoryStockCountItemsPatchRequestMeta({ page: 0 }), new InventoryStockCountItemsGetMany()]);
  }

  @Action(InventoryStockCountItemsPatchFilters)
  patchInventoryStockCountFilter(
    ctx: StateContext<InventoryStockCountItemsStateModel>,
    action: InventoryStockCountItemsPatchFilters,
  ) {
    super.patchFilter(ctx, action.payload, FILTERS_DEFAULT);
    ctx.dispatch([new InventoryStockCountItemsPatchRequestMeta({ page: 0 }), new InventoryStockCountItemsGetMany()]);
  }

  @Action(InventoryStockCountItemsResetFilters)
  resetInventoryStockCountFilter(ctx: StateContext<InventoryStockCountItemsStateModel>) {
    super.resetFilter(ctx, { ...FILTERS_DEFAULT, tab: ctx.getState().filters?.tab });
  }

  @Action(InventoryStockCountItemsResetStockCount)
  resetInventoryStockCountDetails(ctx: StateContext<InventoryStockCountItemsStateModel>) {
    ctx.patchState({ stockCount: DETAILS_DEFAULT });
  }

  @Action(InventoryStockCountItemsSetType)
  inventoryStockCountSetType(
    ctx: StateContext<InventoryStockCountItemsStateModel>,
    { isSubStockCount }: InventoryStockCountItemsSetType,
  ) {
    ctx.patchState({ isSubStockCount });
  }

  @Action(InventoryStockCountItemsSave)
  inventoryStockSubCountUpdate(
    ctx: StateContext<InventoryStockCountItemsStateModel>,
    { payload: { stockCountId, subStockCountId, body } }: InventoryStockCountItemsSave,
  ) {
    return this.#stockCountsService
      .updateSub(stockCountId, subStockCountId, body)
      .pipe(mergeMap(() => ctx.dispatch(new InventoryStockCountItemsGetMany())));
  }

  @Action(InventoryStockCountItemsReopen)
  inventoryStockSubCountReopen(
    ctx: StateContext<InventoryStockCountItemsStateModel>,
    { payload: { stockCountId } }: InventoryStockCountItemsReopen,
  ) {
    return this.#stockCountsService
      .reopen(stockCountId)
      .pipe(mergeMap(() => ctx.dispatch(new InventoryStockCountItemsGetMany())));
  }

  @Action(InventoryStockCountItemsDiscard)
  inventoryStockSubCountDiscard(
    ctx: StateContext<InventoryStockCountItemsStateModel>,
    { id, subCountId }: InventoryStockCountItemsDiscard,
  ) {
    return this.#stockCountsService.discard(id, subCountId).pipe(
      tap(() => ctx.patchState({ isSubStockCount: false, entitiesInitial: null })),
      tap(() => super.resetFilter(ctx, { ...FILTERS_DEFAULT }, { saveToUrl: false })),
      switchMap(() => this.router.navigateByUrl('/inventory/stock-counts')),
    );
  }

  @Action(InventoryStockCountItemsGetMany, { cancelUncompleted: true })
  getInventoryStockCount(ctx: StateContext<InventoryStockCountItemsStateModel>) {
    const state = ctx.getState();
    const isSubStockCount = state.isSubStockCount;
    const tab = state.filters.tab;
    const { stockCountId, subStockCountId } = state.requestMetadata;

    if (isSubStockCount) {
      const request =
        tab === InventoryStockCountTab.Stockable
          ? this.#stockCountsService.getSubByIdItems(
              stockCountId,
              subStockCountId,
              this.getQuery(state, { subCountItems: true }),
            )
          : this.#stockCountsService.getSubByIdRecipes(
              stockCountId,
              subStockCountId,
              this.getQuery(state, { subCountRecipes: true }),
            );

      return request.pipe(tap(response => this.handleSubStockCountItemsResponse(ctx, response)));
    } else {
      const request =
        tab === InventoryStockCountTab.Stockable
          ? this.#stockCountsService.getByIdItems(stockCountId, this.getQuery(state))
          : this.#stockCountsService.getByIdRecipes(stockCountId, this.getQuery(state));

      return request.pipe(tap(response => this.handleStockCountItemsResponse(ctx, response)));
    }
  }

  @Action(InventoryStockCountItemsPatchRequestMeta)
  patchRequestMetadata(
    ctx: StateContext<InventoryStockCountItemsStateModel>,
    { payload }: InventoryStockCountItemsPatchRequestMeta,
  ) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(InventoryStockCountItemsToggleZeroingUncounted)
  toggleZeroingUncounted(
    ctx: StateContext<InventoryStockCountItemsStateModel>,
    { id }: InventoryStockCountItemsToggleZeroingUncounted,
  ) {
    return this.#stockCountsService.toggleZeroingUncounted(id);
  }

  @Action(InventoryStockCountItemsUnHideAllPackages)
  unHideAllPackages(
    ctx: StateContext<InventoryStockCountItemsStateModel>,
    { retailerItemId }: InventoryStockCountItemsUnHideAllPackages,
  ) {
    const locationId = ctx.getState().stockCount.location;

    return this.#baseItemsService
      .unHideAllPackagings(retailerItemId, locationId)
      .pipe(tap(() => ctx.dispatch(new InventoryStockCountItemsGetMany())));
  }

  @Action(InventoryStockCountItemsSetPackageAsDefault)
  setPackageAsDefault(
    ctx: StateContext<InventoryStockCountItemsStateModel>,
    { retailerItemId, itemPackage }: InventoryStockCountItemsSetPackageAsDefault,
  ) {
    const locationId = ctx.getState().stockCount.location;
    const { packagingId, uomId } = itemPackage.unit;
    const defaultPackaging = packagingId ? { packagingId } : { uomId };

    return this.#baseItemsService
      .setDefaultPackaging({ retailerItemId, locationId, defaultPackaging })
      .pipe(tap(() => ctx.dispatch(new InventoryStockCountItemsGetMany())));
  }

  @Action(InventoryStockCountItemsHidePackage)
  hidePackage(
    ctx: StateContext<InventoryStockCountItemsStateModel>,
    { retailerItemId, itemPackage }: InventoryStockCountItemsHidePackage,
  ) {
    const locationId = ctx.getState().stockCount.location;
    const { packagingId, uomId } = itemPackage.unit;
    const packaging = packagingId ? { packagingId } : { uomId };

    return this.#baseItemsService
      .hideSinglePackaging({ retailerItemId, locationId, packaging })
      .pipe(tap(() => ctx.dispatch(new InventoryStockCountItemsGetMany())));
  }

  @Action(InventoryStockCountItemsGetExportable)
  getAllItems(ctx: StateContext<InventoryStockCountItemsStateModel>) {
    const { stockCountId, subStockCountId } = ctx.getState().requestMetadata;

    return forkJoin([
      this.#stockCountsService.getSubByIdItems(
        stockCountId,
        subStockCountId,
        this.getQuery(ctx.getState(), { noLimit: true, subCountItems: true }),
      ),
      this.#stockCountsService.getSubByIdRecipes(
        stockCountId,
        subStockCountId,
        this.getQuery(ctx.getState(), { noLimit: true }),
      ),
    ]).pipe(
      tap(responses => {
        const deserializedItems = responses
          .flatMap(({ items }) => items.data)
          .map(itemData => InventoryStockCountItem.deserialize(itemData));

        ctx.patchState({ exportableItems: deserializedItems });
      }),
    );
  }

  private getQuery(
    state: InventoryStockCountItemsStateModel,
    options?: GetQueryOptions,
  ): Query<InventoryStockCountItem & InventoryStockCountRequestProps> {
    const { storageId, categoryIds, search, tab, accountingCategories, suppliers } = state.filters;
    const isSubStockCount = state.isSubStockCount;
    const isStockable = tab === InventoryStockCountTab.Stockable;
    const isNonStockable = tab === InventoryStockCountTab.NonStockable;
    const language = this.#store.selectSnapshot(CurrentUserState.language) ?? Language.English;
    const qb = new QueryBuilder<InventoryStockCountItem & InventoryStockCountRequestProps>({
      filtering: [],
      paging: options?.noLimit
        ? QueryPaging.NoLimit
        : { offset: state.requestMetadata.page * state.requestMetadata.limit, limit: state.requestMetadata.limit },
      ordering: [],
    });

    if (state.requestMetadata.sorting.category) {
      qb.setOrder({
        by: options?.subCountItems
          ? 'retailerItem.category.name'
          : options?.subCountRecipes
            ? 'category.name'
            : 'category.id',
        dir: state.requestMetadata.sorting.category,
      });
    }

    if (state.requestMetadata.sorting.name) {
      qb.setOrder({
        by: options?.subCountItems ? `retailerItem.name.${language}` : `name.${language}`,
        dir: state.requestMetadata.sorting.name,
      });
    }

    if (options?.noLimit) {
      return qb.build();
    }

    if (search) {
      const searchFiltering: IQueryFiltering<InventoryStockCountItem & InventoryStockCountRequestProps>[] = [];

      if (isStockable && search) {
        searchFiltering.push({
          by: isSubStockCount ? `retailerItem.name.${language}` : `name.${language}`,
          match: search,
          op: 'like',
        });

        if (isSubStockCount) {
          searchFiltering.push({ by: 'retailerItem.code', match: search, op: 'like' });
        }
      }

      if (isNonStockable && search) {
        searchFiltering.push({ by: `name.${language}`, match: search, op: 'like' });

        if (isSubStockCount) {
          searchFiltering.push({ by: 'code', match: search, op: 'like' });
        }
      }

      qb.filtering.withGroup('or', searchFiltering);
    }

    if (isStockable && storageId) {
      qb.filtering.setFilter({
        by: 'storages.id',
        match: storageId,
        op: 'eq',
      });
    }

    if (categoryIds?.length) {
      if (isStockable) {
        qb.filtering.setFilter({
          by: isSubStockCount ? 'retailerItem.category.id' : 'category.id',
          match: categoryIds,
          op: 'in',
        });
      }

      if (isNonStockable) {
        qb.filtering.setFilter({
          by: 'category.id',
          match: categoryIds,
          op: 'in',
        });
      }
    }

    if (suppliers?.length) {
      if (isStockable) {
        qb.filtering.setFilter({
          by: isSubStockCount ? 'retailerItem.suppliers.id' : 'suppliers.id',
          match: suppliers,
          op: 'in',
        });
      }

      if (isNonStockable) {
        qb.filtering.setFilter({
          by: 'suppliers.id',
          match: suppliers,
          op: 'in',
        });
      }
    }

    if (accountingCategories?.length) {
      if (isStockable) {
        qb.filtering.setFilter({
          by: isSubStockCount ? 'retailerItem.accountingCategory.id' : 'accountingCategory.id',
          match: accountingCategories,
          op: 'in',
        });
      }

      if (isNonStockable) {
        qb.filtering.setFilter({
          by: 'accountingCategory.id',
          match: accountingCategories,
          op: 'in',
        });
      }
    }

    return qb.build();
  }

  private handleStockCountItemsResponse(
    ctx: StateContext<InventoryStockCountItemsStateModel>,
    res: GetStockCountItemsResponse,
  ): void {
    ctx.patchState({
      stockCount: {
        stockCountId: res.id,
        location: res.location.id,
        state: res.state,
        eventDate: res.eventDate.creationDate,
        eventTime: res.eventDate.time,
        isOpening: res.isOpening,
        name: res.name,
      },
      responseMetadata: res.items.metadata,
      entitiesInitial: null,
    });

    const items = InventoryStockCountItem.deserializeList(res.items.data) ?? [];

    super.setMany(ctx, items);
  }

  private handleSubStockCountItemsResponse(
    ctx: StateContext<InventoryStockCountItemsStateModel>,
    res: GetSubStockCountItemsResponse,
  ): void {
    ctx.patchState({
      stockCount: {
        stockCountId: res.stockCount.id,
        location: res.location.id,
        state: res.stockCount.state,
        eventDate: res.stockCount.eventDate,
        eventTime: res.stockCount.timing,
        isOpening: res.stockCount.isOpening,
        name: res.name,
      },
      responseMetadata: res.items.metadata,
      entitiesInitial: InventoryStockCountItem.deserializeList(res.items.data) ?? [],
    });

    const items = InventoryStockCountItem.deserializeList(res.items.data) ?? [];

    super.setMany(ctx, items);
  }
}
