import { getApiDetailsDecorator, IdType, LocalizedData, PreferredType, SkeletonObjectType } from '@supy/common';
import { NonFunctionProperties, Nullable } from '@supy/core';
import { Packaging } from '@supy/packaging';

import {
  ChannelItemQuantity,
  ChannelItemResponse,
  ChannelItemRetailerItem,
  ChannelItemState,
  ChannelItemSupplierItem,
} from './channel-item.model';

const ApiProperty = getApiDetailsDecorator<ChannelItemResponse>();

export class ChannelItem {
  private constructor(args: NonFunctionProperties<ChannelItem>) {
    this.branch = args.branch;
    this.category = args.category;
    this.channel = args.channel;
    this.ckStock = args.ckStock;
    this.createdAt = args.createdAt;
    this.id = args.id;
    this.inventoryItem = args.inventoryItem;
    this.itemCode = args.itemCode;
    this.name = args.name;
    this.orderable = args.orderable;
    this.packaging = args.packaging;
    this.preferredType = args.preferredType;
    this.price = args.price;
    this.priority = args.priority;
    this.quantity = args.quantity;
    this.quantityLevels = args.quantityLevels;
    this.retailer = args.retailer;
    this.retailerItem = args.retailerItem;
    this.state = args.state;
    this.supplier = args.supplier;
    this.supplierItem = args.supplierItem;
    this.totalPrice = args.totalPrice;
    this.updatedAt = args.updatedAt;
  }

  @ApiProperty() readonly branch: IdType;
  @ApiProperty() readonly category: Nullable<SkeletonObjectType>;
  @ApiProperty() readonly channel: IdType;
  @ApiProperty() readonly ckStock: number;
  @ApiProperty() readonly createdAt: Date;
  @ApiProperty() readonly id: string;
  @ApiProperty() readonly inventoryItem: Nullable<IdType>;
  @ApiProperty() readonly itemCode: Nullable<string>;
  @ApiProperty() readonly name: LocalizedData;
  @ApiProperty() readonly orderable: boolean;
  @ApiProperty() readonly packaging: Nullable<Packaging>;
  @ApiProperty() readonly preferredType?: PreferredType;
  @ApiProperty() readonly price: number;
  @ApiProperty() readonly priority: boolean;
  @ApiProperty() readonly quantity: number;
  @ApiProperty() readonly quantityLevels: Nullable<ChannelItemQuantity>;
  @ApiProperty() readonly retailer: IdType;
  @ApiProperty() readonly retailerItem: ChannelItemRetailerItem;
  @ApiProperty() readonly state: ChannelItemState;
  @ApiProperty() readonly supplier: IdType;
  @ApiProperty() readonly supplierItem: Nullable<ChannelItemSupplierItem>;
  @ApiProperty() readonly totalPrice: number;
  @ApiProperty() readonly updatedAt: Date;

  static deserialize(data: ChannelItemResponse): ChannelItem {
    return new ChannelItem({
      branch: data.branch,
      category: data.category ?? null,
      channel: data.channel,
      ckStock: data.ckStock ?? 0,
      createdAt: data.createdAt,
      id: data.id,
      inventoryItem: data.inventoryItem ?? null,
      itemCode: data.itemCode ?? null,
      name: data.name,
      orderable: data.orderable ?? false,
      packaging: data.packaging ?? null,
      price: data.price ?? 0,
      priority: data.priority ?? false,
      quantity: data.quantity ?? 0,
      quantityLevels: data.quantityLevels ?? null,
      retailer: data.retailer,
      retailerItem: data.retailerItem,
      state: data.state,
      supplier: data.supplier,
      supplierItem: data.supplierItem ?? null,
      totalPrice: data.totalPrice ?? 0,
      updatedAt: data.updatedAt,
    });
  }

  static deserializeList(data: ChannelItemResponse[]): ChannelItem[] {
    return data.map(this.deserialize);
  }
}
