import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { IQueryResponse, Query } from '@supy/common';

import { INVENTORY_ITEM_BFF_URI } from '../config';
import {
  BaseItemInventoryApi,
  BaseItemInventoryRequestProps,
  BulkUpdateBaseItemsAffectCogsRequest,
  BulkUpdateBaseItemsMinRequest,
  BulkUpdateBaseItemsParRequest,
  MinimumEffectiveDateResponse,
  UpdateBaseItemInventoryRequest,
} from '../core';
import {
  ItemStockMovement,
  PriceHistoryRecordApi,
  PriceHistoryRequestProps,
  StockMovementRequestProps,
} from '../modules';

@Injectable({ providedIn: 'root' })
export class InventoryItemsService {
  private readonly httpClient = inject(HttpClient);
  private readonly bffUri = inject<string>(INVENTORY_ITEM_BFF_URI);

  getById(id: string): Observable<BaseItemInventoryApi> {
    return this.httpClient.get<BaseItemInventoryApi>(`${this.bffUri}/${id}`);
  }

  getMany(
    query: Query<BaseItemInventoryApi & BaseItemInventoryRequestProps>,
  ): Observable<IQueryResponse<BaseItemInventoryApi>> {
    return this.httpClient.get<IQueryResponse<BaseItemInventoryApi>>(this.bffUri, {
      params: new HttpParams({
        fromObject: query.toQueryParams(),
      }),
    });
  }

  update(id: string, body: Partial<UpdateBaseItemInventoryRequest>): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/${id}`, body);
  }

  getStockMovement(query: Query<StockMovementRequestProps>): Observable<IQueryResponse<ItemStockMovement>> {
    return this.httpClient.get<IQueryResponse<ItemStockMovement>>(`${this.bffUri}/stock-movement`, {
      params: new HttpParams({
        fromObject: query.toQueryParams(),
      }),
    });
  }

  bulkUpdateMin(body: BulkUpdateBaseItemsMinRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/bulk/min`, body);
  }

  bulkUpdatePar(body: BulkUpdateBaseItemsParRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/bulk/par`, body);
  }

  bulkUpdateAffectCogs(body: BulkUpdateBaseItemsAffectCogsRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/bulk/affect-cogs`, body);
  }

  deactivate(inventoryItemId: string, locationId: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/${inventoryItemId}/locations/${locationId}/deactivate`, null);
  }

  getPriceHistory(query: Query<PriceHistoryRequestProps>): Observable<IQueryResponse<PriceHistoryRecordApi>> {
    return this.httpClient.get<IQueryResponse<PriceHistoryRecordApi>>(`${this.bffUri}/price-history`, {
      params: new HttpParams({
        fromObject: query.toQueryParams(),
      }),
    });
  }

  getMinimumEffectiveDate(itemId: string): Observable<MinimumEffectiveDateResponse> {
    return this.httpClient.get<MinimumEffectiveDateResponse>(`${this.bffUri}/${itemId}/effective-date`);
  }
}
