import { ColDef, ICellRendererParams, NewValueParams } from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  Injector,
  input,
  model,
  OnInit,
  output,
  signal,
  ViewChild,
} from '@angular/core';
import { Currency } from '@supy.api/dictionaries';

import {
  DropdownTreeNode,
  GridPocComponent,
  InputCellEditorComponent,
  MultiSelectCellEditorComponent,
  MultiSelectCellRendererComponent,
  SortableHeaderComponent,
} from '@supy/components';
import { TaxRate } from '@supy/settings';

import { computedFoodCost, CostCenterGridItem, CostCenterPayload, RecipeStatement, SalesTypeCost } from '../../core';

@Component({
  selector: 'supy-cost-centers-grid',
  templateUrl: 'cost-centers-grid.component.html',
  styleUrls: ['./cost-centers-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CostCentersGridComponent implements OnInit {
  readonly #injector = inject(Injector);

  readonly currency = input<Currency>();
  readonly currencyPrecision = input<number>();
  readonly canUpdateRecipeCostCenters = input<boolean>();
  readonly taxes = input<TaxRate[]>([]);
  readonly statements = input<RecipeStatement[]>([]);
  readonly defaultSellingPrice = input<number | undefined>(undefined);
  readonly defaultTaxes = input<string[] | undefined>(undefined);

  readonly data = model<CostCenterGridItem[]>([]);

  readonly costCenterChange = output<CostCenterPayload[]>();
  readonly locationChange = output<string>();

  readonly columnDefs = signal<ColDef<CostCenterGridItem>[]>([]);

  @ViewChild(GridPocComponent) private readonly grid: GridPocComponent;

  ngOnInit(): void {
    this.setupColumnDefsEffect();
    this.handleDefaultSellingPriceChanges();
    this.handleDefaultTaxesChanges();
    this.handleStatementsChanges();

    this.initializeDefaultTaxes();
  }

  private initializeDefaultTaxes(): void {
    effect(
      () => {
        const currentData = this.data();
        const defaultTaxesList = this.defaultTaxes();

        if (currentData.length && Array.isArray(defaultTaxesList) && defaultTaxesList.length) {
          currentData.forEach(costCenter => {
            if (!costCenter.taxes || costCenter.taxes.length === 0) {
              this.setTaxes(defaultTaxesList, costCenter);
            }
          });
          this.emitCostCenterChanges();
          this.grid?.api?.refreshCells();
        }
      },
      { injector: this.#injector },
    );
  }

  private setupColumnDefsEffect(): void {
    effect(
      () => {
        if (this.canUpdateRecipeCostCenters()) {
          this.setupColumnDefs();
        }
      },
      { injector: this.#injector, allowSignalWrites: true },
    );
  }

  private handleDefaultSellingPriceChanges(): void {
    effect(
      () => {
        const price = this.defaultSellingPrice();

        if (typeof price === 'number') {
          this.data().forEach(costCenter => this.setSellingPrice(price, costCenter));
          this.grid?.api?.refreshCells();
          this.emitCostCenterChanges();
        }
      },
      { injector: this.#injector },
    );
  }

  private handleDefaultTaxesChanges(): void {
    effect(
      () => {
        const taxes = this.defaultTaxes();

        if (Array.isArray(taxes)) {
          this.data().forEach(costCenter => this.setTaxes(taxes, costCenter));
          this.emitCostCenterChanges();
          this.grid?.api?.refreshCells();
        }
      },
      { injector: this.#injector },
    );
  }

  private handleStatementsChanges(): void {
    effect(
      () => {
        const statements = this.statements();

        if (statements?.length) {
          this.setDefaultCostMany(statements);
        }
      },
      { injector: this.#injector },
    );
  }

  private setSellingPrice(sellingPriceValue: number, item: CostCenterGridItem): void {
    item.sellingPrice = sellingPriceValue;
    this.setComputedTargetCost(item);
  }

  private setTaxes(taxes: string[], item: CostCenterGridItem): void {
    item.taxes = taxes;
    this.setComputedTargetCost(item);
  }

  private emitCostCenterChanges(): void {
    this.costCenterChange.emit(
      this.data()
        .filter(costCenter => costCenter.location)
        .map(costCenter => ({
          location: { id: costCenter.location?.id },
          branch: { id: costCenter.branch.id },
          targetCost: costCenter.targetCost,
          costThreshold: costCenter.costThreshold,
          taxes: costCenter.taxes?.map(id => ({ id })),
          sellingPrice: costCenter.sellingPrice,
        })),
    );
  }

  private onLocationSelected(item: CostCenterGridItem, location: { id: string; name: string } | null): void {
    item.location = location;

    if (location?.id) {
      this.locationChange.emit(location.id);

      this.refreshRowIfPossible(item, true);
    } else {
      this.setDefaultCost({ locationId: null, cost: null, index: this.data().indexOf(item), force: true });
      this.emitCostCenterChanges();

      item.costThreshold = undefined;
    }
  }

  protected setupColumnDefs(): void {
    const canUpdateRecipeCostCenters = this.canUpdateRecipeCostCenters();

    this.columnDefs.set([
      {
        headerName: $localize`:@@grid.headers.branchName:Branch Name`,
        field: 'branch.name',
        flex: 1,
        filter: true,
        floatingFilter: true,
        filterParams: {
          filterOptions: ['contains', 'notContains', 'equals', 'notEqual'],
          caseSensitive: false,
          debounceMs: 200,
        },
      },
      {
        headerName: $localize`:@@grid.headers.costCenter:Cost Center`,
        field: 'location',
        flex: 1,
        headerComponent: SortableHeaderComponent,
        sortable: true,
        comparator: (
          valueA: { id: string; name: string } | null | undefined,
          valueB: { id: string; name: string } | null | undefined,
        ) => {
          if (!valueA?.id && valueB?.id) return -1;

          if (valueA?.id && !valueB?.id) return 1;

          if (!valueA?.id && !valueB?.id) return 0;

          return valueA.name.localeCompare(valueB.name);
        },
        valueFormatter: (params: { data?: CostCenterGridItem }) => {
          return params.data?.location?.name ?? '';
        },
        cellRenderer: MultiSelectCellRendererComponent,
        cellRendererParams: (params: { data?: CostCenterGridItem }) => {
          if (!params.data) return {};

          return {
            context: {
              list: params.data.branch.children ?? [],
              valueKey: 'id',
              titleKey: 'name',
              displayKey: 'name',
              multiple: false,
              placeholder: $localize`:@@costCenter.selectPlaceholder:Select Cost Center`,
              clearable: true,
              onValueChange: (value: DropdownTreeNode) => {
                this.onLocationSelected(params.data, value);
              },
              disabled: () => !canUpdateRecipeCostCenters,
            },
            value: params.data.location?.id ?? null,
          };
        },
        onCellValueChanged: (params: NewValueParams<CostCenterGridItem, string>) => {
          if (params.data && params.newValue) {
            const location = params.data.branch.children?.find(loc => loc.id === params.newValue);

            if (location) {
              this.onLocationSelected(params.data, location);
            }
          } else if (params.data && params.oldValue && !params.newValue) {
            this.onLocationSelected(params.data, null);
          }
        },
      },
      {
        headerName: $localize`:@@grid.headers.recipeCost:Recipe Cost (${this.currency()})`,
        field: 'cost',
        flex: 1,
        valueFormatter: (params: { data?: CostCenterGridItem }) => {
          if (params.data) {
            return (params.data.cost ?? 0).toFixed(this.currencyPrecision());
          }

          return null;
        },
        tooltipValueGetter: (params: { data?: CostCenterGridItem }) => {
          if (params.data) {
            return this.getSalesTypesCostsTooltip(params.data);
          }

          return '';
        },
      },
      {
        headerName: $localize`:@@grid.headers.sellingPrice:Selling Price (${this.currency()})`,
        field: 'sellingPrice',
        flex: 1,
        editable: canUpdateRecipeCostCenters,
        valueGetter: (params: { data?: CostCenterGridItem }) => {
          if (params.data?.location?.id) {
            return params.data.sellingPrice ?? null;
          }

          return null;
        },
        cellEditor: InputCellEditorComponent,
        cellEditorParams: {
          context: {
            numeric: true,
            precision: this.currencyPrecision(),
            suffix: this.currency(),
          },
        },
        onCellValueChanged: (params: NewValueParams<CostCenterGridItem>) => {
          if (params.data) {
            this.setSellingPrice(Number(params.newValue), params.data);
            this.refreshRowIfPossible(params.data);
            this.emitCostCenterChanges();
          }
        },
      },
      {
        headerName: $localize`:@@grid.headers.costThreshold:Food Cost % Threshold`,
        field: 'costThreshold',
        flex: 1,
        editable: canUpdateRecipeCostCenters,
        onCellValueChanged: () => this.emitCostCenterChanges(),
        cellEditor: InputCellEditorComponent,
        cellEditorParams: {
          context: {
            numeric: true,
            precision: this.currencyPrecision(),
            suffix: '%',
          },
        },
      },
      {
        headerName: $localize`:@@grid.headers.targetCost:Target Food Cost (%)`,
        field: 'targetCost',
        flex: 1,
        editable: canUpdateRecipeCostCenters,
        valueGetter: (params: { data?: CostCenterGridItem }) => {
          if (params.data?.location?.id) {
            return params.data.targetCost ?? null;
          }

          return null;
        },
        cellEditor: InputCellEditorComponent,
        cellEditorParams: {
          context: {
            numeric: true,
            precision: this.currencyPrecision(),
            suffix: '%',
          },
        },
        onCellValueChanged: () => this.emitCostCenterChanges(),
      },
      {
        headerName: $localize`:@@grid.headers.taxes:Taxes`,
        field: 'taxes',
        flex: 1,
        editable: canUpdateRecipeCostCenters,
        cellRenderer: (params: ICellRendererParams<CostCenterGridItem>) => {
          if (params.data?.location?.id) {
            return `${params.data.taxes?.length ?? 0} Selected`;
          }

          return '';
        },
        cellEditor: MultiSelectCellEditorComponent,
        cellEditorParams: (params: { data?: CostCenterGridItem }) => {
          if (!params.data) return {};

          return {
            context: {
              list: this.taxes(),
              valueKey: 'id',
              titleKey: 'name',
              displayKey: 'name',
              multiple: true,
              placeholder: 'Select Taxes',
              clearable: true,
            },
            value: params.data.taxes ?? [],
          };
        },
        onCellValueChanged: (params: NewValueParams<CostCenterGridItem, string[]>) => {
          if (params.data) {
            this.setTaxes(params.newValue || [], params.data);
            this.refreshRowIfPossible(params.data);
            this.emitCostCenterChanges();
          }
        },
      },
    ]);
  }

  private setDefaultCost({
    locationId,
    cost,
    index,
    force,
  }: {
    locationId: string | null;
    cost: number | null;
    index?: number;
    force?: boolean;
  }): void {
    this.data().forEach((costCenter, currIndex) => {
      if (locationId === costCenter.location?.id || index === currIndex) {
        costCenter.cost = cost;
        this.setComputedTargetCost(costCenter);
        this.refreshRowIfPossible(costCenter, force);
      }
    });
  }

  private setDefaultCostMany(statements: RecipeStatement[]): void {
    statements.forEach(({ locationId, cost }) => {
      this.setDefaultCost({ locationId, cost });
    });

    this.emitCostCenterChanges();
  }

  private getTaxesAmount(taxes: string[]): number {
    return this.taxes()
      .filter(tax => taxes?.includes(tax.id))
      ?.reduce((sum, tax) => sum + tax.rate, 0);
  }

  private setComputedTargetCost(item: CostCenterGridItem): void {
    if (!item.location && !item.sellingPrice && !item.cost) {
      return;
    }

    if (item.targetCost === undefined || item.targetCost === null || item.targetCost === 0) {
      item.targetCost = computedFoodCost(item.cost, this.getTaxesAmount(item.taxes), item.sellingPrice);
    }
  }

  private refreshRowIfPossible(item: CostCenterGridItem, force?: boolean): void {
    if (this.grid?.api) {
      const rowNode = this.grid.api.getRowNode(this.data().indexOf(item).toString());

      if (rowNode) {
        this.grid.api.refreshCells({ rowNodes: [rowNode], force });
      }
    }
  }

  private getSalesTypesCostsTooltip(rowData: CostCenterGridItem): string {
    if (rowData.salesTypesCosts?.length) {
      return rowData.salesTypesCosts
        .map((cost: SalesTypeCost) => `${cost.salesTypeName}: ${cost.cost.toFixed(this.currencyPrecision())}`)
        .join('\n');
    }

    return $localize`:@@costCenter.noSalesTypeCost:No sales type costs available`;
  }
}
