<div class="supy-recipes-list-legend">
  <div class="supy-recipes-list-legend__item">
    <supy-icon name="sub-recipe" color="warn" size="small" />
    <span i18n="@@inventory.recipe.legend.semiFinished">Semi-Finished Recipe</span>
  </div>
  <div class="supy-recipes-list-legend__item">
    <supy-icon name="food" color="primary" size="small" />
    <span i18n="@@inventory.recipe.legend.finished">Finished Recipe</span>
  </div>
</div>
