import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { IQueryResponse, Query } from '@supy/common';

import { CHANNEL_ITEMS_BFF_URI, CHANNEL_ITEMS_URI } from '../config';
import {
  ChannelItem,
  ChannelItemRequestProps,
  ChannelItemResponse,
  ChannelItemsPerPackaging,
  CreateChannelItemRequest,
  CreateChannelItemsRequest,
  CreateChannelItemsResponse,
  UpdateChannelItemRequest,
  UpdateChannelItemsRequest,
} from '../core';

@Injectable({
  providedIn: 'root',
})
export class ChannelItemsService {
  readonly #httpClient = inject(HttpClient);
  readonly #uri = inject(CHANNEL_ITEMS_URI);
  readonly #uriBff = inject(CHANNEL_ITEMS_BFF_URI);

  getMany(query: Query<ChannelItemRequestProps>): Observable<IQueryResponse<ChannelItemResponse>> {
    return this.#httpClient.get<IQueryResponse<ChannelItemResponse>>(this.#uriBff, { params: query.toQueryParams() });
  }

  getManyFillable(query: Query<ChannelItemRequestProps>): Observable<IQueryResponse<ChannelItemResponse>> {
    return this.#httpClient.get<IQueryResponse<ChannelItemResponse>>(`${this.#uriBff}/fillable`, {
      params: query.toQueryParams(),
    });
  }

  getManyGroupedByPackaging(
    query: Query<ChannelItemRequestProps>,
  ): Observable<IQueryResponse<ChannelItemsPerPackaging>> {
    return this.#httpClient.get<IQueryResponse<ChannelItemsPerPackaging>>(`${this.#uriBff}/grouped/packaging`, {
      params: query.toQueryParams(),
    });
  }

  create(body: CreateChannelItemRequest): Observable<ChannelItem> {
    return this.#httpClient.post<ChannelItem>(this.#uri, body);
  }

  createMany(body: CreateChannelItemsRequest): Observable<CreateChannelItemsResponse> {
    return this.#httpClient.post<CreateChannelItemsResponse>(`${this.#uri}/batch`, body);
  }

  update(body: UpdateChannelItemRequest, itemId: string): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uri}/${itemId}`, body);
  }

  updateMany(body: UpdateChannelItemsRequest): Observable<void> {
    return this.#httpClient.patch<void>(`${this.#uri}/batch`, body);
  }

  deleteMany(ids: string[]): Observable<void> {
    return this.#httpClient.delete<void>(`${this.#uriBff}/batch`, { body: { channelItemsIds: ids } });
  }
}
