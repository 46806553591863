import {
  InventoryStockCountTemplateCreate,
  InventoryStockCountTemplatesFilters,
  InventoryStockCountTemplatesRequestMetadata,
  InventoryStockCountTemplateUpdate,
  StockCountTemplateClone,
} from '../state';

export class InventoryStockCountTemplatesGetOne {
  static readonly type = '[InventoryStockCountTemplates] GetOne';
  constructor(readonly id: string) {}
}

export class ResetStockCountTemplateDetailsState {
  static readonly type = '[InventoryStockCountTemplates] ResetStockCountTemplateState';
}

export class InventoryStockCountTemplatesResetSelected {
  static readonly type = '[InventoryStockCountTemplates] InventoryStockCountResetSelected';
}

export class InventoryStockCountClearSelectedTemplates {
  static readonly type = '[InventoryStockCountTemplates] InventoryStockCountClearSelectedTemplates';
}

export class InventoryStockCountTemplatesInitFilters {
  static readonly type = '[InventoryStockCountTemplates] InitFilters';
}

export class InventoryStockCountTemplatesPatchFilter {
  static readonly type = '[InventoryStockCountTemplates] PatchFilter';
  constructor(readonly payload: Partial<InventoryStockCountTemplatesFilters>) {}
}

export class InventoryStockCountTemplatesResetFilter {
  static readonly type = '[InventoryStockCountTemplates] ResetFilter';
}

export class InventoryStockCountTemplatesGetMany {
  static readonly type = '[InventoryStockCountTemplates] GetMany';
}

export class InventoryStockCountTemplatesClone {
  static readonly type = '[InventoryStockCountTemplates] Clone';
  constructor(readonly payload: { id: string; values: StockCountTemplateClone }) {}
}

export class InventoryStockCountTemplatesDelete {
  static readonly type = '[InventoryStockCountTemplates] Delete';
  constructor(readonly payload: { ids: string[] }) {}
}

export class InventoryStockCountTemplatesCreate {
  static readonly type = '[InventoryStockCountTemplates] Create';
  constructor(readonly payload: InventoryStockCountTemplateCreate) {}
}

export class InventoryStockCountTemplatesUpdate {
  static readonly type = '[InventoryStockCountTemplates] Update';
  constructor(readonly payload: { id: string; values: InventoryStockCountTemplateUpdate }) {}
}

export class InventoryStockCountTemplatesPatchRequestMetadata {
  static readonly type = '[InventoryStockCountTemplates] PatchRequestMeta';
  constructor(public payload: Partial<InventoryStockCountTemplatesRequestMetadata>) {}
}
