import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { INVENTORY_STOCK_COUNT_BFF_URI } from '../../../config';
import { InventoryVarianceReportExport } from '../core';

@Injectable({ providedIn: 'root' })
export class InventoryVarianceReportService {
  private readonly httpClient = inject(HttpClient);
  private readonly bffUri = inject<string>(INVENTORY_STOCK_COUNT_BFF_URI);

  getReport(stockCountId: string): Observable<InventoryVarianceReportExport> {
    return this.httpClient.get<InventoryVarianceReportExport>(`${this.bffUri}/${stockCountId}/variance-report/export`);
  }
}
