import produce from 'immer';
import { tap } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, createSelector, Selector, State, StateContext, StateToken } from '@ngxs/store';

import {
  BaseRequestMetadata,
  BaseResponseMetadata,
  EntityListDateRangeFilter,
  EntityListState,
  EntityListStateModel,
  Query,
  QueryBuilder,
} from '@supy/common';

import { InventoryItemsService } from '../../../../services';
import { PriceHistoryRecord, PriceHistoryRequestProps } from '../../core';
import {
  PriceHistoryGetMany,
  PriceHistoryInitFilters,
  PriceHistoryPatchFilter,
  PriceHistoryPatchRequestMeta,
  PriceHistoryResetFilter,
} from '../actions';

export interface PriceHistoryStateModel extends EntityListStateModel<PriceHistoryRecord> {
  readonly filters: PriceHistoryFilters;
  readonly requestMetadata: PriceHistoryRequestMetadata;
  readonly responseMetadata: PriceHistoryResponseMetadata;
}

export interface PriceHistoryFilters extends EntityListDateRangeFilter {
  readonly supplierId: string | null;
}

export interface PriceHistoryMappedFilters {
  readonly supplierId: string;
  readonly dateRange: { readonly start: Date; readonly end: Date };
}

export interface PriceHistoryRequestMetadata extends BaseRequestMetadata {
  readonly retailerId: string | null;
  readonly locationId: string | null;
  readonly retailerItemId: string | null;
}

export interface PriceHistoryResponseMetadata extends BaseResponseMetadata {}

const PRICE_HISTORY_STATE_TOKEN = new StateToken<PriceHistoryStateModel[]>('priceHistory');

const FILTERS_DEFAULT: PriceHistoryFilters = {
  supplierId: null,
  start: null,
  end: null,
};

const REQUEST_META_DEFAULT: PriceHistoryRequestMetadata = {
  page: 0,
  limit: 25,
  retailerId: null,
  locationId: null,
  retailerItemId: null,
};
const RESPONSE_META_DEFAULT: PriceHistoryResponseMetadata = { total: 0, count: 0 };

@State<PriceHistoryStateModel>({
  name: PRICE_HISTORY_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    filters: FILTERS_DEFAULT,
    requestMetadata: REQUEST_META_DEFAULT,
    responseMetadata: RESPONSE_META_DEFAULT,
  },
})
@Injectable()
export class PriceHistoryState extends EntityListState {
  protected readonly router = inject(Router);
  private readonly inventoryItemService = inject(InventoryItemsService);

  @Selector()
  static items(state: PriceHistoryStateModel) {
    return EntityListState.all(state);
  }

  @Selector()
  static currentItem(state: PriceHistoryStateModel) {
    return EntityListState.current(state);
  }

  static item(id: string, next?: boolean) {
    return createSelector([PriceHistoryState], (state: PriceHistoryStateModel) => {
      return EntityListState.one(id, next)(state);
    });
  }

  @Selector()
  static appliedFiltersCount(state: PriceHistoryStateModel) {
    return EntityListState.appliedFiltersCount(state, FILTERS_DEFAULT);
  }

  @Selector()
  static filters(state: PriceHistoryStateModel) {
    return EntityListState.currentFilters<PriceHistoryFilters>(state);
  }

  @Selector()
  static requestMetadata(state: PriceHistoryStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: PriceHistoryStateModel) {
    return state.responseMetadata;
  }

  @Selector()
  static isFirst(state: PriceHistoryStateModel) {
    return EntityListState.isFirst(state);
  }

  @Selector()
  static isLast(state: PriceHistoryStateModel) {
    return EntityListState.isLast(state);
  }

  @Action(PriceHistoryInitFilters)
  inventoryStocksInitFilter(ctx: StateContext<PriceHistoryStateModel>) {
    super.initFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(PriceHistoryPatchFilter)
  patchPriceHistoryFilter(ctx: StateContext<PriceHistoryStateModel>, action: PriceHistoryPatchFilter) {
    super.patchFilter(ctx, action.payload, FILTERS_DEFAULT);
  }

  @Action(PriceHistoryResetFilter)
  resetPriceHistoryFilter(ctx: StateContext<PriceHistoryStateModel>) {
    super.resetFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(PriceHistoryGetMany, { cancelUncompleted: true })
  getPriceHistory(ctx: StateContext<PriceHistoryStateModel>) {
    return this.inventoryItemService.getPriceHistory(this.getQuery(ctx.getState())).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(ctx, PriceHistoryRecord.deserializeList(data));
      }),
    );
  }

  @Action(PriceHistoryPatchRequestMeta)
  patchRequestMetadata(ctx: StateContext<PriceHistoryStateModel>, { payload }: PriceHistoryPatchRequestMeta) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  private getQuery(state: PriceHistoryStateModel): Query<PriceHistoryRequestProps> {
    const { supplierId, start, end } = state.filters;

    const qb = new QueryBuilder<PriceHistoryRequestProps>({
      filtering: [
        {
          by: 'retailerItem.id',
          match: state.requestMetadata.retailerItemId,
          op: 'eq',
        },
        {
          by: 'location.id',
          match: state.requestMetadata.locationId,
          op: 'eq',
        },
      ],
      paging: { offset: state.requestMetadata.page * state.requestMetadata.limit, limit: state.requestMetadata.limit },
      ordering: [],
    });

    if (supplierId) {
      qb.filtering.setFilter({
        by: 'supplier.id',
        match: supplierId,
        op: 'eq',
      });
    }

    if (start && end) {
      qb.filtering.withFiltering([
        { by: 'fromDate', op: 'eq', match: start },
        { by: 'toDate', op: 'eq', match: end },
      ]);
    }

    return qb.build();
  }
}
