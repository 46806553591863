import { DateRange } from '@infragistics/igniteui-angular';

import { FilterConfig, SelectType } from '@supy/components';

export interface CostAdjustmentsGridMappedFilters {
  readonly dateRange: DateRange;
  readonly retailerId: string;
  readonly locationId: string;
  readonly salesTypeId: string;
}

export function getCostAdjustmentsGridFiltersConfig(): FilterConfig {
  return {
    dropdowns: [
      {
        canSelectSingleDate: true,
        name: 'dateRange',
        placeholder: $localize`:@@common.date:Date`,
        selectType: SelectType.DateRange,
        width: '14rem',
      },
    ],
  };
}
