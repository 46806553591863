import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { IQueryResponse, Query } from '@supy/common';

import {
  BaseItem,
  BaseItemRecipe,
  BaseItemRequestProps,
  BaseItemsMergeRequest,
  BaseItemSupplierApi,
  BulkUpdateBaseItemsAccountingCategoryRequest,
  BulkUpdateBaseItemsCategoryRequest,
  BulkUpdateBaseItemsLocationRequest,
  BulkUpdateBaseItemsStateRequest,
  CheckBulkBaseItemRecipesOutput,
  CheckBulkBaseItemRecipesRequest,
  CkPriceListsForAllItemLocationsResponse,
  CreateBaseItemRequest,
  CreateItemPackageRequest,
  ItemCountingPackagingHideRequest,
  ItemCountingPackagingSetDefaultRequest,
  ListingBaseItemOutput,
  UpdateBaseItemRequest,
} from '../../../core';
import { InventoryRecipeRequestProps } from '../../recipe';
import {
  CountingPackagingTab,
  SetInventoryCountingPackagingsRequest,
  SetLocationCountingPackagingsRequest,
} from '../../stocks';
import { BASE_ITEMS_BFF_URI, PACKAGING_BFF_URI } from '../config';

@Injectable({ providedIn: 'root' })
export class BaseItemsService {
  private readonly httpClient = inject(HttpClient);
  private readonly bffUri = inject<string>(BASE_ITEMS_BFF_URI);
  private readonly packagingBffUri = inject<string>(PACKAGING_BFF_URI);

  getByIdBff(id: string): Observable<BaseItem> {
    return this.httpClient.get<BaseItem>(`${this.bffUri}/${id}`);
  }

  getMany(query: Query<BaseItem & BaseItemRequestProps>): Observable<IQueryResponse<ListingBaseItemOutput>> {
    return this.httpClient.get<IQueryResponse<ListingBaseItemOutput>>(this.bffUri, {
      params: new HttpParams({
        fromObject: query.toQueryParams(),
      }),
    });
  }

  getManyPost(query: Query<BaseItem & BaseItemRequestProps>): Observable<IQueryResponse<ListingBaseItemOutput>> {
    return this.httpClient.post<IQueryResponse<ListingBaseItemOutput>>(`${this.bffUri}/get`, {
      query,
    });
  }

  getPackages(id: string): Observable<IQueryResponse<BaseItemSupplierApi>> {
    return this.httpClient.get<IQueryResponse<BaseItemSupplierApi>>(`${this.bffUri}/${id}/packages`);
  }

  createItemPackage(body: CreateItemPackageRequest): Observable<void> {
    return this.httpClient.post<void>(`${this.packagingBffUri}/suppliers`, body);
  }

  create(body: CreateBaseItemRequest): Observable<BaseItem> {
    return this.httpClient.post<BaseItem>(this.bffUri, body);
  }

  update(id: string, body: UpdateBaseItemRequest): Observable<BaseItem> {
    return this.httpClient.patch<BaseItem>(`${this.bffUri}/${id}`, body);
  }

  merge(body: BaseItemsMergeRequest): Observable<void> {
    return this.httpClient.post<void>(`${this.bffUri}/merge`, body);
  }

  bulkUpdateCategory(body: BulkUpdateBaseItemsCategoryRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/bulk/category`, body);
  }

  bulkUpdateAccountingCategory(body: BulkUpdateBaseItemsAccountingCategoryRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/bulk/accounting-category`, body);
  }

  bulkUpdateState(body: BulkUpdateBaseItemsStateRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/bulk/state`, body);
  }

  bulkAddLocations(body: BulkUpdateBaseItemsLocationRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/bulk/locations/add`, body);
  }

  bulkReplaceLocations(body: BulkUpdateBaseItemsLocationRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/bulk/locations/replace`, body);
  }

  unHideAllPackagings(retailerItemId: string, locationId: string): Observable<void> {
    return this.httpClient.patch<void>(
      `${this.bffUri}/${retailerItemId}/locations/${locationId}/counting-packagings/unhide`,
      null,
    );
  }

  hideSinglePackaging({ retailerItemId, locationId, packaging }: ItemCountingPackagingHideRequest): Observable<void> {
    return this.httpClient.patch<void>(
      `${this.bffUri}/${retailerItemId}/locations/${locationId}/counting-packagings/hide`,
      { packaging },
    );
  }

  setDefaultPackaging({
    retailerItemId,
    locationId,
    defaultPackaging,
  }: ItemCountingPackagingSetDefaultRequest): Observable<void> {
    return this.httpClient.patch<void>(
      `${this.bffUri}/${retailerItemId}/locations/${locationId}/counting-packagings/default`,
      { defaultPackaging },
    );
  }

  getRecipes(id: string, query: Query<InventoryRecipeRequestProps>): Observable<IQueryResponse<BaseItemRecipe>> {
    return this.httpClient.get<IQueryResponse<BaseItemRecipe>>(`${this.bffUri}/${id}/recipes`, {
      params: query.toQueryParams(),
    });
  }

  getCkPriceListsForAllItemLocations(
    id: string,
    ckId: string,
    locationId?: string,
  ): Observable<IQueryResponse<CkPriceListsForAllItemLocationsResponse>> {
    let params = new HttpParams();

    if (locationId) {
      params = params.set('locationId', locationId);
    }

    return this.httpClient.get<IQueryResponse<CkPriceListsForAllItemLocationsResponse>>(
      `${this.bffUri}/${id}/has-ck-price-list/${ckId}`,
      { params },
    );
  }

  getBulkRecipes(body: CheckBulkBaseItemRecipesRequest): Observable<IQueryResponse<CheckBulkBaseItemRecipesOutput>> {
    return this.httpClient.post<IQueryResponse<CheckBulkBaseItemRecipesOutput>>(`${this.bffUri}/recipes`, body);
  }

  bulkUpdateTaxable(payload: { taxCode?: string; query: Query<BaseItem & BaseItemRequestProps> }): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/bulk/taxable`, payload);
  }

  getStockCount(retailerId: string, locationId: string): Observable<{ data: CountingPackagingTab[] }> {
    return this.httpClient.get<{ data: CountingPackagingTab[] }>(
      `${this.bffUri}/${retailerId}/locations/${locationId}/counting-packagings`,
    );
  }

  updateTabStockCount(
    body: SetLocationCountingPackagingsRequest,
    retailerItemId: string,
    locationId: string,
  ): Observable<void> {
    return this.httpClient.patch<void>(
      `${this.bffUri}/${retailerItemId}/locations/${locationId}/counting-packagings`,
      body,
    );
  }

  updateTabStockCountInventory(body: SetInventoryCountingPackagingsRequest, retailerItemId: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.bffUri}/${retailerItemId}/counting-packagings`, body);
  }

  getChannelItemsBff(query: Query<BaseItem & BaseItemRequestProps>): Observable<IQueryResponse<BaseItem>> {
    return this.httpClient.get<IQueryResponse<BaseItem>>(this.bffUri, { params: query.toQueryParams() });
  }
}
