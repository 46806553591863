import { inject, InjectionToken } from '@angular/core';

import { EndpointConfig } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

export const BASE_ITEMS_BFF_URI = new InjectionToken<string>('Base Items BFF URI', {
  factory: () => {
    const config = inject<EndpointConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/base-items`;
  },
});

export const PACKAGING_BFF_URI = new InjectionToken<string>('Packaging BFF URI', {
  factory: () => {
    const config = inject<EndpointConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/item-packages`;
  },
});
