<form class="supy-recipe-cookbook" [formGroup]="form">
  <div class="supy-recipe-cookbook__main">
    <article class="supy-recipe-cookbook__section">
      <supy-checkbox formControlName="hidden">
        <span i18n="@@inventory.recipe.cookBook.hideThisRecipe"
          >Hide this recipe from Cook Book in the mobile application</span
        >
      </supy-checkbox>
    </article>

    <article class="supy-recipe-cookbook__section">
      <header class="supy-recipe-cookbook__header" i18n="@@inventory.recipe.cookBook.cookingDetails">
        Cooking Details
      </header>

      <div class="supy-recipe-cookbook__form">
        <div class="supy-recipe-cookbook__control">
          <span class="supy-recipe-cookbook__label" i18n="@@inventory.recipe.cookBook.cookTime"
            >Cook Time (Minutes)</span
          >
          <supy-input type="number" placeholder="Cook Time" name="cook-time" formControlName="cookTimeInMinutes" />
        </div>
        <div class="supy-recipe-cookbook__control">
          <span class="supy-recipe-cookbook__label" i18n="@@inventory.recipe.cookBook.servingPersons"
            >Serving (Persons)</span
          >
          <supy-input type="number" placeholder="Serving" name="servingPersons" formControlName="servingPersons" />
        </div>
        <div class="supy-recipe-cookbook__control">
          <span class="supy-recipe-cookbook__label" i18n="@@inventory.recipe.cookBook.difficulty"
            >Recipe Difficulty</span
          >
          <supy-star-rating formControlName="difficulty" />
        </div>
      </div>
    </article>

    <article class="supy-recipe-cookbook__section">
      <header class="supy-recipe-cookbook__header" i18n="@@preparation">Preparation</header>

      <supy-grid
        #grid
        primaryKey="id"
        class="supy-recipe-cookbook__steps-grid"
        [rowDraggable]="!isReadonly"
        [data]="cookBookSteps"
        [emptyGridTemplate]="emptyGridTemplate"
        [addedInFooter]="!isReadonly"
        i18n-addedInFooterTitle="@@inventory.recipe.cookBook.clickToAdd"
        addedInFooterTitle="Click to add preparation step"
        (rowAdding)="onAddStep()"
        (cellClick)="$event.cell.editMode = true"
        (cellEditDone)="syncForm()"
      >
        <ng-template #emptyGridTemplate />
        <supy-grid-column header="Step #" i18n-header="@@grid.headers.stepNum.label" width="10%" [editable]="false">
          <ng-template supyGridCell let-cell="cell">
            <span>{{ cell.row.index + 1 }}</span>
          </ng-template>
        </supy-grid-column>
        <supy-grid-column
          field="description"
          header="Description"
          i18n-header="@@common.description"
          width="75%"
          [editable]="!isReadonly"
        >
          <ng-template supyGridCellEditor let-cell="cell">
            <supy-input
              style="width: 100%"
              [noShadow]="true"
              [focusOnInit]="true"
              [(value)]="cell.editValue"
              (focusOut)="grid.endEdit()"
            />
          </ng-template>
        </supy-grid-column>
        <supy-grid-column
          *ngIf="!isReadonly"
          field="id"
          header="Actions"
          i18n-header="@@actions"
          width="10%"
          [editable]="false"
        >
          <ng-template supyGridCell let-value="value">
            <supy-button type="icon" color="default" name="delete" (buttonClick)="onRemoveStep(value)">
              <supy-icon name="delete" color="error" />
            </supy-button>
          </ng-template>
        </supy-grid-column>
      </supy-grid>
    </article>

    <article class="supy-recipe-cookbook__section">
      <header class="supy-recipe-cookbook__header" i18n="@@inventory.recipe.cookBook.serving">Serving</header>
      <p i18n="@@inventory.recipe.cookBook.instructions">Instructions</p>
      <supy-textarea
        name="instructions"
        formControlName="instructions"
        i18n-placeholder="@@inventory.recipe.cookBook.serving"
        placeholder="Serving"
        [rows]="5"
      />
    </article>
  </div>
</form>
