import { IdType, InvoiceUnit, LocalizedData, PreferredType, SkeletonObjectType } from '@supy/common';
import { Packaging } from '@supy/packaging';

import { ChannelItem } from './channel-item.entity';

export enum ChannelItemState {
  Available = 'available',
  Deleted = 'deleted',
  Hidden = 'hidden',
}

export interface ChannelItemResponse {
  readonly branch: IdType;
  readonly category?: SkeletonObjectType;
  readonly channel: IdType;
  readonly ckStock?: number;
  readonly createdAt: Date;
  readonly id: string;
  readonly inventoryItem?: IdType;
  readonly itemCode?: string;
  readonly name: LocalizedData;
  readonly orderable?: boolean;
  readonly packaging?: Packaging;
  readonly preferredType?: PreferredType;
  readonly price: number;
  readonly priority?: boolean;
  readonly quantity?: number;
  readonly quantityLevels?: ChannelItemQuantity;
  readonly retailer: IdType;
  readonly retailerItem: ChannelItemRetailerItem;
  readonly state: ChannelItemState;
  readonly supplier: IdType;
  readonly supplierItem?: ChannelItemSupplierItem;
  readonly totalPrice?: number;
  readonly updatedAt: Date;
}

export interface UpdateChannelItemRequest {
  readonly id?: string;
  readonly name?: LocalizedData;
  readonly price?: number;
  readonly unit?: string;
  readonly state?: ChannelItemState;
  readonly itemCode?: string;
  readonly preferredType?: PreferredType;
  readonly retailerItemId?: string;
  readonly channelId?: string;
}

export interface CreateChannelItemRequest {
  readonly name?: LocalizedData;
  readonly price?: number;
  readonly description?: string;
  readonly unit?: string;
  readonly itemCode?: string;
  readonly retailerItemId: string;
  readonly supplierId?: string;
  readonly retailerId?: string;
  readonly branchId?: string;
  readonly channelId: string;
  readonly preferredType?: PreferredType;
}

export interface CreateChannelItemsRequest {
  readonly channelItems: CreateChannelItemRequest[];
  readonly replace?: boolean;
}

export interface UpdateRelatedChannelItemsRequest {
  readonly item: UpdateChannelItemRequest;
  readonly branches?: string[];
}

export interface UpdateChannelItemsRequest {
  readonly channelItems: UpdateChannelItemRequest[];
}

export interface CreateChannelItemsResponse {
  readonly channelItems: ChannelItem[];
}

export interface DeleteChannelItemsRequest {
  readonly channelItemIds: string[];
}

export interface CheckPreferredSiblingsRequest {
  readonly supplierId: string;
  readonly retailerId: string;
  readonly retailerItemIds: string[];
}

export interface ChannelItemRetailerItem {
  readonly id: string;
  readonly code: string;
  readonly isLocked?: boolean;
  readonly taxCode?: string;
}

export interface ChannelItemQuantity {
  readonly stock: number;
  readonly par: number;
}

export interface ChannelItemSupplierItem {
  readonly code: string;
  readonly id: string;
  readonly invoiceUnit: InvoiceUnit;
  readonly name: LocalizedData;
}

export type SupplierChannelItem = Omit<ChannelItemResponse, 'supplier'> & { readonly supplier: SkeletonObjectType };

export interface ChannelItemsPerPackaging {
  readonly packaging: Packaging;
  readonly channelItems: SupplierChannelItem[];
  readonly selectedChannelItem: SupplierChannelItem;
}

export interface ChannelItemRequestProps {
  readonly 'branch.id': string;
  readonly 'category.id': string;
  readonly 'channel.id': string;
  readonly 'inventoryItem.id': string;
  readonly 'name.ar': string;
  readonly 'name.en': string;
  readonly 'packaging.id': string;
  readonly 'retailer.id': string;
  readonly 'retailerItem.id': string;
  readonly 'supplier.id': string;
  readonly 'supplierItem.id': string;
  readonly 'uom.name': string;
  readonly branchId: string;
  readonly channelId: string;
  readonly ckStock: number;
  readonly createdAt: string;
  readonly id: string;
  readonly itemCode: string;
  readonly name: string;
  readonly preferredType: string;
  readonly price: string;
  readonly retailerId: string;
  readonly retailerItemId: string;
  readonly state: string;
  readonly supplierId: string;
  readonly updatedAt: Date;
}
