<form
  [formGroup]="form"
  class="supy-create-recipe__price-simulator"
  [class.vague]="recipeTypeValue === inventoryRecipeType.SemiFinished"
>
  <div class="supy-create-recipe__price-simulator-column">
    <div class="supy-create-recipe__price-simulator-row">
      <div class="supy-create-recipe__price-simulator-row-title" i18n="@@inventory.recipe.costing.targetCost">
        Target Cost
      </div>

      <supy-input type="number" formControlName="targetCost" name="targetCost" addonText="%">
        <supy-input-error
          *ngIf="!form.get('targetCost')?.valid && (formSubmitted || form.get('targetCost')?.touched)"
          i18n="@@inventory.recipe.costing.fieldRequired"
          >Field is required</supy-input-error
        >
      </supy-input>
    </div>
  </div>

  <div class="supy-create-recipe__price-simulator-column">
    <div class="supy-create-recipe__price-simulator-row">
      <div class="supy-create-recipe__price-simulator-row-title" i18n="@@inventory.recipe.costing.basedOn">
        Based On
      </div>

      <supy-button-group formControlName="basedOn" [buttons]="types" (valueChange)="(null)" />
    </div>
  </div>

  <div class="supy-create-recipe__price-simulator-column">
    <div class="supy-create-recipe__price-simulator-row">
      <div></div>
      <div class="supy-create-recipe__price-simulator-row-price">
        {{ currency }}
      </div>
    </div>

    <div class="supy-create-recipe__price-simulator-row">
      <div
        class="supy-create-recipe__price-simulator-row-title"
        i18n="@@inventory.recipe.costing.projectedSellingPrice"
      >
        {{ basedOn === basedOnEnum.SellingPrice ? 'Expected ' : '' }} Selling Price
      </div>
      <div class="supy-create-recipe__price-simulator-row-price">
        {{
          (basedOn === basedOnEnum.SellingPrice ? computedExpectedSellingPrice : sellingPriceValue)
            | supyPrecision: currencyPrecision
        }}
      </div>
    </div>

    <div class="supy-create-recipe__price-simulator-row divider">
      <div class="supy-create-recipe__price-simulator-row-title" i18n="@@inventory.recipe.costing.projectedFoodCost">
        {{ basedOn === basedOnEnum.FoodCost ? 'Expected ' : '' }} Food Cost
      </div>
      <div class="supy-create-recipe__price-simulator-row-price">
        {{
          (basedOn === basedOnEnum.FoodCost ? computedExpectedFoodCost : computedFoodCost)
            | supyPrecision: currencyPrecision
        }}
      </div>
    </div>

    <div class="supy-create-recipe__price-simulator-row">
      <small class="supy-create-recipe__price-simulator-row-title" i18n="@@inventory.recipe.costing.expectedProfit"
        >Expected Profit</small
      >
      <div class="supy-create-recipe__price-simulator-row-price">
        {{
          (basedOn === basedOnEnum.FoodCost ? computedExpectedFoodCostProfit : computedExpectedSellingProfit)
            | supyPrecision: currencyPrecision
        }}
      </div>
    </div>
  </div>
</form>
