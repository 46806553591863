<div class="supy-create-recipe-footer">
  <ng-container *ngIf="editMode; else createActions">
    <supy-button
      color="primary"
      name="create-recipe"
      [disabled]="isSaveLoading || disableActions || !canSubmit()"
      (buttonClick)="save.emit()"
    >
      <supy-loader *ngIf="isSaveLoading" />
      <span class="supy-create-recipe-footer__label">{{ saveTitle }}</span>
    </supy-button>
  </ng-container>

  <ng-template #createActions>
    <supy-button
      *ngIf="createDraftButton"
      variant="link"
      color="default"
      name="save-draft"
      [disabled]="disableActions || !canSubmit()"
      (buttonClick)="createDraft.emit()"
    >
      <supy-icon name="draft" color="primary" />
      <span class="supy-create-recipe-footer__label supy-create-recipe-footer__label--primary">Save as Draft</span>
    </supy-button>
    <supy-button
      *ngIf="directToText"
      name="save-and-redirect"
      color="primary"
      (buttonClick)="createAndRedirect.emit()"
      [disabled]="disableActions || !canSubmit()"
    >
      <supy-icon name="draft" color="secondary" />
      <span class="supy-create-recipe-footer__label">Save and go to {{ directToText }}</span>
    </supy-button>
  </ng-template>

  <supy-button
    *ngIf="nextMode"
    color="primary"
    name="create"
    (buttonClick)="next.emit()"
    [disabled]="disableActions || !canSubmit()"
  >
    <supy-icon name="arrow-right" color="secondary" />
    <span class="supy-create-item-footer__label">Next</span>
  </supy-button>
</div>
