import {
  CellClickedEvent,
  ColDef,
  EditableCallbackParams,
  GridOptions,
  ICellEditorParams,
  ICellRendererParams,
  IRowNode,
  NewValueParams,
  RowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  Injector,
  input,
  model,
  OnInit,
  output,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import { Currency } from '@supy.api/dictionaries';

import {
  ActionsCellRendererComponent,
  DropdownTreeNode,
  GridPocComponent,
  InputCellEditorComponent,
  MultiSelectCellEditorComponent,
  MultiSelectCellRendererComponent,
} from '@supy/components';
import { Writable } from '@supy/core';
import { TaxRate } from '@supy/settings';

import {
  CostCenterGridItem,
  CostCenterLocationGridItem,
  CostCenterPayload,
  MultipleCostCenterGridItem,
} from '../../core';

interface BranchType {
  readonly id: string;
  readonly name: string;
  readonly children?: LocationType[];
}

interface LocationType {
  readonly id: string;
  readonly name: string;
}

interface FlatCostCenterGridRow {
  readonly id: string;
  readonly parentId?: string;
  readonly isParent: boolean;
  readonly isExpanded?: boolean;
  readonly name: string;
  readonly branch?: BranchType;
  readonly location?: LocationType;
  readonly sellingPrice?: number;
  readonly taxes?: string[];
  readonly revenuePercentage?: number;
  readonly locationIndex?: number;
}

@Component({
  selector: 'supy-cost-centers-multiple-grid',
  templateUrl: 'cost-centers-multiple-grid.component.html',
  styleUrls: ['./cost-centers-multiple-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CostCentersMultipleGridComponent implements OnInit {
  readonly #injector = inject(Injector);

  private readonly grid = viewChild(GridPocComponent);

  readonly currency = input<Currency>();
  readonly currencyPrecision = input<number>();
  readonly taxes = input<TaxRate[]>([]);
  readonly defaultSellingPrice = input<number | null>(null);
  readonly defaultTaxes = input<string[] | null>(null);

  readonly data = model<CostCenterGridItem[]>([]);
  readonly costCenterChange = output<CostCenterPayload[]>();

  readonly columnDefs = signal<ColDef[]>([]);
  readonly multipleCostCentersData = signal<MultipleCostCenterGridItem[]>([]);
  readonly flattenedGridData = signal<FlatCostCenterGridRow[]>([]);

  protected readonly gridOptions: GridOptions = {
    doesExternalFilterPass: (node: RowNode<FlatCostCenterGridRow>) => this.filterRow(node.data),
    isExternalFilterPresent: () => true,
    enableCellTextSelection: true,
    ensureDomOrder: true,
    alwaysMultiSort: true,
  };

  ngOnInit(): void {
    this.initializeData();
    this.handleDefaultSellingPriceChanges();
    this.handleDefaultTaxesChanges();
  }

  setupColumnDefs(): void {
    this.columnDefs.set([
      {
        headerName: '',
        field: 'isExpanded',
        flex: 0.2,
        cellRenderer: ({ data }: ICellRendererParams<FlatCostCenterGridRow>) => {
          if (!data?.isParent) {
            return '';
          }

          return data.isExpanded
            ? '<span class="ag-icon ag-icon-tree-open" style="cursor:pointer;"></span>'
            : '<span class="ag-icon ag-icon-tree-closed" style="cursor:pointer;"></span>';
        },
        onCellClicked: ({ data }: CellClickedEvent<FlatCostCenterGridRow>) => {
          if (data?.isParent) {
            this.toggleRowExpansion(data.id);
          }
        },
      },
      {
        headerName: $localize`:@@grid.headers.name:Name`,
        field: 'name',
        flex: 2,
        valueGetter: (params: ValueGetterParams<FlatCostCenterGridRow>) => {
          if (params.data?.isParent) {
            return params.data.branch?.name ?? '';
          }

          return params.data?.location?.name ?? '';
        },
        cellRendererSelector: (params: ICellRendererParams<FlatCostCenterGridRow>) => {
          if (params.data?.isParent) {
            return { component: () => `<strong>${params.value as string}</strong>`, params: {} };
          }

          const branch = this.multipleCostCentersData().find(item => item.id === params.data.parentId)?.branch;

          return {
            component: MultiSelectCellRendererComponent,
            params: {
              context: {
                list: branch?.children ?? [],
                valueKey: 'id',
                titleKey: 'name',
                displayKey: 'name',
                multiple: false,
                placeholder: $localize`:@@costCenter.selectPlaceholder:Select Cost Center`,
                clearable: true,
                onValueChange: (value: DropdownTreeNode) => {
                  const branch = this.multipleCostCentersData().find(item => item.id === params.data.parentId)?.branch;

                  if (branch) {
                    this.onLocationSelect(value, params.data);
                  }
                },
                disabled: () => params.data?.isParent,
              },
              value: params.data.location?.id ?? null,
            },
          };
        },
        onCellValueChanged: ({ data }: NewValueParams<FlatCostCenterGridRow>) => {
          if (data && !data.isParent) {
            const branch = this.multipleCostCentersData().find(item => item.id === data.parentId)?.branch;

            if (branch) {
              const location = branch.children?.find(loc => loc.id === data.name);

              this.onLocationSelect(location as LocationType, data);
            }
          }
        },
      },
      {
        headerName: $localize`:@@grid.headers.revenuePercentage:Revenue Percentage (%)`,
        field: 'revenuePercentage',
        flex: 1,
        editable: ({ data }: EditableCallbackParams<FlatCostCenterGridRow>) => !data?.isParent,
        cellEditor: InputCellEditorComponent,
        cellEditorParams: {
          context: {
            numeric: true,
            precision: 3,
            suffix: '%',
          },
        },
        onCellValueChanged: ({ data, newValue }: NewValueParams<FlatCostCenterGridRow, number>) => {
          if (data && !data.isParent && data.parentId && data.locationIndex !== undefined) {
            this.updateLocationRevenuePercentage(data.parentId, data.locationIndex, newValue);
            this.onCellEditDone();
          }
        },
        valueFormatter: ({ data, value }: ValueFormatterParams<FlatCostCenterGridRow, number>) => {
          if (data && !data.isParent && value) {
            return value.toString();
          }

          return '';
        },
      },
      {
        headerName: $localize`:@@grid.headers.costCenters:Cost Centers`,
        flex: 1,
        valueGetter: (params: ValueGetterParams<FlatCostCenterGridRow>) => {
          if (params.data?.isParent) {
            return this.getLocationsLength(this.multipleCostCentersData().find(item => item.id === params.data.id));
          }

          return '';
        },
      },
      {
        headerName: $localize`:@@grid.headers.sellingPrice:Selling Price (${this.currency()})`,
        field: 'sellingPrice',
        flex: 1,
        editable: ({ data }: EditableCallbackParams<FlatCostCenterGridRow>) => !!data?.isParent,
        cellEditor: InputCellEditorComponent,
        cellEditorParams: {
          context: {
            numeric: true,
            precision: this.currencyPrecision(),
            suffix: this.currency(),
          },
        },
        valueGetter: (params: ValueGetterParams<FlatCostCenterGridRow>) => {
          if (params.data && !params.data.isParent) {
            const parent = this.multipleCostCentersData().find(item => item.id === params.data.parentId);

            return this.getCostCenterSellingPrice(params.data.revenuePercentage, parent?.sellingPrice ?? 0);
          }

          return params.data?.sellingPrice;
        },
        valueFormatter: params => {
          if (params.data) {
            const value = params.value as number | undefined;

            return value !== undefined ? value.toFixed(this.currencyPrecision()) : '';
          }

          return '';
        },
        onCellValueChanged: (params: NewValueParams<FlatCostCenterGridRow>) => {
          const data = params.data;

          if (data && data.isParent) {
            this.updateBranchSellingPrice(data.id, Number(params.newValue));
            this.onCellEditDone();
          }
        },
      },
      {
        headerName: $localize`:@@grid.headers.taxes:Taxes`,
        field: 'taxes',
        flex: 1,
        editable: ({ data }: EditableCallbackParams<FlatCostCenterGridRow>) => !!data?.isParent,
        cellRenderer: ({ data }: ICellRendererParams<FlatCostCenterGridRow>) => {
          if (data && data.isParent) {
            return $localize`:@@taxes.selectedCount:${data.taxes?.length ?? 0} Selected`;
          }

          return '';
        },
        cellEditor: MultiSelectCellEditorComponent,
        cellEditorParams: ({ data }: ICellEditorParams<FlatCostCenterGridRow>) => {
          if (!data || !data.isParent) return {};

          return {
            context: {
              list: this.taxes(),
              valueKey: 'id',
              titleKey: 'name',
              displayKey: 'name',
              multiple: true,
              placeholder: $localize`:@@taxes.select:Select Taxes`,
              clearable: true,
            },
            value: data.taxes ?? [],
          };
        },
        onCellValueChanged: ({ data, newValue }: NewValueParams<FlatCostCenterGridRow, string[]>) => {
          if (data && data.isParent) {
            this.updateBranchTaxes(data.id, newValue ?? []);
            this.onCellEditDone();
          }
        },
      },
      {
        headerName: $localize`:@@grid.headers.actions:Actions`,
        flex: 0.25,
        cellRenderer: ActionsCellRendererComponent,
        cellRendererParams: ({ data }: ICellEditorParams<FlatCostCenterGridRow>) => {
          if (data.isParent) {
            return {
              context: {
                actions: [
                  {
                    icon: 'plus-flat',
                    tooltip: $localize`:@@costCenter.actions.add:Add cost center`,
                    iconColor: 'primary',
                    callback: () => this.onModify(data.id),
                  },
                ],
              },
            };
          } else {
            return {
              context: {
                actions: [
                  {
                    icon: 'delete',
                    tooltip: $localize`:@@costCenter.actions.delete:Delete cost center`,
                    iconColor: 'error',
                    callback: () => this.onModify(data.parentId, data.locationIndex),
                  },
                ],
              },
            };
          }
        },
      },
    ]);
  }

  private initializeData(): void {
    effect(
      () => {
        const costCenterData = this.data();

        if (costCenterData?.length) {
          const aggregatedData = this.aggregateCostCenterData(costCenterData);

          this.multipleCostCentersData.set(aggregatedData);
          this.flattenedGridData.set(this.flattenData(aggregatedData));
        }
      },
      { injector: this.#injector, allowSignalWrites: true },
    );
  }

  private flattenData(data: MultipleCostCenterGridItem[]): FlatCostCenterGridRow[] {
    const result: FlatCostCenterGridRow[] = [];

    data.forEach(item => {
      result.push({
        id: item.id,
        isParent: true,
        isExpanded: true,
        branch: item.branch,
        sellingPrice: item.sellingPrice,
        taxes: item.taxes,
        name: item.branch.name,
      });

      item.locations.forEach((location, index) => {
        result.push({
          id: `${item.id}${index}`,
          parentId: item.id,
          isParent: false,
          location: location.location,
          revenuePercentage: location.revenuePercentage,
          locationIndex: index,
          name: location.location?.name,
        });
      });
    });

    return result;
  }

  private toggleRowExpansion(rowId: string): void {
    const data = this.flattenedGridData();
    const rowIndex = data.findIndex(row => row.id === rowId);

    if (rowIndex !== -1) {
      data[rowIndex] = {
        ...data[rowIndex],
        isExpanded: !data[rowIndex].isExpanded,
      };
      this.flattenedGridData.set([...data]);

      if (this.grid()?.api) {
        this.grid().api.onFilterChanged();
      }
    }
  }

  private filterRow(row: FlatCostCenterGridRow): boolean {
    if (!row) return true;

    if (row.isParent) {
      return true;
    } else {
      const parentRow = this.flattenedGridData().find(r => r.id === row.parentId);

      return parentRow?.isExpanded === true;
    }
  }

  private updateLocationRevenuePercentage(branchId: string, locationIndex: number, value: number): void {
    const data = this.multipleCostCentersData();
    const branchIndex = data.findIndex(item => item.id === branchId);

    if (branchIndex !== -1 && data[branchIndex].locations[locationIndex]) {
      data[branchIndex].locations[locationIndex].revenuePercentage = value;
      this.multipleCostCentersData.set([...data]);
      this.flattenedGridData.set(this.flattenData(data));
    }
  }

  private updateBranchSellingPrice(branchId: string, value: number): void {
    const data = this.multipleCostCentersData();
    const branchIndex = data.findIndex(item => item.id === branchId);

    if (branchIndex !== -1) {
      data[branchIndex].sellingPrice = value;
      this.multipleCostCentersData.set([...data]);
      this.flattenedGridData.set(this.flattenData(data));
    }
  }

  private updateBranchTaxes(branchId: string, value: string[]): void {
    const data = this.multipleCostCentersData();
    const branchIndex = data.findIndex(item => item.id === branchId);

    if (branchIndex !== -1) {
      data[branchIndex].taxes = value;
      this.multipleCostCentersData.set([...data]);
      this.flattenedGridData.set(this.flattenData(data));
    }
  }

  private aggregateCostCenterData(costCenters: CostCenterGridItem[]): MultipleCostCenterGridItem[] {
    return (
      costCenters?.reduce((arr: MultipleCostCenterGridItem[], costCenter: CostCenterGridItem) => {
        if (arr?.map(({ id }) => id)?.includes(costCenter.id)) {
          return arr;
        }

        const currentCostCenters = costCenters.filter(({ branch }) => branch.id === costCenter.branch.id);

        arr.push({
          id: costCenter.id,
          branch: costCenter.branch,
          sellingPrice: currentCostCenters.reduce((sum, { sellingPrice }) => sum + (sellingPrice ?? 0), 0),
          taxes: currentCostCenters[0]?.taxes,
          locations: currentCostCenters
            .flatMap(costCenter => costCenter)
            .filter(Boolean) as CostCenterLocationGridItem[],
        });

        return arr;
      }, []) ?? []
    );
  }

  private handleDefaultSellingPriceChanges(): void {
    effect(
      () => {
        const price = this.defaultSellingPrice();

        if (typeof price === 'number') {
          untracked(() => {
            const data = this.multipleCostCentersData();

            data.forEach(costCenter => {
              costCenter.sellingPrice = price;
            });
            this.multipleCostCentersData.set([...data]);
            this.flattenedGridData.set(this.flattenData(data));
          });
        }
      },
      { injector: this.#injector, allowSignalWrites: true },
    );
  }

  private handleDefaultTaxesChanges(): void {
    effect(
      () => {
        const taxes = this.defaultTaxes();

        if (Array.isArray(taxes) && taxes.length) {
          untracked(() => {
            const data = this.multipleCostCentersData();
            let dataChanged = false;

            data.forEach(costCenter => {
              costCenter.taxes = taxes;
              dataChanged = true;
            });

            if (dataChanged) {
              this.multipleCostCentersData.set([...data]);
              this.flattenedGridData.set(this.flattenData(data));
              this.onCellEditDone();
            }
          });
        }
      },
      { injector: this.#injector, allowSignalWrites: true },
    );
  }

  private onModify(branchId: string, costCenterIndex?: number): void {
    const data = this.multipleCostCentersData();
    const branchIndex = data.findIndex(item => item.id === branchId);

    if (branchIndex !== -1) {
      if (costCenterIndex !== undefined) {
        data[branchIndex].locations = data[branchIndex].locations.filter((_, idx) => idx !== costCenterIndex);
      } else {
        data[branchIndex].locations = [
          ...(data[branchIndex].locations ?? []),
          { sellingPrice: this.defaultSellingPrice(), taxes: this.defaultTaxes() },
        ];

        const flatData = this.flattenedGridData();
        const parentIndex = flatData.findIndex(row => row.id === branchId);

        if (parentIndex !== -1) {
          flatData[parentIndex] = {
            ...flatData[parentIndex],
            isExpanded: true,
          };
        }
      }

      this.multipleCostCentersData.set([...data]);
      this.flattenedGridData.set(this.flattenData(data));
      this.onCellEditDone();

      this.grid()?.api.onFilterChanged();
    }
  }

  private isRevenuePercentageValid(branchId: string): boolean {
    const branchData = this.multipleCostCentersData().find(({ id }) => branchId === id);

    if (!branchData) return true;

    const percentage = branchData.locations.reduce((acc, curr) => acc + +(curr.revenuePercentage ?? 0), 0) || 0;

    return percentage === 0 || percentage === 100;
  }

  private getLocationsLength(branch?: MultipleCostCenterGridItem): number {
    if (!branch?.locations) return 0;

    return branch.locations.filter(({ location }) => location?.id).length;
  }

  private getCostCenterSellingPrice(revenuePercentage: number | undefined, branchSellingPrice: number): number {
    if (!revenuePercentage || !branchSellingPrice) {
      return 0;
    }

    return (branchSellingPrice * revenuePercentage) / 100;
  }

  private onCellEditDone(): void {
    const payload = this.multipleCostCentersData()
      .flatMap(costCenter =>
        costCenter.locations.map(({ location, revenuePercentage }) => ({
          location: { id: location?.id },
          branch: { id: costCenter.branch.id },
          targetCost: 0,
          revenuePercentage,
          sellingPrice: this.getCostCenterSellingPrice(revenuePercentage, costCenter.sellingPrice),
          taxes: costCenter.taxes?.map(id => ({ id })),
        })),
      )
      .filter(costCenter => costCenter?.location?.id);

    for (const costCenter of payload) {
      if (!this.isRevenuePercentageValid(costCenter.branch.id)) {
        this.costCenterChange.emit(null);

        return;
      }
    }

    this.costCenterChange.emit(payload);
  }

  private onLocationSelect(location: LocationType | null, rowData: FlatCostCenterGridRow): void {
    const data = this.multipleCostCentersData();
    const branchIndex = data.findIndex(item => item.id === rowData.parentId);

    if (branchIndex !== -1 && rowData.locationIndex !== undefined) {
      data[branchIndex].locations[rowData.locationIndex].location = location;
      (rowData as Writable<FlatCostCenterGridRow>).location = location;

      const selectedCostCenters = data[branchIndex].locations?.filter(item => item.location?.id).length;

      if (selectedCostCenters === 1) {
        data[branchIndex].locations[rowData.locationIndex].revenuePercentage = 100;
        (rowData as Writable<FlatCostCenterGridRow>).revenuePercentage = 100;
      }

      this.multipleCostCentersData.set([...data]);

      const rowNodes: IRowNode[] = [];
      const rowNode = this.grid()?.api.getRowNode(this.flattenedGridData().indexOf(rowData).toString());

      if (rowNode) {
        rowNodes.push(rowNode);
      }

      if (rowData.parentId) {
        const rowNodeParent = this.grid()?.api.getRowNode(
          this.flattenedGridData()
            .findIndex(item => item.id === rowData.parentId)
            .toString(),
        );

        if (rowNodeParent) {
          rowNodes.push(rowNodeParent);
        }
      }
      this.grid()?.api.refreshCells();

      if (rowNodes.length > 0) {
        this.grid()?.api.refreshCells({ rowNodes, force: true });
      }

      this.onCellEditDone();
    }
  }

  protected getEmptyGridMessage(): string {
    return $localize`:@@grid.noValidLocations:There are no valid locations for this recipe`;
  }
}
