import { takeUntil } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';

import { AuthService } from '@supy/authentication';
import { Destroyable, LocalizedData, MimeType } from '@supy/common';
import { DropdownTreeNode, ToggleButton, UploadedFile } from '@supy/components';
import { SalesType } from '@supy/settings';

import { InventoryRecipeType } from '../../core';

export interface RecipeDetailsForm {
  readonly id: string;
  readonly code: string;
  readonly imageUrl: string;
  readonly name: LocalizedData;
  readonly type: InventoryRecipeType;
  readonly category: string;
  readonly location?: string;
  readonly activeSalesTypes?: string[];
}

@Component({
  selector: 'supy-recipe-details-form',
  templateUrl: './recipe-details-form.component.html',
  styleUrls: ['./recipe-details-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecipeDetailsFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RecipeDetailsFormComponent),
      multi: true,
    },
  ],
})
export class RecipeDetailsFormComponent<T>
  extends Destroyable
  implements OnInit, OnChanges, ControlValueAccessor, Validator
{
  private readonly authService = inject(AuthService);
  @Input() readonly formSubmitted: boolean;
  @Input() readonly editMode: boolean = false;
  @Input() readonly isReadonly: boolean = false;
  @Input() readonly categories: DropdownTreeNode<string>[];
  @Input() readonly locations: DropdownTreeNode<string>[];
  @Input() readonly withLocation: boolean;
  @Input() readonly salesTypes: SalesType[];
  @Input() readonly disabledSalesType: SalesType;

  @Output() readonly typeChange = new EventEmitter<InventoryRecipeType>();
  @Output() readonly locationChange = new EventEmitter<void>();
  @Output() readonly salesTypesChange = new EventEmitter<string[]>();

  protected readonly recipeType = InventoryRecipeType;

  protected readonly mimeType = MimeType;
  protected readonly uploadImageUrl = `${process.env.API_URL_BFF}/api/inventory-recipes/upload`;
  protected readonly token = this.authService.authUser?.accessToken ?? '';
  protected readonly types: ToggleButton[] = [
    {
      label: $localize`:@@finished:Finished`,
      value: InventoryRecipeType.Finished,
      icon: 'food',
    },
    {
      label: $localize`:@@inventory.recipe.semiFinished:Semi-Finished`,
      value: InventoryRecipeType.SemiFinished,
      icon: 'sub-recipe',
    },
  ];

  protected readonly form = new FormGroup({
    name: new FormGroup({
      en: new FormControl<string | null>(null, { validators: Validators.required }),
      ar: new FormControl<string | null>(null),
    }),
    type: new FormControl<InventoryRecipeType>(InventoryRecipeType.Finished),
    category: new FormControl<string | null>(null),
    code: new FormControl<string>(crypto.randomUUID().slice(0, 6), { validators: Validators.required }),
    imageUrl: new FormControl<string | null>(null),
    location: new FormControl<string | null>(null),
    activeSalesTypes: new FormControl<string[] | null>(null, { validators: Validators.required }),
  });

  get nameControl(): AbstractControl<string> {
    return this.form.get('name')?.get('en');
  }

  get recipeTypeControl(): AbstractControl<InventoryRecipeType> {
    return this.form.get('type');
  }

  get locationControl(): AbstractControl<string> {
    return this.form.get('location');
  }

  get activeSalesTypesCount(): boolean {
    return this.form.get('type')?.value === InventoryRecipeType.Finished && this.salesTypes?.length > 1;
  }

  get rawValue() {
    return this.form.getRawValue();
  }

  onTouched: () => void;
  onChange: (value: T) => void;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isReadonly) {
      if (changes.isReadonly.currentValue) {
        this.form.disable();
      } else {
        this.form.enable();

        if (this.editMode) {
          this.recipeTypeControl?.disable();
          this.locationControl?.disable();
        }
      }
    }
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value: T) => {
      this.onChange?.(value);
    });
  }

  writeValue(value: T): void {
    if (value) {
      this.form.patchValue(value);
    }
  }

  registerOnChange(onChange: (value: T) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  validate(): ValidationErrors | null {
    if (this.form.valid) {
      return null;
    }

    return { valid: false };
  }

  onFileChange(event: UploadedFile[]): void {
    this.form.patchValue({ imageUrl: event?.[0]?.response.photoUrl || null });
  }

  onTypeChange(value: string | null): void {
    switch (value as InventoryRecipeType) {
      case InventoryRecipeType.Finished: {
        this.form.addControl(
          'activeSalesTypes',
          new FormControl<string[] | null>(
            this.salesTypes?.map(val => val.id),
            { validators: Validators.required },
          ),
        );
        break;
      }
      case InventoryRecipeType.SemiFinished: {
        this.form.removeControl('activeSalesTypes');
        break;
      }
    }

    if (value) {
      this.typeChange.emit(value as InventoryRecipeType);
    }
  }

  onSalesTypeChange(value: string[]): void {
    this.salesTypesChange.emit(value);
  }
}
