import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';

import { RecipeIngredientsGrid } from '../recipe-ingredients-grid.interface';

@Component({
  selector: 'supy-full-width-add',
  template: `
    <supy-button
      *ngIf="!grid?.isReadonly"
      class="supy-full-width-add__add-row"
      color="default"
      width="full"
      (buttonClick)="grid.onRowAdding()"
    >
      <span class="supy-full-width-add__add-row-content">
        <span class="supy-full-width-add__add-row-content-label" i18n="@@inventory.recipe.grid.clickToAdd"
          >Click to add ingredient or sub-recipe</span
        >
        <supy-icon name="plus-flat-circle" title="Add" color="primary" size="medium" />
      </span>
    </supy-button>

    <div *ngIf="grid.ingredients?.length && !grid?.isReadonly" class="supy-full-width-add">
      <div [style.width]="'25.5%'" class="supy-full-width-add__cell" i18n="@@total">Total</div>
      <div [style.width]="grid.hidePrices() ? '17%' : '8%'" class="supy-full-width-add__cell">
        {{ grid.ingredientsTotal.net | supyPrecision: grid.currencyPrecision }}
      </div>

      <div [style.width]="grid.hidePrices() ? '17%' : '10%'" class="supy-full-width-add__cell">
        <supy-dropdown-tree
          class="supy-full-width-add__combo"
          [formControl]="dropdownTreeControl"
          variant="flat"
          i18n-placeholder="@@inventory.recipe.grid.uom"
          placeholder="Uom"
          selection="single"
          name="select-uom-total"
          [data]="grid.totalUnits"
          [clearable]="false"
        />
      </div>

      <div [style.width]="grid.hidePrices() ? '9%' : '8%'" class="supy-full-width-add__cell"></div>

      <div [style.width]="'8.5%'" class="supy-full-width-add__cell">
        {{ grid.ingredientsTotal.gross | supyPrecision: grid.currencyPrecision }}
      </div>

      <div *ngIf="!grid.hidePrices()" [style.width]="'8%'" class="supy-full-width-add__cell">
        {{ grid.ingredientsTotal.avgCost | supyPrecision: grid.currencyPrecision }}
      </div>
    </div>
  `,
  styleUrls: ['./full-width-add.row.component.scss'],
})
export class FullWidthRowComponent implements ICellRendererAngularComp {
  readonly #destroyRef = inject(DestroyRef);
  protected grid: RecipeIngredientsGrid;
  protected dropdownTreeControl = new FormControl<string | null>(null);
  private ingredientsLength = 0;

  agInit(params: ICellRendererParams & { grid: RecipeIngredientsGrid }): void {
    this.grid = params.grid;
    this.ingredientsLength = this.grid.ingredients?.length;
    this.dropdownTreeControl.setValue(this.grid.totalUnitId, { emitEvent: false });
    this.dropdownTreeControl.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(value => {
      this.grid.totalUnitId = value;
    });
  }

  refresh(): boolean {
    return this.ingredientsLength !== this.grid.ingredients?.length;
  }
}
