import produce from 'immer';
import { catchError, EMPTY, mergeMap, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import { EntityListState, EntityListStateModel, QueryBuilder, QueryPaging } from '@supy/common';
import { StatisticsCard } from '@supy/components';
import { Packaging } from '@supy/packaging';
import { RetailerSettingsState, SalesType, SettingsState } from '@supy/settings';
import { CurrentUserState } from '@supy/users';

import {
  AutocompleteFinishedRecipe,
  CostAdjustmentDetailResponse,
  CostAdjustmentsDetailGroup,
  EnhancedCostAdjustmentsGroup,
  InventoryRecipe,
  InventoryRecipeRequestProps,
  InventoryRecipeStateEnum,
  InventoryRecipeType,
  RecipeIngredientItem,
  RecipesStatisticsCardType,
  RecipeStatement,
  RecipeStatisticsResponse,
} from '../../core';
import { downloadInventoryRecipesList } from '../../helpers';
import { InventoryRecipeService } from '../../services';
import {
  InventoryRecipeAutocompleteFinishedRecipes,
  InventoryRecipeBackdate,
  InventoryRecipeBulkActivate,
  InventoryRecipeBulkArchive,
  InventoryRecipeBulkUpdateCategory,
  InventoryRecipeClone,
  InventoryRecipeCostAdjustmentsDetails,
  InventoryRecipeCostAdjustmentsGetMany,
  InventoryRecipeCostAdjustmentsReset,
  InventoryRecipeCreate,
  InventoryRecipeCreatePackaging,
  InventoryRecipeDelete,
  InventoryRecipeDeletePackaging,
  InventoryRecipeGet,
  InventoryRecipeGetAll,
  InventoryRecipeGetMany,
  InventoryRecipeGetManyPost,
  InventoryRecipeGetMinimumEffectiveDate,
  InventoryRecipeGetPDF,
  InventoryRecipeGetStatements,
  InventoryRecipeGetStatistics,
  InventoryRecipeIngredientItemsGet,
  InventoryRecipeInitFilters,
  InventoryRecipeListExport,
  InventoryRecipeMarkStock,
  InventoryRecipePatchFilter,
  InventoryRecipePatchRequestMeta,
  InventoryRecipePublish,
  InventoryRecipeResetFilter,
  InventoryRecipeResetPagination,
  InventoryRecipeResetRequestMeta,
  InventoryRecipeResetSequence,
  InventoryRecipeSetCreationType,
  InventoryRecipeSetIdToCloneIngredients,
  InventoryRecipeUpdate,
  InventoryRecipeUpdateCookbook,
  InventoryRecipeUpdateCostCenters,
  InventoryRecipeUpdateInventory,
  InventoryRecipeUpdateLocations,
  InventoryRecipeUpdatePackaging,
  InventoryRecipeWarnPackagingLinkedToSupplier,
} from '../actions';
import { PackagingService } from './../../../../services';

export interface InventoryRecipeStateModel extends EntityListStateModel<InventoryRecipe> {
  readonly filters: InventoryRecipeFilters;
  readonly requestMetadata: InventoryRecipeRequestMetadata;
  readonly responseMetadata: InventoryRecipeResponseMetadata;
  readonly creationType: InventoryRecipeType | null;
  readonly ingredientItems: RecipeIngredientItem[];
  readonly statisticCards: StatisticsCard[];
  readonly statements: RecipeStatement[];
  readonly exportLoading: boolean;
  readonly autocompleteFinishedRecipes: AutocompleteFinishedRecipe[];
  readonly minimumEffectiveDate: Date | null;
  readonly idToCloneIngredients: string | null;
  readonly salesTypesByRecipeId: SalesType[];
  readonly costAdjustments: EnhancedCostAdjustmentsGroup[];
}

export interface InventoryRecipeFilters {
  readonly codeName: string | null;
  readonly status: string | null;
  readonly retailer: string | null;
  readonly categories: string[];
  readonly selectedStatistics: string | null;
  readonly state: InventoryRecipeStateEnum;
  readonly locationIds: string[];
  readonly detailLocationId: string | null;
  readonly isStockable: boolean | null;
  readonly eventType: string | null;
  readonly startDate: Date | number | null;
  readonly endDate: Date | number | null;
  readonly userId: string | null;
  readonly excludedRecipeIds?: string[];
  readonly nameAscending?: boolean;
}

const INVENTORY_RECIPE_STATE_TOKEN = new StateToken<InventoryRecipeStateModel[]>('inventoryRecipe');

const FILTERS_DEFAULT: InventoryRecipeFilters = {
  codeName: null,
  status: null,
  retailer: null,
  categories: [],
  selectedStatistics: null,
  locationIds: [],
  state: InventoryRecipeStateEnum.Available,
  detailLocationId: null,
  isStockable: null,
  eventType: null,
  startDate: null,
  endDate: null,
  userId: null,
  nameAscending: false,
};

export interface InventoryRecipeRequestMetadata {
  readonly limit: number;
  readonly page: number;
  readonly retailerId: string | null;
  readonly userId: string | null;
  readonly useInProduction: boolean;
}

export interface InventoryRecipeResponseMetadata {
  readonly total: number;
  readonly count: number;
}

const REQUEST_META_DEFAULT: InventoryRecipeRequestMetadata = {
  page: 0,
  limit: 50,
  retailerId: null,
  userId: null,
  useInProduction: false,
};
const RESPONSE_META_DEFAULT: InventoryRecipeResponseMetadata = { total: 0, count: 0 };

@State<InventoryRecipeStateModel>({
  name: INVENTORY_RECIPE_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    requestMetadata: REQUEST_META_DEFAULT,
    responseMetadata: RESPONSE_META_DEFAULT,
    filters: FILTERS_DEFAULT,
    creationType: null,
    ingredientItems: [],
    statisticCards: [],
    statements: [],
    exportLoading: false,
    autocompleteFinishedRecipes: [],
    minimumEffectiveDate: null,
    idToCloneIngredients: null,
    salesTypesByRecipeId: [],
    costAdjustments: [],
  },
})
@Injectable()
export class InventoryRecipeState extends EntityListState {
  private readonly inventoryRecipeService = inject(InventoryRecipeService);
  private readonly packagingService = inject(PackagingService);

  readonly #store = inject(Store);
  readonly #hidePrices = toSignal(this.#store.select(CurrentUserState.hideApproxPrice));

  static get filtersDefault() {
    return FILTERS_DEFAULT;
  }

  @Selector()
  static recipes(state: InventoryRecipeStateModel) {
    return EntityListState.all(state);
  }

  @Selector()
  static selectedLocationId(state: InventoryRecipeStateModel) {
    return state.filters.locationIds;
  }

  @Selector()
  static costAdjustments(state: InventoryRecipeStateModel) {
    return state.costAdjustments;
  }

  @Selector()
  static currentRecipe(state: InventoryRecipeStateModel) {
    return EntityListState.current(state);
  }

  static recipe(id: string, next?: boolean) {
    return createSelector([InventoryRecipeState], (state: InventoryRecipeStateModel) => {
      return EntityListState.one(id, next)(state);
    });
  }

  @Selector()
  static appliedFiltersCount(state: InventoryRecipeStateModel) {
    return EntityListState.appliedFiltersCount(state, FILTERS_DEFAULT, ['locationIds', 'state', 'detailLocationId']);
  }

  @Selector()
  static filters(state: InventoryRecipeStateModel) {
    return EntityListState.currentFilters<InventoryRecipeFilters>(state);
  }

  @Selector()
  static costCenters(state: InventoryRecipeStateModel) {
    return EntityListState.current(state)?.costCenters;
  }

  @Selector()
  static locationIds(state: InventoryRecipeStateModel) {
    return EntityListState.currentFilters<InventoryRecipeFilters>(state).locationIds;
  }

  @Selector()
  static requestMetadata(state: InventoryRecipeStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: InventoryRecipeStateModel) {
    return state.responseMetadata;
  }

  @Selector()
  static creationType(state: InventoryRecipeStateModel) {
    return state.creationType;
  }

  @Selector()
  static ingredientItems(state: InventoryRecipeStateModel) {
    return state.ingredientItems;
  }

  @Selector()
  static flattenPackages(state: InventoryRecipeStateModel): Packaging[] | undefined {
    return EntityListState.current(state)
      ?.packages?.map(({ items }) => items)
      .flat(2);
  }

  @Selector()
  static statisticCards(state: InventoryRecipeStateModel) {
    return state.statisticCards;
  }

  @Selector()
  static detailLocation(state: InventoryRecipeStateModel) {
    return state.filters?.detailLocationId;
  }

  @Selector()
  static statements(state: InventoryRecipeStateModel) {
    return state.statements;
  }

  @Selector()
  static exportLoading(state: InventoryRecipeStateModel) {
    return state.exportLoading;
  }

  @Selector()
  static autocompleteFinishedRecipes(state: InventoryRecipeStateModel) {
    return state.autocompleteFinishedRecipes;
  }

  @Selector()
  static minimumEffectiveDate(state: InventoryRecipeStateModel) {
    return state.minimumEffectiveDate;
  }

  @Selector()
  static idToCloneIngredients(state: InventoryRecipeStateModel) {
    return state.idToCloneIngredients;
  }

  @Action(InventoryRecipeCostAdjustmentsGetMany, { cancelUncompleted: true })
  getMany(ctx: StateContext<InventoryRecipeStateModel>, { recipeId, payload }: InventoryRecipeCostAdjustmentsGetMany) {
    return this.inventoryRecipeService.getCostAdjustments(recipeId, payload).pipe(
      tap(response => {
        const costAdjustments = response.data.map(item => EnhancedCostAdjustmentsGroup.deserialize(item));

        ctx.patchState({ costAdjustments });
      }),
    );
  }

  @Action(InventoryRecipeCostAdjustmentsReset, { cancelUncompleted: true })
  resetCostAdjustments(ctx: StateContext<InventoryRecipeStateModel>) {
    ctx.patchState({ costAdjustments: [] });
  }

  @Action(InventoryRecipeCostAdjustmentsDetails, { cancelUncompleted: true })
  getDetails(
    ctx: StateContext<InventoryRecipeStateModel>,
    { recipeId, payload, rowId }: InventoryRecipeCostAdjustmentsDetails,
  ) {
    return this.inventoryRecipeService.getCostAdjustmentsDetails(recipeId, payload).pipe(
      tap((response: CostAdjustmentDetailResponse) => {
        const details = CostAdjustmentsDetailGroup.deserialize(response);

        const state = ctx.getState();
        const costAdjustments = [...state.costAdjustments];

        const adjustmentIndex = costAdjustments.findIndex(adj => adj.id === rowId);

        costAdjustments[adjustmentIndex] = {
          ...costAdjustments[adjustmentIndex],
          detailsData: details,
        };

        ctx.patchState({ costAdjustments });
      }),
    );
  }

  @Action(InventoryRecipeInitFilters)
  inventoryRecipeInitFilter(ctx: StateContext<InventoryRecipeStateModel>) {
    super.initFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(InventoryRecipePatchFilter)
  patchInventoryRecipeFilter(ctx: StateContext<InventoryRecipeStateModel>, action: InventoryRecipePatchFilter) {
    super.patchFilter(ctx, action.payload, FILTERS_DEFAULT, action.options);
  }

  @Action(InventoryRecipeResetFilter)
  resetInventoryRecipeFilter(ctx: StateContext<InventoryRecipeStateModel>, action: InventoryRecipeResetFilter) {
    const currentState = ctx.getState();
    const currentFilters = currentState.filters;

    super.resetFilter(ctx, FILTERS_DEFAULT);

    if (action.options?.preserveKeys?.length) {
      const newFilters = { ...ctx.getState().filters };

      action.options.preserveKeys.forEach(key => {
        const typedKey = key as keyof typeof currentFilters;

        if (typedKey in currentFilters) {
          Object.assign(newFilters, { [typedKey]: currentFilters[typedKey] });
        }
      });

      ctx.patchState({
        filters: newFilters,
      });
    }
  }

  @Action(InventoryRecipeResetPagination)
  resetPagination(ctx: StateContext<InventoryRecipeStateModel>) {
    ctx.dispatch(new InventoryRecipePatchRequestMeta({ page: 0, limit: 50 }));
  }

  @Action(InventoryRecipeGet)
  getInventoryRecipe(ctx: StateContext<InventoryRecipeStateModel>, action: InventoryRecipeGet) {
    return this.getOne<InventoryRecipe, InventoryRecipeStateModel>(ctx, {
      ...action.payload,
      fetchApi: () => this.inventoryRecipeService.getByIdBff(action.payload.id, action.payload.locationId),
    }).pipe(mergeMap(recipe => ctx.dispatch(new InventoryRecipeSetCreationType(recipe.type))));
  }

  @Action(InventoryRecipeGetMany, { cancelUncompleted: true })
  getInventoryRecipes(ctx: StateContext<InventoryRecipeStateModel>) {
    return this.inventoryRecipeService.getManyBff(this.getQuery(ctx.getState())).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(ctx, data);
      }),
    );
  }

  @Action(InventoryRecipeGetManyPost, { cancelUncompleted: true })
  getInventoryRecipesPost(ctx: StateContext<InventoryRecipeStateModel>) {
    return this.inventoryRecipeService.getManyPostBff(this.getQuery(ctx.getState())).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(ctx, data);
      }),
    );
  }

  @Action(InventoryRecipeGetAll, { cancelUncompleted: true })
  getAllInventoryRecipes(ctx: StateContext<InventoryRecipeStateModel>) {
    return this.inventoryRecipeService.getManyBff(this.getQuery(ctx.getState(), { noLimit: true })).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(ctx, data);
      }),
    );
  }

  @Action(InventoryRecipePatchRequestMeta)
  patchRequestMetadata(ctx: StateContext<InventoryRecipeStateModel>, { payload }: InventoryRecipePatchRequestMeta) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(InventoryRecipeResetRequestMeta)
  resetRequestMetadata(ctx: StateContext<InventoryRecipeStateModel>) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = { ...REQUEST_META_DEFAULT };
      }),
    );
  }

  @Action(InventoryRecipeCreate)
  createRecipe(ctx: StateContext<InventoryRecipeStateModel>, { payload }: InventoryRecipeCreate) {
    return this.inventoryRecipeService
      .create({
        ...payload,
      })
      .pipe(
        tap(recipe => {
          ctx.setState(
            produce(draft => {
              draft.entities.push(recipe);
              draft.sequence.id = recipe.id;
            }),
          );
        }),
      );
  }

  @Action(InventoryRecipeDelete)
  deleteRecipe(ctx: StateContext<InventoryRecipeStateModel>, { id }: InventoryRecipeDelete) {
    return this.inventoryRecipeService.delete(id);
  }

  @Action(InventoryRecipePublish)
  publishRecipe(ctx: StateContext<InventoryRecipeStateModel>, { id, effectiveDate }: InventoryRecipePublish) {
    return this.inventoryRecipeService.publish(id, effectiveDate);
  }

  @Action(InventoryRecipeUpdate)
  updateRecipe(ctx: StateContext<InventoryRecipeStateModel>, { id, payload }: InventoryRecipeUpdate) {
    return this.inventoryRecipeService.update(id, { ...payload });
  }

  @Action(InventoryRecipeUpdateInventory)
  updateRecipeInventory(ctx: StateContext<InventoryRecipeStateModel>, { id, payload }: InventoryRecipeUpdateInventory) {
    return this.inventoryRecipeService
      .updateInventory(id, { ...payload })
      .pipe(switchMap(() => ctx.dispatch(new InventoryRecipeGet({ id }))));
  }

  @Action(InventoryRecipeMarkStock)
  markAsStock(ctx: StateContext<InventoryRecipeStateModel>, { payload }: InventoryRecipeMarkStock) {
    return this.inventoryRecipeService
      .markAsStock(payload.id)
      .pipe(switchMap(() => ctx.dispatch(new InventoryRecipeGet({ id: payload.id }))));
  }

  @Action(InventoryRecipeSetCreationType)
  setCreationType(ctx: StateContext<InventoryRecipeStateModel>, { payload }: InventoryRecipeSetCreationType) {
    ctx.setState(
      produce(draft => {
        draft.creationType = payload;
      }),
    );
  }

  @Action(InventoryRecipeUpdateCookbook)
  updateCookbook(ctx: StateContext<InventoryRecipeStateModel>, { id, payload }: InventoryRecipeUpdateCookbook) {
    return this.inventoryRecipeService.updateCookbook(id, payload);
  }

  @Action(InventoryRecipeCreatePackaging)
  createRecipePackaging(ctx: StateContext<InventoryRecipeStateModel>, { payload }: InventoryRecipeCreatePackaging) {
    return this.packagingService.create(payload.packaging).pipe(
      mergeMap(() => {
        const recipeId = InventoryRecipeState.currentRecipe(ctx.getState())?.id;

        return ctx.dispatch(new InventoryRecipeGet({ id: recipeId }));
      }),
    );
  }

  @Action(InventoryRecipeUpdatePackaging)
  updateItemPackaging(ctx: StateContext<InventoryRecipeStateModel>, { payload }: InventoryRecipeUpdatePackaging) {
    return this.packagingService.update(payload.id, payload.body).pipe(
      tap(({ metadata }) => {
        if (metadata?.linkedToChannelItems) {
          ctx.dispatch(new InventoryRecipeWarnPackagingLinkedToSupplier());
        }
      }),
      mergeMap(() => {
        const recipeId = InventoryRecipeState.currentRecipe(ctx.getState())?.id;

        return ctx.dispatch(new InventoryRecipeGet({ id: recipeId }));
      }),
    );
  }

  @Action(InventoryRecipeDeletePackaging)
  deleteItemPackaging(ctx: StateContext<InventoryRecipeStateModel>, { payload }: InventoryRecipeDeletePackaging) {
    return this.packagingService.delete(payload.id).pipe(
      mergeMap(() => {
        const recipeId = InventoryRecipeState.currentRecipe(ctx.getState())?.id;

        return ctx.dispatch(new InventoryRecipeGet({ id: recipeId }));
      }),
    );
  }

  @Action(InventoryRecipeIngredientItemsGet)
  getIngredientItems(
    ctx: StateContext<InventoryRecipeStateModel>,
    { term, locationId }: InventoryRecipeIngredientItemsGet,
  ) {
    return this.inventoryRecipeService
      .getIngredientItems({
        term,
        retailerId: ctx.getState().requestMetadata.retailerId,
        recipeId: ctx.getState().sequence.id,
        locationId,
      })
      .pipe(tap(({ data }) => ctx.patchState({ ingredientItems: data })));
  }

  @Action(InventoryRecipeBulkArchive)
  bulkArchive(ctx: StateContext<InventoryRecipeStateModel>, { body }: InventoryRecipeBulkArchive) {
    return this.inventoryRecipeService.bulkArchiveBff(
      body.query ? body : { query: this.getQuery(ctx.getState(), { noLimit: true }) },
    );
  }

  @Action(InventoryRecipeBulkActivate)
  bulkActivate(ctx: StateContext<InventoryRecipeStateModel>, { body }: InventoryRecipeBulkActivate) {
    return this.inventoryRecipeService.bulkActivateBff(
      body.query ? body : { query: this.getQuery(ctx.getState(), { noLimit: true }) },
    );
  }

  @Action(InventoryRecipeBulkUpdateCategory)
  bulkUpdateCategory(ctx: StateContext<InventoryRecipeStateModel>, { body }: InventoryRecipeBulkUpdateCategory) {
    return this.inventoryRecipeService.bulkUpdateCategory(
      body.query ? body : { ...body, query: this.getQuery(ctx.getState(), { noLimit: true }) },
    );
  }

  @Action(InventoryRecipeGetStatistics)
  getInventoryStatistics(ctx: StateContext<InventoryRecipeStateModel>) {
    if (!ctx.getState().filters.locationIds.length) {
      return of(new RecipeStatisticsResponse());
    }

    return this.inventoryRecipeService.getStatisticsBff(ctx.getState().filters.locationIds.at(0)).pipe(
      tap(({ recipesTotal, unmappedFinished, aboveFoodCost, aboveTargetCost, belowTargetCost }) => {
        ctx.patchState({
          statisticCards: [
            {
              type: RecipesStatisticsCardType.RecipesTotal,
              title: $localize`:@@inventory.recipe.statistics.recipesTotal:No. of All Recipes`,
              icon: { name: 'box', color: 'info' },
              value: recipesTotal.value ?? 0,
            },
            {
              type: RecipesStatisticsCardType.NotLinked,
              title: $localize`:@@inventory.recipe.statistics.notLinked:Not Linked to POS`,
              icon: { name: 'link-vertical', color: 'success' },
              value: unmappedFinished.value ?? 0,
              clickable: true,
            },

            {
              type: RecipesStatisticsCardType.AboveFoodCost,
              title: $localize`:@@inventory.recipe.statistics.aboveFoodCost:Above Food Cost Limit`,
              icon: { name: 'below-line', color: 'error' },
              value: aboveFoodCost.value ?? 0,
              clickable: true,
            },
            {
              type: RecipesStatisticsCardType.aboveTargetCost,
              title: $localize`:@@inventory.recipe.statistics.aboveTargetCost:Above Target Food Cost`,
              icon: { name: 'below-line', color: 'error' },
              value: aboveTargetCost.value ?? 0,
              clickable: true,
            },
            {
              type: RecipesStatisticsCardType.belowTargetCost,
              title: $localize`:@@inventory.recipe.statistics.belowTargetCost:Below Target Food Cost`,
              icon: { name: 'above-line', color: 'warn' },
              value: belowTargetCost.value ?? 0,
              clickable: true,
            },
          ],
        });
      }),
    );
  }

  @Action(InventoryRecipeUpdateCostCenters)
  updateCostCenters(ctx: StateContext<InventoryRecipeStateModel>, { id, body }: InventoryRecipeUpdateCostCenters) {
    return this.inventoryRecipeService
      .updateCostCenters(id, body)
      .pipe(mergeMap(() => ctx.dispatch(new InventoryRecipeGet({ id }))));
  }

  @Action(InventoryRecipeUpdateLocations)
  updateLocations(ctx: StateContext<InventoryRecipeStateModel>, { id, body }: InventoryRecipeUpdateLocations) {
    return this.inventoryRecipeService.updateLocations(id, body);
  }

  @Action(InventoryRecipeResetSequence)
  resetSequenceHandler(ctx: StateContext<InventoryRecipeStateModel>) {
    super.resetSequence(ctx);
  }

  @Action(InventoryRecipeGetStatements)
  getStatement(ctx: StateContext<InventoryRecipeStateModel>, { id, locationIds }: InventoryRecipeGetStatements) {
    return this.inventoryRecipeService
      .getStatements(id, locationIds)
      .pipe(tap(statements => ctx.patchState({ statements })));
  }

  @Action(InventoryRecipeGetMinimumEffectiveDate)
  getMinimumEffectiveDate(
    ctx: StateContext<InventoryRecipeStateModel>,
    { id }: InventoryRecipeGetMinimumEffectiveDate,
  ) {
    return this.inventoryRecipeService.getMinimumEffectiveDate(id).pipe(
      tap(({ minEffectiveDate }) =>
        ctx.patchState({
          minimumEffectiveDate: new Date(minEffectiveDate),
        }),
      ),
    );
  }

  @Action(InventoryRecipeClone)
  cloneRecipe(ctx: StateContext<InventoryRecipeStateModel>, { payload }: InventoryRecipeClone) {
    return this.inventoryRecipeService.clone(payload.id, payload.body).pipe(
      tap(recipe => {
        const recipeUrl = this.router.serializeUrl(
          this.router.createUrlTree([`/${payload.section}/recipes/${recipe.id}/details`]),
        );

        window.open(recipeUrl, '_blank');
      }),
    );
  }

  @Action(InventoryRecipeListExport, { cancelUncompleted: true })
  downloadItems(ctx: StateContext<InventoryRecipeStateModel>) {
    ctx.patchState({
      exportLoading: true,
    });

    const state = ctx.getState();
    const query = this.getQuery(state, {
      noLimit: true,
    });

    return this.inventoryRecipeService.getManyBff(query).pipe(
      withLatestFrom(
        this.#store.select(SettingsState.currency),
        this.#store.select(SettingsState.ianaTimeZone),
        this.#store.select(RetailerSettingsState.recipeCostingTaxes),
      ),
      tap(([{ data }, currency, ianaTimeZone, taxes]) => {
        ctx.patchState({
          exportLoading: false,
        });

        const { locationIds } = state.filters;

        void downloadInventoryRecipesList(
          data,
          {
            locationId: locationIds?.[0],
            currency,
            taxes,
            hidePrices: this.#hidePrices(),
          },
          ianaTimeZone,
        );
      }),
      catchError(() => {
        ctx.patchState({
          exportLoading: false,
        });

        return EMPTY;
      }),
    );
  }

  @Action(InventoryRecipeAutocompleteFinishedRecipes)
  autocompleteFinishedRecipes(
    ctx: StateContext<InventoryRecipeStateModel>,
    { params }: InventoryRecipeAutocompleteFinishedRecipes,
  ) {
    return this.inventoryRecipeService
      .autocompleteFinishedRecipes(params)
      .pipe(tap(({ data }) => ctx.patchState({ autocompleteFinishedRecipes: data })));
  }

  @Action(InventoryRecipeBackdate)
  backdateRecipe(_: StateContext<InventoryRecipeStateModel>, { id, payload }: InventoryRecipeBackdate) {
    return this.inventoryRecipeService.backdate(id, payload);
  }

  @Action(InventoryRecipeSetIdToCloneIngredients)
  cloneIngredients(ctx: StateContext<InventoryRecipeStateModel>, { id }: InventoryRecipeSetIdToCloneIngredients) {
    ctx.patchState({ idToCloneIngredients: id });
  }

  private getQuery(state: InventoryRecipeStateModel, options?: { noLimit: boolean }) {
    const {
      codeName,
      categories,
      status,
      selectedStatistics,
      state: recipeState,
      locationIds,
      isStockable,
      excludedRecipeIds,
      nameAscending,
    } = state.filters;

    const qb = new QueryBuilder<InventoryRecipe & InventoryRecipeRequestProps>({
      filtering: [
        {
          by: 'state',
          match: recipeState ?? InventoryRecipeStateEnum.Available,
          op: 'eq',
        },
      ],
      paging: options?.noLimit
        ? QueryPaging.NoLimit
        : { offset: state.requestMetadata.page * state.requestMetadata.limit, limit: state.requestMetadata.limit },
      ordering: nameAscending
        ? [
            {
              by: 'name.en',
              dir: 'asc',
            },
            {
              by: 'createdAt',
              dir: 'desc',
            },
            { by: 'id', dir: 'desc' },
          ]
        : [
            {
              by: 'createdAt',
              dir: 'desc',
            },
            { by: 'id', dir: 'desc' },
          ],
    });

    if (codeName) {
      qb.filtering.withGroup('or', [
        {
          by: 'code',
          match: codeName,
          op: 'like',
        },
        {
          by: 'name.en',
          match: codeName,
          op: 'like',
        },
      ]);
    }

    if (categories?.length) {
      qb.filtering.setFilter({
        by: 'category.id',
        match: categories,
        op: 'in',
      });
    }

    if (state.requestMetadata.retailerId) {
      qb.filtering.setFilter({
        by: 'retailer.id',
        match: state.requestMetadata.retailerId,
        op: 'eq',
      });
    }

    if (state.requestMetadata.userId) {
      qb.filtering.setFilter({
        by: 'createdBy.id',
        match: state.requestMetadata.userId,
        op: 'eq',
      });
    }

    if (state.requestMetadata.useInProduction) {
      qb.filtering.setFilter({ by: 'locations.useInProduction', op: 'eq', match: true });
    }

    if (status) {
      qb.filtering.setFilter({
        by: 'type',
        match: status,
        op: 'eq',
      });
    }

    if (locationIds?.length > 0) {
      qb.filtering.setFilter({
        by: 'locations.locationId',
        match: locationIds,
        op: 'in',
      });
    }

    if (selectedStatistics) {
      qb.filtering.setFilter({
        by: 'tileType',
        match: selectedStatistics,
        op: 'eq',
      });
    }

    if (typeof isStockable === 'boolean') {
      qb.filtering.setFilter({
        by: 'isStockable',
        match: isStockable,
        op: 'eq',
      });
    }

    if (excludedRecipeIds) {
      qb.filtering.setFilter({
        by: 'id',
        match: excludedRecipeIds,
        op: 'not-in',
      });
    }

    return qb.build();
  }

  @Action(InventoryRecipeGetPDF)
  getPDF(ctx: StateContext<InventoryRecipeStateModel>, { id }: InventoryRecipeGetPDF) {
    return this.inventoryRecipeService.getPDF(id).pipe(
      tap(response => {
        window.open(response.signedUrl, '_blank');
      }),
    );
  }
}
