import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { IQueryResponse, Query } from '@supy/common';

import { INVENTORY_WASTAGES_BFF_URI } from '../../../config';
import {
  CreateInventoryWastageRequest,
  InventoryEventItemProps,
  InventoryEventRepeatStandardProps,
  InventoryEventRequestProps,
  InventoryWastageEvent,
  InventoryWastageSearchItem,
  ReplicationResponse,
  UpdateInventoryWastageRequest,
  UploadInventoryEventAttachmentQueryParams,
  UploadInventoryEventAttachmentResponse,
} from '../core';

@Injectable({ providedIn: 'root' })
export class InventoryWastageService {
  private readonly httpClient = inject(HttpClient);
  private readonly bffUrl = inject<string>(INVENTORY_WASTAGES_BFF_URI);

  getByIdBff(id: string): Observable<InventoryWastageEvent> {
    return this.httpClient.get<InventoryWastageEvent>(`${this.bffUrl}/${id}`);
  }

  getManyBff(query: Query<InventoryEventRequestProps>): Observable<IQueryResponse<InventoryWastageEvent>> {
    return this.httpClient.get<IQueryResponse<InventoryWastageEvent>>(this.bffUrl, {
      params: new HttpParams({ fromObject: query.toQueryParams() }),
    });
  }

  createBff(body: CreateInventoryWastageRequest): Observable<InventoryWastageEvent> {
    return this.httpClient.post<InventoryWastageEvent>(this.bffUrl, body);
  }

  updateBff(body: UpdateInventoryWastageRequest): Observable<InventoryWastageEvent> {
    const { id, ...rest } = body;

    return this.httpClient.patch<InventoryWastageEvent>(`${this.bffUrl}/${id}`, rest);
  }

  deleteBff(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.bffUrl}/${id}`);
  }

  getItemsBff(query: InventoryEventItemProps): Observable<IQueryResponse<InventoryWastageSearchItem>> {
    return this.httpClient.get<IQueryResponse<InventoryWastageSearchItem>>(`${this.bffUrl}/items`, {
      params: new HttpParams({
        fromObject: { ...query, eventDate: new Date(query.eventDate).toISOString() },
      }),
    });
  }

  uploadAttachment(
    body: FormData,
    queryParams: UploadInventoryEventAttachmentQueryParams,
  ): Observable<UploadInventoryEventAttachmentResponse> {
    return this.httpClient.post<UploadInventoryEventAttachmentResponse>(`${this.bffUrl}/upload`, body, {
      params: new HttpParams({ fromObject: { ...queryParams } }),
    });
  }

  repeat(id: string, values: InventoryEventRepeatStandardProps): Observable<ReplicationResponse> {
    return this.httpClient.post<ReplicationResponse>(`${this.bffUrl}/${id}/repeat`, values);
  }
}
