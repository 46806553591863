<header class="inventory-event-details-header">
  <div class="inventory-event-details-header__flex-space-between">
    <supy-icon name="close-circle" color="gray" routerLink="../" queryParamsHandling="preserve" />

    <div *ngIf="selectedEvent" class="inventory-event-details-header__actions">
      <supy-dropdown-toggle [dropdownRef]="detailsActions">
        <supy-icon name="dots" color="gray" />
      </supy-dropdown-toggle>
      <supy-dropdown #detailsActions action>
        @if (canDelete) {
          <supy-dropdown-item (click)="deleteClicked.emit(selectedEvent.id)">
            <supy-icon name="delete" color="error" />
            <span i18n="@@delete">Delete</span>
          </supy-dropdown-item>
        }

        <supy-dropdown-item *ngIf="canDownload" (click)="downloadClicked.emit(selectedEvent.id)">
          <supy-icon name="document-download" />
          <span i18n="@@download">Download</span>
        </supy-dropdown-item>

        @if (
          (eventType === inventoryEventType.Transfer &&
            mode === inventoryTransferMode.Outgoing &&
            eventState !== inventoryTransferStateEnum.Received) ||
          (eventType !== inventoryEventType.Transfer && eventState !== inventoryEventStateEnum.Submitted)
        ) {
          <supy-dropdown-item (click)="repeatClicked.emit()">
            <supy-icon name="refresh" color="secondary" />
            <span i18n="@@repeat">Repeat</span>
          </supy-dropdown-item>
        }
      </supy-dropdown>

      <supy-button
        type="icon"
        color="default"
        name="previous"
        title="Previous"
        [disabled]="previousDisabled"
        (buttonClick)="navigationClicked.emit(false)"
      >
        <supy-icon name="long-arrow-left-circle" size="medium" />
      </supy-button>
      <supy-button
        type="icon"
        color="default"
        name="next"
        title="Next"
        [disabled]="nextDisabled"
        (buttonClick)="navigationClicked.emit(true)"
      >
        <supy-icon name="long-arrow-right-circle" size="medium" />
      </supy-button>
    </div>
  </div>
  <div class="inventory-event-details-header__flex-space-between">
    <h4>{{ eventTitle }}</h4>
    <supy-inventory-event-status *ngIf="selectedEvent" [eventType]="eventType" [status]="eventStatus" />
  </div>
</header>
