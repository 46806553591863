<div class="supy-packages-grid">
  <div *ngFor="let package of packages; let index = index" class="supy-packages-grid__package">
    <div class="supy-packages-grid__package-items">
      <ng-container
        [ngTemplateOutlet]="packageItemColumn"
        [ngTemplateOutletContext]="{
          groupIndex: index,
          title: 'Base',
          level: 0,
          iconName: 'package-base',
          iconColor: 'primary',
          items: package.items,
          item: item,
        }"
      />

      <ng-container
        [ngTemplateOutlet]="packageItemColumn"
        [ngTemplateOutletContext]="{
          groupIndex: index,
          title: 'Pack',
          level: 1,
          iconName: '3dcube',
          iconColor: 'info',
          items: package.items,
          item: item,
        }"
      />

      <ng-container
        [ngTemplateOutlet]="packageItemColumn"
        [ngTemplateOutletContext]="{
          groupIndex: index,
          title: 'Container',
          level: 2,
          iconName: 'box',
          iconColor: 'success',
          items: package.items,
          item: item,
        }"
      />
    </div>
  </div>

  <ng-container *supyHasAccess="createPackagingStrategy">
    <supy-button
      *ngIf="!isReadonly && (!baseUnit?.isPiece || !packages?.length)"
      name="add-package"
      class="supy-packages-grid-add-package"
      color="default-outline"
      (click)="onCreatePackageItem({ item })"
    >
      <supy-icon name="add-base-pckg" size="huge" />
      <span>{{ packages?.length ? 'Create additional base packaging' : 'Create base packaging' }}</span>
    </supy-button>
  </ng-container>
</div>

<ng-template
  #packageItemColumn
  let-groupIndex="groupIndex"
  let-title="title"
  let-level="level"
  let-iconName="iconName"
  let-iconColor="iconColor"
  let-items="items"
  let-item="item"
  let-isDeletable="isDeletable"
>
  <div *ngIf="(items | supyPackageLevel: level)?.length" class="supy-packages-grid__package-item-column">
    <div class="supy-packages-grid__package-item-column-title level-{{ level }}">
      <supy-icon [name]="iconName" [color]="iconColor" />
      {{ title }}
    </div>
    <supy-package-item
      *ngFor="let packageItem of items | supyPackageLevel: level"
      [itemName]="getLocalizedName(packageItem.itemName) || item.name"
      [packageItem]="packageItem"
      [isReadonly]="isReadonly"
      [isEditable]="isEditable"
      [isDeletable]="isLastPackageItem(packageItem, items) && canDeletePackaging()"
      (addPackageItem)="onCreatePackageItem({ item, groupIndex, basePackageItem: $event })"
      (editPackageItem)="
        onEditPackageItem({
          item,
          groupIndex,
          basePackageItem: getParentPackaging($event),
          packageItem: $event,
          isDeletable: isLastPackageItem(packageItem, items) && canDeletePackaging(),
        })
      "
      (deletePackageItem)="deletePackageItem.emit($event)"
    /></div
></ng-template>
